<template>
  <icon-base v-bind="$props" viewport="0 0 28 28">
    <path
      :style="style"
      class="st0"
      d="M21.47,8.37c0.24,4.74-0.96,7.16-3.13,8c2.84-6.58,1.61-12.18-5.57-16.35c2.74,8.07-3.83,8.97-7.03,12.53
	c-2.94,3.27-2.89,9.01-0.42,11.9c3.51,4.1,10.66,4.99,15.73,0.97C26.2,21.35,24.99,15.03,21.47,8.37L21.47,8.37z M10.9,25.77
	c-7.11-2.5-6.82-10.79,0.84-14.68c-5.96,8.22-0.11,8.48,2.37,10.79C15.33,23.02,14.57,27.06,10.9,25.77z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconSectorGases",
  mixins: [iconMixin]
};
</script>
