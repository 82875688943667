<template>
  <g :id="selector" class="legend">
    <template v-for="(cat, i) in categories">
      <circle :key="'cat_' + i" cx="0" :cy="i * 24" r="10" :style="'fill: ' + cat.color"></circle>
      <text :key="'cat_text_' + i" x="15" :y="i * 24 + 3">{{ cat.label }}</text>
    </template>
  </g>
</template>

<script>

export default {
  props: {
    labels: {
      type: Array,
      default: () => {}
    },
    colors: {
      type: Array,
      default: () => {}
    },
    selector: {
      type: String,
      default: "legendGradient"
    }
  },
  computed: {
    categories: function() {
      return this.labels.map((k, i) => ({ label: k, color: this.colors[i] }));
    }
  }
};
</script>

<style scoped>
.legend {
  font-size: 0.8em;
}
circle {
  stroke: #444 !important;
  stroke-width: 0.5 !important;
  stroke-opacity: 0.5 !important;
}
</style>
