import axios from "../axios";

class IndicatorService {
  constructor() {
    this.indicatorCache = [];
  }

  getIndicatorsBySector(sectorId) {
    return axios.get(`api/sectors/${sectorId}/indicators`).then(result => {
      return result.data;
    });
  }

  /**
   * Returns an indicator.
   * Instead of reloading the indicator each time, we store already loaded indicators
   * @param id
   * @return {Promise<AxiosResponse<T>>|Promise<unknown>}
   */
  getIndicatorFull(id) {
    if (typeof this.indicatorCache[id] === "undefined") {
      return axios.get(`api/indicators/${id}/scores`).then(result => {
        this.indicatorCache[id] = result.data;
        return result.data;
      });
    } else {
      return Promise.resolve(this.indicatorCache[id]);
    }
  }

  updateIndicator(indicatorId, data) {
    return axios.put(`api/indicators/${indicatorId}`, data).then(response => {
      return response.data;
    });
  }

  deleteIndicator(indicatorId) {
    return axios.delete(`api/indicators/${indicatorId}`).then(response => {
      return response.data;
    });
  }

  updateIndicatorSources(indicatorId, sourceSet) {
    return axios.post(`api/indicators/${indicatorId}/updateSources`, sourceSet).then(response => {
      return response.data;
    });
  }
}

export default new IndicatorService();
