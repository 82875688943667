<template>
  <div class="canton"></div>
</template>

<script>
export default {
  name: "CantonPage"
};
</script>

<style scoped></style>
