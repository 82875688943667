<template>
  <icon-base v-bind="$props" viewport="0 0 32 24">
    <path
      class="st0"
      d="M2.89,10.34H1.5c-0.42,0-0.76-0.22-0.76-0.62c0-0.4,0.34-0.62,0.76-0.62h4.25c0.42,0,0.76,0.21,0.76,0.62
	c0,0.4-0.34,0.62-0.76,0.62H4.36v4.59c0,0.44-0.29,0.73-0.73,0.73s-0.73-0.3-0.73-0.73V10.34z"
    ></path>
    <path
      class="st0"
      d="M10.14,14.29c0,0.55,0.13,0.67,0.13,0.88c0,0.3-0.28,0.5-0.59,0.5c-0.56,0-0.75-0.36-0.77-0.76
	c-0.51,0.56-1.1,0.76-1.79,0.76c-0.74,0-1.48-0.51-1.48-1.33c0-1.08,0.82-1.39,1.83-1.5l0.8-0.09c0.31-0.04,0.56-0.11,0.56-0.48
	c0-0.38-0.39-0.54-0.82-0.54c-0.97,0-0.99,0.72-1.51,0.72c-0.33,0-0.56-0.23-0.56-0.5c0-0.52,0.73-1.18,2.08-1.18
	c1.25,0,2.12,0.4,2.12,1.34V14.29z M8.83,13.35c-0.14,0.13-0.41,0.18-0.56,0.21l-0.47,0.07c-0.55,0.08-0.84,0.25-0.84,0.65
	c0,0.3,0.28,0.54,0.73,0.54c0.72,0,1.16-0.47,1.16-0.95V13.35z"
    ></path>
    <path
      class="st0"
      d="M11.05,9.64c0-0.43,0.26-0.65,0.65-0.65c0.39,0,0.65,0.22,0.65,0.65v1.77h0.02c0.26-0.42,0.71-0.66,1.29-0.66
	c1.38,0,2.02,1.17,2.02,2.43c0,1.69-0.96,2.49-2.05,2.49c-0.64,0-1.16-0.27-1.38-0.82h-0.02v0.21c0,0.4-0.21,0.62-0.59,0.62
	c-0.4,0-0.59-0.21-0.59-0.62V9.64z M13.33,11.77c-0.8-0.01-1.05,0.78-1.05,1.45c0,0.74,0.29,1.42,1.05,1.42
	c0.7-0.02,1.04-0.65,1.04-1.42C14.37,12.61,14.17,11.77,13.33,11.77z"
    ></path>
    <path
      class="st0"
      d="M17.41,13.45c0,0.69,0.45,1.19,1.21,1.19c0.98,0,0.98-0.65,1.51-0.65c0.31,0,0.54,0.27,0.54,0.51
	c0,0.79-1.26,1.16-2.05,1.16c-1.83,0-2.52-1.24-2.52-2.37c0-1.52,0.92-2.54,2.47-2.54c1.51,0,2.37,1.07,2.37,2.14
	c0,0.45-0.13,0.56-0.57,0.56H17.41z M19.7,12.7c-0.07-0.6-0.52-1.04-1.1-1.04c-0.65,0-1.05,0.4-1.19,1.04H19.7z"
    ></path>
    <path
      class="st0"
      d="M21.63,9.64c0-0.43,0.26-0.65,0.65-0.65c0.39,0,0.65,0.22,0.65,0.65v5.38c0,0.43-0.26,0.65-0.65,0.65
	c-0.39,0-0.65-0.22-0.65-0.65V9.64z"
    ></path>
    <path
      class="st0"
      d="M23.9,9.64c0-0.43,0.26-0.65,0.65-0.65s0.65,0.22,0.65,0.65v5.38c0,0.43-0.26,0.65-0.65,0.65
	s-0.65-0.22-0.65-0.65V9.64z"
    ></path>
    <path
      class="st0"
      d="M27.21,13.45c0,0.69,0.45,1.19,1.21,1.19c0.98,0,0.98-0.65,1.51-0.65c0.31,0,0.54,0.27,0.54,0.51
	c0,0.79-1.26,1.16-2.05,1.16c-1.83,0-2.52-1.24-2.52-2.37c0-1.52,0.92-2.54,2.47-2.54c1.51,0,2.37,1.07,2.37,2.14
	c0,0.45-0.13,0.56-0.57,0.56H27.21z M29.5,12.7c-0.07-0.6-0.52-1.04-1.1-1.04c-0.65,0-1.05,0.4-1.19,1.04H29.5z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconTable",
  mixins: [iconMixin]
};
</script>
