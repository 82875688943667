<template>
  <!-- Template to handle the configurator (right side) -->
  <div class="configurator-config w-100 h-100" :class="config.view">
    <perfect-scrollbar :options="{ suppressScrollX: true }" class="h-100">
      <div style="margin-right: 15px; margin-left: 2.5px">
        <div class="config-mobile-graphic-type d-block d-lg-none">
          <h5 class="config-section-title mb-1 font-weight-bold text-secondary">
            {{ $t("graphic_types") }}
          </h5>
          <!-- Links to different diagrams inside the configurator (only displayed when left navbar gets hidden) -->
          <div class="configurator-nav col-12 pl-0 col-lg-1 d-lg-none">
            <nav class="configurator-nav position-relative overflow-hidden clearfix mb-3 no-break">
              <configurator-link to="/configurator/bar">
                <icon-bar-chart width="32" height="24" :class="{ active: isActive('bar') }"></icon-bar-chart>
              </configurator-link>
              <configurator-link :id="$sidebar.showSidebar ? 'step-7' : ''" to="/configurator/map">
                <icon-map width="32" height="24" :class="{ active: isActive('map') }"></icon-map>
              </configurator-link>

              <configurator-link to="/configurator/trend">
                <icon-trend-chart width="32" height="24" :class="{ active: isActive('trend') }"></icon-trend-chart>
              </configurator-link>

              <configurator-link to="/configurator/spider">
                <icon-spider-chart width="32" height="24" :class="{ active: isActive('spider') }"></icon-spider-chart>
              </configurator-link>

              <configurator-link :id="$sidebar.showSidebar ? 'step-8' : ''" to="/configurator/sources">
                <icon-source width="42" height="24" :class="{ active: isActive('sources') }"></icon-source>
              </configurator-link>

              <configurator-link :id="$sidebar.showSidebar ? 'step-9' : ''" to="/configurator/raw">
                <icon-table width="42" height="24" :class="{ active: isActive('raw') }"></icon-table>
              </configurator-link>
            </nav>
          </div>
        </div>

        <!-- Indicators -->
        <div class="config-section w-100 mb-4">
          <h5 class="config-section-title mb-1 font-weight-bold text-secondary">
            {{ $t("comparative_values") }}
          </h5>
          <ul class="selection list-group w-100">
            <li
              v-for="(item, index) in config.selection"
              :key="item._id"
              class="selection-item list-group-item rounded border mb-2"
              :class="{
                'border-primary': isViewSingle && index === config.selectedItem
              }"
              @click="selectIndicator(index)"
            >
              <div class="row no-gutters">
                <div class="col-12">
                  <h5 class="align-top mb-1 font-weight-bold">
                    {{ item.indicator.name }}
                  </h5>
                </div>
                <div class="col-2" style="height: 25px">
                  <component
                    :is="getSectorIcon(item.indicator.sectorId)"
                    :color="'#' + getSectorColor(item.indicator.sectorId)"
                    :width="18"
                    :height="18"
                  ></component>
                </div>
                <div class="col-1 form-inline" style="height: 25px" @click.stop>
                  <v-swatches
                    v-model="item.color"
                    popover-x="right"
                    swatches="text-advanced"
                    shapes="circles"
                    :trigger-style="{ width: '12px', height: '12px' }"
                    @input="selectColor(item)"
                  ></v-swatches>
                </div>
                <div class="col-8 form-inline" style="height: 25px" @click.stop>
                  <div class="form-group w-100">
                    <span
                      v-if="item.indicator.type === 'policy_measure'"
                      class="l-2 badge badge-white px-2 badge-indicator-type"
                    >
                      Politikmassnahme
                    </span>
                    <label v-else class="w-100 align">
                      <select
                        v-model="item.meta.unit"
                        class="form-control-sm border-light w-100"
                        :aria-label="$t('unit')"
                        @change="selectUnit(item)"
                      >
                        <option
                          v-for="(unit, i) in item.indicator.years[item.meta.year]"
                          :key="'unit-' + item._id + '-' + i"
                          :value="unit"
                          >{{ unit }}</option
                        >
                      </select>
                    </label>
                  </div>
                </div>
                <div class="col-1" style="height: 25px" @click.stop>
                  <button type="button" class="close text-danger" aria-label="Close" @click="removeItem(index)">
                    <span class="align-top" aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <button id="step-1" class="btn btn-primary btn-block mt-1 h3" @click="openConfiguratorSelectionModal()">
            {{ $t("config.indicator.modify") }}
          </button>
        </div>

        <div id="step-6">
          <!-- Year -->
          <div v-if="years.length > 1" class="w-100 my-4 small">
            <h5 class="config-section-title mb-1 font-weight-bold text-secondary">
              Zeitraum
            </h5>
            <div class="mx-1 pb-4">
              <vue-slider
                v-model="year"
                :data="years"
                :marks="yearMarks"
                :adsorb="true"
                :tooltip="'none'"
                :contained="true"
                :process="isYearRange"
                @change="updateYear"
              ></vue-slider>
            </div>
          </div>

          <!-- Ansicht -->
          <div v-if="isViewTypeSelectable" class="config-section w-100 my-4">
            <h5 class="config-section-title mb-1 font-weight-bold text-secondary">
              {{ $t("config.view_selection") }}
            </h5>

            <div id="view-multi-single-button" class="d-inline-block my-2 mr-1">
              <b-button
                class="btn"
                :class="{
                  'btn-outline-light': !isViewMultiple && !isViewMultipleDisabled,
                  'btn-outline-primary': isViewMultiple,
                  'btn-outline-danger': !chartHasViewMultiple,
                  'btn-outline-warning': !isChartConfigValidMultiple
                }"
                :disabled="isViewMultipleDisabled"
                @click="selectView('multiple')"
              >
                <icon-view-multiple
                  :class="{
                    active: isViewMultiple,
                    danger: !chartHasViewMultiple,
                    warning: !isChartConfigValidMultiple
                  }"
                />
              </b-button>
              <b-tooltip target="view-multi-single-button" title="Tooltip title" triggers="hover" placement="bottom">
                <span v-if="!chartHasViewMultiple">
                  {{ $t("config.restrictions.view_not_supported") }}
                </span>
                <span v-else-if="!isChartConfigValidMultiple">
                  {{ $t("config.restrictions.data_must_be_scaled") }}
                </span>
                <span v-else>
                  <b>{{ $t("config.view_multiple") }}: </b>{{ $t("config.tooltip.view_multiple") }}
                </span>
              </b-tooltip>
            </div>

            <div id="view-single-button" class="d-inline-block my-2 mr-1">
              <button
                class="btn"
                :class="{
                  'btn-outline-light': !isViewSingle && !isViewSingleDisabled,
                  'btn-outline-primary': isViewSingle,
                  'btn-outline-danger': !chartHasViewSingle,
                  'btn-outline-warning': !isChartConfigValidSingle
                }"
                :disabled="isViewSingleDisabled"
                @click="selectView('single')"
              >
                <icon-view-single
                  :class="{
                    active: isViewSingle,
                    danger: !chartHasViewSingle,
                    warning: !isChartConfigValidSingle
                  }"
                />
              </button>
              <b-tooltip target="view-single-button" title="Tooltip title" triggers="hover" placement="bottom">
                <span v-if="!chartHasViewSingle">
                  {{ $t("config.restrictions.view_not_supported") }}
                </span>
                <span v-else-if="chartReqScaledViewSingle && !config.useScores">
                  {{ $t("config.restrictions.data_must_be_scaled") }}
                </span>
                <span v-else>
                  <b>{{ $t("config.view_single") }}: </b>{{ $t("config.tooltip.view_single") }}
                </span>
              </b-tooltip>
            </div>

            <div id="view-combination-button" class="d-inline-block my-2 mr-1">
              <button
                class="btn"
                :class="{
                  'btn-outline-light': !isViewCombined && !isViewCombinedDisabled,
                  'btn-outline-primary': isViewCombined,
                  'btn-outline-danger': !chartHasViewCombined,
                  'btn-outline-warning': !isChartConfigValidCombined
                }"
                :disabled="isViewCombinedDisabled"
                @click="selectView('combined')"
              >
                <icon-view-combined
                  :class="{
                    active: isViewCombined,
                    danger: !chartHasViewCombined,
                    warning: !isChartConfigValidCombined
                  }"
                />
              </button>
              <b-tooltip target="view-combination-button" title="Tooltip title" triggers="hover" placement="bottom">
                <span v-if="!chartHasViewCombined">
                  {{ $t("config.restrictions.view_not_supported") }}
                </span>
                <span v-else-if="!isChartConfigValidCombined">
                  {{ $t("config.restrictions.data_must_be_scaled") }}
                </span>
                <span v-else>
                  <b>{{ $t("config.view_combined") }}: </b>{{ $t("config.tooltip.view_combined") }}
                </span>
              </b-tooltip>
            </div>
          </div>

          <hr />
          <!-- Switch switch_normed -->
          <div class="config-section w-100 my-3">
            <div class="float-right px-2">
              <toggle-button
                v-model="config.useScores"
                :width="32"
                :height="16"
                color="#3384b8"
                :sync="true"
                :disabled="isScaledDataRequired"
              ></toggle-button>
            </div>
            <h5 id="config-switch-normed" class="config-section-title mb-1 font-weight-bold text-secondary">
              {{ $t("config.switch_normed") }}
            </h5>
            <b-tooltip target="config-switch-normed" title="Tooltip title" triggers="hover" placement="left">
              {{ $t("config.tooltip.switch_normed") }}
            </b-tooltip>
          </div>

          <!-- Wahl Kantone einschränken -->
          <div class="config-section w-100 my-3">
            <div class="float-right px-2">
              <toggle-button
                v-model="config.useCantonRestriction"
                :width="32"
                :height="16"
                color="#3384b8"
                :sync="true"
                :disabled="!isCantonSelectionAllowed"
              ></toggle-button>
            </div>
            <h5 id="config-switch-canton-selection" class="config-section-title mb-1 font-weight-bold text-secondary">
              {{ $t("config.switch_canton_selection") }}
            </h5>
            <b-tooltip target="config-switch-canton-selection" title="Tooltip title" triggers="hover" placement="left">
              {{ $t("config.tooltip.switch_canton_selection") }}
            </b-tooltip>
          </div>

          <!-- Switch "Was sehe ich?" -->
          <div class="config-section w-100 my-3">
            <div class="float-right px-2">
              <toggle-button
                v-model="config.useLookingAt"
                :width="32"
                :height="16"
                :sync="true"
                color="#3384b8"
              ></toggle-button>
            </div>
            <h5 id="config-switch-looking-at" class="config-section-title mb-1 font-weight-bold text-secondary">
              {{ $t("config.switch_looking_at") }}
            </h5>
            <b-tooltip target="config-switch-looking-at" title="Tooltip title" triggers="hover" placement="left">
              {{ $t("config.tooltip.switch_looking_at") }}
            </b-tooltip>
          </div>

          <!-- Animationen -->
          <div class="config-section w-100 my-3">
            <div class="float-right px-2">
              <toggle-button
                v-model="config.useAnimations"
                :width="32"
                :height="16"
                :sync="true"
                color="#3384b8"
              ></toggle-button>
            </div>
            <h5 id="config-switch-animations" class="config-section-title mb-1 font-weight-bold text-secondary">
              {{ $t("config.switch_animations") }}
            </h5>
            <b-tooltip target="config-switch-animations" title="Tooltip title" triggers="hover" placement="left">
              {{ $t("config.tooltip.switch_animations") }}
            </b-tooltip>
          </div>
          <hr />

          <!-- Ansicht teilen -->
          <div class="config-section w-100 my-3">
            <div id="step-11">
              <h5 class="config-section-title mb-2 font-weight-bold text-secondary">
                {{ $t("config.btn_share_view") }}
              </h5>
              <button class="btn btn-sm btn-info" @click="openShareModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
                  <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>

    <configurator-selection-modal id="indicator-modal" :sector-set="sectorSet" />
    <share-modal id="share-modal" :query-params="queryParams" />
    <embed-modal id="embed-modal" :query-params="queryParams" @update-query-params="updateDisableConfig" />
  </div>
</template>

<script>
import { IconViewCombined, IconViewMultiple, IconViewSingle } from "../Icons/view";
import {
  IconSectorBuildings,
  IconSectorData,
  IconSectorGases,
  IconSectorMobility,
  IconSectorRenewableEnergy
} from "../Icons/sectors";
import Icons from "../../components/Icons/graphicTypes";
import ConfiguratorSelectionModal from "./modals/ConfiguratorSelectionModal";
import ShareModal from "./modals/ShareModal";
import EmbedModal from "@/components/Configurator/modals/EmbedModal";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import _ from "lodash";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import ChartTypesMixin from "../../mixins/ChartTypesMixin";
import getChart from "../../config/charts";
import SectorSrv from "../../services/SectorSrv";
import ConfiguratorConfig from "./ConfiguratorConfig";
import ConfiguratorLink from "./partials/ConfiguratorLink";
import { normalizeUnits } from "@/utils/utils";
import CantonSrv from "@/services/CantonSrv";

export default {
  name: "ConfiguratorConfig",
  components: {
    EmbedModal,
    IconViewCombined,
    IconViewSingle,
    IconViewMultiple,
    IconSectorMobility,
    IconSectorBuildings,
    IconSectorGases,
    IconSectorRenewableEnergy,
    IconSectorData,
    ConfiguratorSelectionModal,
    ShareModal,
    VueSlider,
    VSwatches,
    ConfiguratorConfig,
    ConfiguratorLink,
    ...Icons
  },
  mixins: [ChartTypesMixin],
  props: {
    sectorSet: {
      type: Array,
      default: () => []
    },
    cantonSet: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    let _config = {
      selection: [],
      selectedItem: 0,
      year: null,
      view: "multiple", // "single", "multiple" & "combined"
      useScores: false,
      useCantonRestriction: true,
      useLookingAt: true,
      useAnimations: true,
      disableConfig: false
    };
    return {
      buttonBackgroundColor: "",
      defaultConfig: _config,
      config: _.cloneDeep(_config),
      years: [],
      year_single: null,
      year_range: [],
      flashMessages: {
        view_not_supported: null,
        view_req_scaled_data: null,
        no_canton_selection: null
      },
      fullUrl: "",
      isLoading: true
    };
  },
  computed: {
    // holds a copy of the configurator config
    local() {
      return !this.value || _.isEmpty(this.value) ? this.defaultConfig : this.value;
    },

    // returns a flat array of indicators
    indicators() {
      return _.flatten(this.sectorSet.map(sector => sector.indicatorSet));
    },

    // returns the year marks for year selection
    yearMarks() {
      if (this.years.length > 8) {
        return this.years.filter((e, i) => i % 2 === 0);
      }
      return this.years;
    },

    // returns the year depending on chart
    year: {
      get() {
        return this.isYearRange ? this.year_range : this.year_single;
      },
      set(val) {
        if (this.isYearRange) {
          this.year_range = val;
        } else {
          this.year_single = val;
        }
      }
    },

    // holds the current chart config depending on route name
    chartConfig() {
      return getChart(this.$route.name);
    },

    // VIEW TYPE SINGLE

    // boolean value whether or not the current view is type "single"
    isViewSingle() {
      return this.config.view === "single";
    },
    // checks if the current chart type includes the view type "single"
    chartHasViewSingle() {
      return this.chartConfig && this.chartConfig.supportedViews.includes("single");
    },
    // checks if the current chart type requires scaled data for view type "single"
    chartReqScaledViewSingle() {
      return this.chartConfig && this.chartConfig.supportedViewsReqScaledData.includes("single");
    },
    // checks if the config is valid for current chart type's view "single"
    isChartConfigValidSingle() {
      return !(this.chartReqScaledViewSingle && !this.config.useScores);
    },
    // does the current chart type with the given config allows to select view type = "single"
    isViewSingleDisabled() {
      return !this.chartHasViewSingle || !this.isChartConfigValidSingle;
    },

    // VIEW TYPE MULTIPLE

    // boolean value whether or not the current view is type "multiple"
    isViewMultiple() {
      return this.config.view === "multiple";
    },
    // checks if the current chart type includes the view type "multiple"
    chartHasViewMultiple() {
      return this.chartConfig && this.chartConfig.supportedViews.includes("multiple");
    },
    // checks if the current chart type requires scaled data for view type "multiple"
    chartReqScaledViewMultiple() {
      return this.chartConfig && this.chartConfig.supportedViewsReqScaledData.includes("multiple");
    },
    // checks if the config is valid for current chart type's view "multiple"
    isChartConfigValidMultiple() {
      return !(this.chartReqScaledViewMultiple && !this.config.useScores);
    },
    // does the current chart type with the given config allows to select view type = "multiple"
    isViewMultipleDisabled() {
      return !this.chartHasViewMultiple || !this.isChartConfigValidMultiple;
    },

    // VIEW TYPE COMBINED

    // boolean value whether or not the current view is type "combined"
    isViewCombined() {
      return this.config.view === "combined";
    },
    // checks if the current chart type includes the view type "combined"
    chartHasViewCombined() {
      return this.chartConfig && this.chartConfig.supportedViews.includes("combined");
    },
    // checks if the current chart type requires scaled data for view type "combined"
    chartReqScaledViewCombined() {
      return this.chartConfig && this.chartConfig.supportedViewsReqScaledData.includes("combined");
    },
    // checks if the config is valid for current chart type's view "combined"
    isChartConfigValidCombined() {
      return !(this.chartReqScaledViewCombined && !this.config.useScores);
    },
    // does the current chart type with the given config allows to select view type = "combined"
    isViewCombinedDisabled() {
      return !this.chartHasViewCombined || !this.isChartConfigValidCombined;
    },

    // OTHER SETTINGS

    // returns true if a view type can be selected
    isViewTypeSelectable() {
      return this.chartHasViewMultiple || this.chartHasViewSingle || this.chartHasViewCombined;
    },
    // checks for the current graphic if the data needs to be scaled
    isScaledDataRequired() {
      return this.chartConfig && this.chartConfig.supportedViewsReqScaledData.includes(this.config.view);
    },
    // checks if the current graphic type supports the selection of cantons
    isCantonSelectionAllowed() {
      return this.chartConfig && this.chartConfig.isCantonSelectionSupported;
    },
    // returns true or false whether or not the current chart type uses year range or not
    isYearRange() {
      return this.chartConfig && this.chartConfig.isYearRange;
    },
    queryParams() {
      let queryParams = {
        indicators: this.config.selection.map(s => s.indicator.id).join(","),
        colors: this.config.selection.map(s => s.color.replace("#", "")).join(","),
        units: this.config.selection.map(s => normalizeUnits(s.meta.unit)).join(","),
        year: this.isYearRange ? this.year_range[0] + "-" + this.year[1] : String(this.year_single),
        view: this.config.view,
        useScores: String(this.config.useScores),
        useLookingAt: String(this.config.useLookingAt),
        useAnimations: String(this.config.useAnimations),
        restrictCantons: String(this.config.useCantonRestriction),
        disableConfig: String(this.config.disableConfig)
      };

      if (this.config.useCantonRestriction) {
        queryParams["cantons"] = this.cantonSet.map(c => c.id).join(",");
      }

      return queryParams;
    }
  },
  watch: {
    sectorSet() {
      if (this.isLoading) {
        this.updateSelectionFromQuery();
        this.isLoading = false;
        this.updateConfig();
      }
    },
    "config.useScores"() {
      this.updateConfig();
    },
    "config.useAnimations"() {
      this.updateConfig();
    },
    "config.useCantonRestriction"() {
      this.updateConfig();
    },
    "config.useLookingAt"() {
      this.updateConfig();
    },
    chartType() {
      this.updateYear();
      this.checkChartConfigRestrictions();
    },
    queryParams() {
      if (this.isLoading) return;
      let reload = true; // todo make this configurable?
      if (reload) {
        if (JSON.stringify(this.$route.query) !== JSON.stringify(this.queryParams)) {
          this.$router.replace({ query: this.queryParams });
        }
      }
    }
  },
  created() {
    this.$emit("input", _.cloneDeep(this.local));
  },
  mounted() {
    this.updateConfigFromQuery();
    this.checkChartConfigRestrictions();
  },
  methods: {
    /**
     * Parses query parameters and updates the component configuration based on the provided values.
     */
    updateConfigFromQuery() {
      let query = this.$route.query;

      if (query.view) {
        this.selectView(query.view);
      }

      if (query.year) {
        if (query.year.includes("-")) {
          const [startYear, endYear] = query.year.split("-");
          this.year_range = [parseInt(startYear), parseInt(endYear)];
        } else {
          this.year_single = parseInt(query?.year);
        }
      }

      if (query.useScores && query.useScores !== String(this.config.useScores)) {
        this.updateUseScores(query.useScores === "true");
      }
      if (query.useLookingAt !== "undefined" && query.useLookingAt !== String(this.config.useLookingAt)) {
        this.updateUseLookingAt(query.useLookingAt === "true");
      }
      if (query.useAnimations && query.useAnimations !== String(this.config.useAnimations)) {
        this.updateUseAnimation(query.useAnimations === "true");
      }
      if (query.restrictCantons && query.restrictCantons !== String(this.config.useCantonRestriction)) {
        this.updateUseCantonRestriction(query.restrictCantons === "true");
      }
    },

    /**
     * Parses query parameters and updates the component configuration accordingly.
     * If 'indicators' parameter is present, it populates the 'selection' in the configuration.
     * Additionally, it triggers updates for the year and years based on the modified configuration.
     */
    updateSelectionFromQuery() {
      let query = this.$route.query;
      if (query.indicators) {
        const s_ids = query.indicators?.split(",").map(Number) || [];
        const num_ids = s_ids.length;

        const empty = new Array(num_ids).fill(null);
        const s_colors = (query.colors?.split(",") || []).slice(0, num_ids).concat(empty);
        const s_units = (query.units?.split(",") || []).slice(0, num_ids).concat(empty);

        this.config.selection = s_ids.flatMap((indicatorId, i) =>
          this.sectorSet.flatMap(sector =>
            sector.indicatorSet
              .filter(indicator => indicatorId === indicator.id)
              .map(indicator => {
                const years = Object.keys(indicator.years).map(Number);
                const year = years.includes(this.year) ? this.year : years[years.length - 1];
                const units = indicator.years[year];
                const color = "#" + (s_colors[i] ? s_colors[i] : SectorSrv.getSectorColor(indicator.sectorId));
                const unit = units.find(u => normalizeUnits(u) === normalizeUnits(s_units[i])) || units[0];
                const _id = parseInt(sector.id + "" + indicator.id);
                return { _id: _id, meta: { year, unit }, indicator, color };
              })
          )
        );
      }
      this.updateYear();
      this.updateYears(this.config.selection);
    },
    isActive(icon) {
      return icon === this.$route.name;
    },
    updateConfig() {
      if (this.isLoading) return;
      this.$emit("input", _.cloneDeep(this.config));
    },
    updateDisableConfig(value) {
      this.config.disableConfig = value;
      this.updateConfig();
    },
    updateUseScores(selection) {
      this.config.useScores = selection;
      this.updateConfig();
    },
    updateUseCantonRestriction(selection) {
      this.config.useCantonRestriction = selection;
      this.updateConfig();
    },
    updateUseLookingAt(selection) {
      this.config.useLookingAt = selection;
      this.updateConfig();
    },
    updateUseAnimation(selection) {
      this.config.useAnimations = selection;
      this.updateConfig();
    },
    updateYear() {
      this.config.year = this.year;
      this.updateConfig();
    },
    updateYears(selection) {
      let years = [];
      if (selection.length > 0) {
        years = _(selection)
          .map(s => Object.keys(s.indicator.years))
          .flatten()
          .map(y => parseInt(y))
          .uniq()
          .sort()
          .value();
      }
      if (years.length > 0) {
        let min_year = years[0];
        let max_year = years[years.length - 1];
        // check if year is already set
        if (!this.year_single) {
          this.year_single = max_year;
        }
        if (!this.year_range || this.year_range.length < 1) {
          this.year_range = [min_year, max_year];
        }
        // check single year
        if (min_year > this.year_single) {
          this.year_single = min_year;
        }
        if (this.year_single > max_year) {
          this.year_single = max_year;
        }
        // check year range
        if (min_year > this.year_range[0]) {
          this.year_range[0] = min_year;
        }
        if (this.year_range[1] > max_year) {
          this.year_range[1] = max_year;
        }

        if (this.chartType !== "trend") {
          this.config.year = this.year_single;
        } else if (this.chartType === "trend") {
          this.config.year = this.year_range;
        }
      }
      this.years = years || [];
    },
    getSectorIcon(sectorId) {
      return this.sectorSet.find(sector => sector.id === sectorId)?.icon;
    },
    getSectorColor(sectorId) {
      return this.sectorSet.find(sector => sector.id === sectorId)?.color;
    },
    getSectorName(sectorId) {
      return this.sectorSet.find(sector => sector.id === sectorId)?.name;
    },
    openConfiguratorSelectionModal() {
      this.$eventHub.$emit("open-configurator-selection-modal", {
        cb: this.updateSelection,
        selection: this.config.selection
      });
      this.$eventHub.$emit("selectionModalOpened");
    },
    openEmbedModal() {
      this.$eventHub.$emit("open-embed-modal");
    },
    updateSelection(selection) {
      this.config.selection = selection;
      this.updateYears(selection);
      this.updateConfig();
    },
    removeItem(index) {
      if (index > -1) this.config.selection.splice(index, 1);
      this.updateConfig();
    },
    selectView(view) {
      if (this.chartConfig && this.chartConfig.supportedViews.includes(view)) {
        this.config.view = view;
        if (view !== "single") {
          this.config.selectedItem = null;
        } else {
          if (this.config.selectedItem == null) {
            this.config.selectedItem = 0;
          }
        }
        this.updateConfig();
      }
    },
    selectIndicator(index) {
      this.config.selectedItem = index;
      this.config.view = "single";
      this.updateConfig();
    },
    selectUnit() {
      this.updateConfig();
    },
    selectColor() {
      this.updateConfig();
    },
    checkChartConfigRestrictions() {
      let flashViewNotSupported = false;

      // destroy prev messages
      this.flashMessages.view_not_supported?.destroy();
      this.flashMessages.view_req_scaled_data?.destroy();
      this.flashMessages.no_canton_selection?.destroy();

      // view type specific rules
      if (this.isViewSingle && this.chartType !== "sources" && this.chartType !== "raw") {
        // fires on view single but view single not supported by chart type
        if (!this.chartHasViewSingle) {
          flashViewNotSupported = true;

          this.config.view = "multiple";
        }
      } else if (this.isViewMultiple) {
        // fires on view multiple but view multiple not supported by chart type
        if (!this.chartHasViewMultiple) {
          flashViewNotSupported = true;
          this.config.view = "single";
        }
      } else if (this.isViewCombined) {
        // fires on view multiple but view multiple not supported by chart type
        if (!this.chartHasViewCombined) {
          flashViewNotSupported = true;
          this.config.view = "single";
        }
      }

      if (flashViewNotSupported) {
        this.flashMessages.view_not_supported = this.flash(this.$t("config.exceptions.view_not_supported"), "info");
      }

      // check if chart type requires scaled data
      if (!this.config.useScores && this.isScaledDataRequired) {
        this.flashMessages.view_req_scaled_data = this.flash(this.$t("config.exceptions.view_req_scaled_data"), "info");
        this.config.useScores = true;
      }
      // fires when chart type does not support canton selection
      if (!this.isCantonSelectionAllowed && this.config.useCantonRestriction) {
        this.flashMessages.no_canton_selection = this.flash(this.$t("config.exceptions.no_canton_selection"), "info");
        this.config.useCantonRestriction = false;
      }
    },
    openShareModal() {
      this.$eventHub.$emit("open-share-modal");
    }
  }
};
</script>

<style scoped>
.w-15 {
  width: 15%;
  max-width: 15%;
}
.w-60 {
  width: 60%;
  max-width: 60%;
}
.selection .selection-item {
  cursor: pointer;
}
.selection .selection-item div.section-icon,
.selection .selection-item div.section-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.selection .selection-item div.section-actions {
  justify-content: flex-end;
}
.selection .selection-item .close {
  font-size: 28px;
}
.btn-text-right {
  text-align: right;
  margin-bottom: 2rem;
}
</style>
<style>
.vue-slider-mark-label {
  font-size: 0.9em;
}
</style>
