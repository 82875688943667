<template>
  <div class="contentPages configurator-raw-data w-100 h-100">
    <perfect-scrollbar style="height: calc(100% - 60px)">
      <div class="container">
        <div class="entry-header mb-3">
          <h1 class="title mb-0">{{ post.title }} - {{ post.date }}</h1>
          <p class="text-muted"></p>
          <p>
            <router-link :to="'/pages/posts'" title="Zurück zu den Posts">
              Zurück zu den Posts
            </router-link>
          </p>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b-card
              :title="post.title"
              :img-src="post.img"
              img-alt="Image"
              img-top
            >
              <b-card-text>
                {{ post.content }}
              </b-card-text>
            </b-card>
          </div>

          <div class="col-md-8">
            <div class="row">
              <div
                v-for="indicator in visibleIndicators"
                :key="indicator.id"
                class="col-md-3 mb-4"
              >
                <b-card
                  :title="indicator.name"
                  tag="article"
                  style="max-width: 20rem;"
                  class="mb-2"
                >
                  <b-card-text>
                    {{ indicator.Posts_Indicators.description }}
                  </b-card-text>

                  <!-- <button href="#" class="btn btn-primary">Scores</button> -->
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import PostsSrv from "../../services/PostSrv";
export default {
  name: "PostPage",
  data() {
    return {
      post: {}
    };
  },
  computed: {
    visibleIndicators() {
      let indicators = [];
      if (this.post.Indicators) {
        this.post.Indicators.forEach((e) => {
          if (e.Posts_Indicators.showInPost) {
            indicators.push(e);
          }
        });
      }

      return indicators;
    }
  },
  mounted() {
    PostsSrv.getPost(this.$route.params.postId).then((post) => {
      this.post = post;
    });
  },
  methods: {}
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

h1 {
  font-weight: bold;
  font-size: 32px;
  color: #0364a6;
  font-family: "Varela Round", sans-serif;
}
.welcome,
.welcome p {
  font-size: 1.06em;
}

a {
  color: #0364a6;
  font-weight: 600;
}
</style>
