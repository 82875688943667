<template>
  <div class="content p-relative">
    <card-component class="login-card" title="Bei CEIS anmelden">
      <form class="login-form">
        <div class="form-group">
          <label for="email">E-Mail</label>
          <input
            id="email"
            v-model="email"
            type="text"
            name="email"
            class="form-control"
            autocomplete="on"
          />
        </div>

        <div class="form-group">
          <label for="password">Passwort</label>
          <input
            id="password"
            v-model="password"
            type="password"
            name="password"
            class="form-control"
            autocomplete="on"
          />
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary w-100" @click="handleSubmit">
            Login
          </button>
        </div>
      </form>
    </card-component>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      password: "",
      email: ""
    };
  },
  computed: {
    username() {
      return this.$store.state.auth.user ? this.$store.state.auth.user.username : "";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  methods: {
    handleSubmit: function(e) {
      e.preventDefault();

      if (this.password.length > 0) {
        let payload = {
          email: this.email,
          password: this.password
        };
        this.$store.dispatch("auth/login", payload).then(user => {
          if (user.role === "admin") {
            this.$router.push("/admin/");
          } else {
            if (typeof this.$route.query.fullPath != "undefined") {
              this.$router.push(this.$route.query.fullPath);
            } else {
              this.$router.push("/configurator");
            }
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.login-wrapper {
  width: 600px;
  margin: 0 auto;
  padding: 60px;
}

.login-card {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
