export default class Chartdata {
  _id;
  title; // chart title
  labels; // chart labels
  datasets;
  isValid; // boolean
  errorMessage;
  styles;
  options;
  meta;

  #defaultData = null;

  // private properties used to apply scores
  #ds_key;
  #ds_ids;
  #ls_key;
  #ls_ids;

  constructor(labels, id, title, options = null, meta = null, styles = null) {
    this._id = id;
    this.title = title;
    this.labels = labels;
    this.datasets = [];

    // error related keys
    this.isValid = true;
    this.errorMessage = "";

    // optional (chart related) properties
    this.styles = styles || {};
    this.options = options || {};
    this.meta = meta || {};
    this.isScoreTopDown = true;

    this.#ls_key = "indicator.name"; // label key
    this.#ls_ids = this.labels;
  }

  setDefaultData(d) {
    this.#defaultData = d;
  }

  // takes a set of cantons to create datasets
  createDatasetsFromCantons(cantons, color, item) {
    // define default keys and ids to apply scores to the datasets
    this.#ds_key = "canton"; // dataset key
    this.#ds_ids = cantons.map(c => c.id);

    // get the "direction" from within the first canton
    if (item && item.indicator) {
      this.isScoreTopDown = item.indicator.isScoreTopDown;
    }

    this.datasets = cantons.map(canton => ({
      id: canton.id,
      label: canton.id,
      color: color,
      data: Array(this.labels.length).fill(this.#defaultData),
      properties: {
        cantonName: canton.properties.name,
        canton: canton.id,
        indicatorId: item ? item.indicator.id : ""
      }
    }));
  }

  createDatasetsFromCantonsAndIndicators(cantons, items) {
    this.datasets = cantons
      .map(canton => {
        return items.map(item => ({
          id: canton.id + item.indicator.id,
          label: canton.id,
          color: item.color,
          data: Array(this.labels.length).fill(this.#defaultData),
          properties: {
            canton: canton.id,
            cantonName: canton.name,
            indicatorId: item.indicator.id
          }
        }));
      })
      .flat();

    // define default keys and ids to apply scores to the datasets
    this.#ds_key = ["canton", "indicatorId"]; // dataset key
    this.#ds_ids = this.datasets.map(c => c.id);
  }

  setLabelKey(key) {
    this.#ls_key = key;
  }

  // takes a set of scores and applies them to the datasets by previously defined ds_key and ls_key
  applyScores(scores, useScores = true) {
    if (scores && scores.length > 0) {
      scores.forEach(s => {
        const ls_key = this.#ls_key;
        const ds_key = this.#ds_key;
        let id_l = this.#ls_ids.indexOf(s[ls_key]);
        let id_ds = this.#ds_ids.indexOf(
          Array.isArray(ds_key) ? this.#ds_key.map(k => s[k]).join("") : s[this.#ds_key]
        );
        if (id_ds > -1 && id_l > -1) {
          this.datasets[id_ds].data[id_l] = useScores ? s.scoreNew : s.score;
        }
      });
    }
  }
}
