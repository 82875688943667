<template>
  <icon-base v-bind="$props" viewport="0 0 32 24">
    <path
      class="st0"
      d="M31.98,13.34c-0.13-0.29-0.25-0.58-0.38-0.87c0.11-0.66,0.21-1.32,0.32-1.98l0.03-0.22l-0.19-0.12l-0.92-0.58
	l-0.21-0.13L30.45,9.6c-0.47,0.4-0.94,0.79-1.41,1.19c-0.46-0.22-0.93-0.44-1.39-0.66l-1.98-2.67c0.18-0.7,0.36-1.39,0.54-2.09
	l0.07-0.26l-0.23-0.13L21.8,2.69l-0.07-0.04l-0.08,0c-0.61-0.03-1.21-0.05-1.82-0.08c-0.39-0.26-0.78-0.52-1.17-0.78l-0.24-0.16
	l-0.2,0.2l-1.08,1.12l-0.09,0.1V3.2v0.39c-0.26-0.1-0.53-0.19-0.79-0.29l-0.11-0.04l-0.11,0.04c-0.63,0.22-1.27,0.45-1.9,0.67
	c-0.69-0.17-1.38-0.34-2.07-0.52L11.88,3.4l-0.14,0.14c-0.38,0.38-0.76,0.76-1.14,1.14c-0.5,0.14-1,0.27-1.5,0.41L7.93,4.86
	l-0.2-0.04L7.6,4.98L6.77,6.02L6.63,6.2l0.1,0.2c0.11,0.22,0.23,0.43,0.34,0.65C5.98,8.12,4.89,9.2,3.8,10.28l-0.09,0.09L3.7,10.49
	L3.59,11.9c-0.68,0.64-1.35,1.27-2.03,1.91l-0.08,0.07l-0.02,0.1c-0.12,0.53-0.24,1.06-0.36,1.59c-0.32,0.41-0.64,0.82-0.95,1.23
	l-0.12,0.15l0.07,0.18l0.5,1.33l0.11,0.29L1,18.67l2.21-0.58l0.25-0.07v-0.26v-0.87c0.59-0.3,1.18-0.59,1.78-0.89
	c0.42,0.11,0.83,0.23,1.25,0.34c-0.07,0.46-0.14,0.91-0.2,1.37l-0.02,0.11l0.05,0.1l1.83,3.54l0.13,0.24l0.26-0.07
	c1.22-0.34,2.44-0.67,3.67-1.01c0.46,0.22,0.92,0.44,1.38,0.65l0.22,0.11l0.17-0.18l2.21-2.37l0.11-0.12l-0.03-0.16
	c-0.07-0.44-0.15-0.89-0.22-1.33c0.26-0.28,0.52-0.56,0.79-0.84c0.06,0.37,0.12,0.74,0.18,1.11l0.02,0.13l0.1,0.08l1.96,1.58
	l0.07,0.05l0.08,0.02c0.19,0.04,0.39,0.08,0.58,0.12c0.02,0.29,0.04,0.58,0.06,0.87l0.01,0.09l0.05,0.08l0.96,1.37l0.22,0.31
	l0.28-0.25l1.04-0.92l0.16-0.14l-0.06-0.21c-0.12-0.41-0.25-0.81-0.37-1.22c0.48-0.71,0.97-1.41,1.45-2.11l0.05-0.07l0.01-0.09
	c0.05-0.48,0.1-0.97,0.15-1.45c0.06-0.03,0.12-0.07,0.18-0.11c0.1,0.35,0.2,0.69,0.3,1.04l0.05,0.17l0.17,0.06l1.71,0.54l0.16,0.05
	l0.14-0.09c0.32-0.22,0.65-0.44,0.97-0.66c0.21,0.4,0.41,0.8,0.62,1.2l0.11,0.21l0.24-0.03l1.25-0.17l0.42-0.06l-0.15-0.4
	c-0.14-0.37-0.28-0.74-0.42-1.12l0.15-0.78l0.06-0.3l-0.3-0.08l-0.28-0.08c0.06-0.29,0.12-0.59,0.18-0.88h0.1
	c0.08,0.15,0.16,0.3,0.24,0.44l0.11,0.21l0.24-0.04l1.37-0.21l0.2-0.03l0.07-0.19l0.29-0.83l0.04-0.13L31.98,13.34z M20,11.57h-2.77
	v2.77h-2.39v-2.77h-2.77V9.18h2.77V6.41h2.39v2.77H20V11.57z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconMap",
  mixins: [iconMixin]
};
</script>
