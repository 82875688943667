<template>
  <v-tour :steps="steps" :options="myOptions" :callbacks="myCallbacks" name="helpTour"
    ><template slot-scope="tour">
      <transition name="fade">
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <template>
            <div slot="actions">
              <b-progress
                :value="tour.currentStep"
                :max="steps.length - 1"
                striped
                :animated="animate"
                height="0.5rem"
                class="mb-3"
              ></b-progress>
              <hr style="background-color: white; height: 1px;" />
              <div v-if="tour.currentStep === 0">
                <b-button pill variant="primary" size="sm" class="btn-skip" @click="tour.skip">
                  Tour beenden
                </b-button>
                <b-button pill variant="primary" size="sm" class="btn-next" @click="nextStep()">
                  Weiter
                </b-button>
              </div>
              <div
                v-if="
                  tour.currentStep !== 0 &&
                    tour.currentStep !== 1 &&
                    tour.currentStep !== 2 &&
                    tour.currentStep !== 6 &&
                    tour.currentStep !== steps.length - 1
                "
              >
                <b-button pill variant="primary" size="sm" class="btn-skip" @click="tour.skip">
                  Tour beenden
                </b-button>
                <b-button pill variant="primary" size="sm" class="btn-previous" @click="previousStep()">
                  Zurück
                </b-button>
                <b-button pill variant="primary" size="sm" class="btn-next" @click="tour.nextStep">
                  Weiter
                </b-button>
              </div>
              <div v-if="tour.currentStep === 1 || tour.currentStep === 2 || tour.currentStep === 6">
                <b-button pill variant="primary" size="sm" class="btn-skip" @click="tour.skip">
                  Tour beenden
                </b-button>
                <b-button pill variant="primary" size="sm" class="btn-previous" @click="previousStep()">
                  Zurück
                </b-button>
              </div>
              <div v-if="tour.currentStep === steps.length - 1">
                <b-button pill variant="primary" size="sm" class="btn-previous" @click="previousStep()">
                  Zurück
                </b-button>
                <b-button pill variant="primary" size="sm" @click="tour.stop">
                  Tour abschliessen
                </b-button>
              </div>
            </div>
          </template>
        </v-step>
      </transition>
    </template></v-tour
  >
</template>

<script>
export default {
  name: "HelpTour",
  data() {
    return {
      animate: true,
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Tour beenden",
          buttonPrevious: "Zurück",
          buttonNext: "Weiter",
          buttonStop: "Tour abschliessen"
        }
      },
      myCallbacks: {
        onPreviousStep: this.customPreviousCallback,
        onNextStep: this.customNextCallback
      },
      steps: [
        {
          target: "#step-0",
          header: {
            title: `<b> Herzlich Willkommen zum CEIS-Tutorial </b>`
          },
          content: `Die nachfolgende Feature-Tour stellt dir die wichtigsten Funktionalitäten des CEIS näher vor. Du kannst die Tour jederzeit mittels dem 'Tour beenden'-Button abbrechen. <br> Viel Spass beim Kennenlernen des CEIS :-)`,
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#sideBarBtn",
          header: {
            title: `<b> Herzlich Willkommen zum CEIS-Tutorial </b>`
          },
          content: `Die nachfolgende Feature-Tour stellt dir die wichtigsten Funktionalitäten des CEIS näher vor. Du kannst die Tour jederzeit mittels dem 'Tour beenden'-Button abbrechen. <br> Viel Spass beim Kennenlernen des CEIS :-)`,
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#step-1",
          content: `Der CEIS funktioniert wie ein Konfigurator. In wenigen Schritten lassen sich Daten in verschiedenen Grafiken abbilden, um Kantone zu vergleichen.  Drücke dazu auf <em> rechten Button</em>, um mit der Auswahl von Vergleichswerten zu beginnen.`,
          params: {
            placement: "right",
            enableScrolling: false
          }
        },
        {
          target: "#step-2",
          content: `Hier findest du eine Aufzählung aller möglichen Vergleichswerte. Wähle einige Vergleichswerte aus und drücke auf <em>"Weiter"</em>, um mit der Tour fortzufahren.`,
          params: {
            placement: "left",
            enableScrolling: false
          },
          before: (type) =>
            new Promise((resolve, reject) => {
              let checkExist = setInterval(() => {
                if (document.querySelector("#step-2")) {
                  resolve();
                  clearInterval(checkExist);
                } else {
                  reject("not FOUND");
                }
              }, 200);
            })
        },
        {
          target: "#step-3",
          content: "Hier hast du die Möglichkeit die Vergleichswerte nach Sektoren zu sortieren.",
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#step-4",
          content:
            "Nutze diese Funktionen, um Vergleichswerte zu suchen, oder diese nach Indikatoren / Politikmassnahmen zu filtern.",
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#step-5",
          content:
            "Hast du dir eine Auswahl von Vergleichswerten zusammengestellt, kannst du dir die Diagramme mit Klick auf diesen Button anzeigen lassen. Probier es doch gleich einmal aus.",
          params: {
            placement: "right",
            enableScrolling: false
          }
        },
        {
          target: "#step-6",
          content: `Super! Die von dir ausgewählten Vergleichswerte sollten nun als Diagramme dargestellt sein. <br> Um die Diagramme anzupassen kannst du Anpassungen an diesen Einstellungen vornehmen. Wenn du mit der Maus über die Beschreibung fährst, erhälst du genauere Informationen zur jeweiligen Einstellung.`,
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#step-7",
          content: `Hier kannst du den Diagrammtyp für deine ausgewählten Vergleichswerte ändern. <br> Du kannst zwischen Balkendiagram, Schweizerkarte, Trend und Spider wählen.`,
          params: {
            placement: "right",
            enableScrolling: false
          }
        },
        {
          target: "#step-8",
          content: `Hier kannst du dir die Quellen der Vergleichswerte anzeigen lassen. <br> Woher stammen die Daten der von Dir gestalteten Abbildung?`,
          params: {
            placement: "right",
            enableScrolling: false
          }
        },
        {
          target: "#step-9",
          content: `Unter Tabelle kannst du dir die Daten-Tabelle, auf der Deine Abbildung basiert, exportieren.`,
          params: {
            placement: "right",
            enableScrolling: false
          }
        },
        {
          target: "#step-10",
          content: `Unter diesem Punkt findest du den News-Feed. Sind beispielsweise neue Daten vorhanden, findest du einen dazugehörigen Post in diesem Tab.`,
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#step-11",
          content: `Hier hast du die Möglichkeit die aktuelle Ansicht zu teilen. Du musst lediglich den Link kopieren und diesen verschicken.`,
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#step-0",
          header: {
            title: `<b> Ende des CEIS-Tutorial </b>`
          },
          content: `Super! Du bist am Ende des Tutorials angelangt. Wir hoffen, dass du nun die wichtigsten Funktionen besser verstehst und wünschen dir viel Spass bei der Nutzung des CEIS. Bei weiteren Fragen kannst du dich gerne an <a style="color: white;" href="mailto:boog@zhaw.ch"><em>boog@zhaw.ch</em></a> wenden.`,
          params: {
            placement: "left",
            enableScrolling: false
          }
        }
      ]
    };
  },
  created() {
    this.$eventHub.$on("sideBarBtnPressed", () => {
      if (this.$tours["helpTour"].currentStep === 1) {
        setTimeout(() => {
          this.$tours["helpTour"].nextStep();
        }, 500);
      }
    });
    this.$eventHub.$on("selectionModalOpened", () => {
      if (this.$tours["helpTour"].currentStep === 2) {
        this.$tours["helpTour"].nextStep();
      }
    });
    this.$eventHub.$on("selectionModalClosed", () => {
      if (this.$tours["helpTour"].currentStep === 6) {
        this.$tours["helpTour"].nextStep();
      }
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("sideBarBtnPressed");
    this.$eventHub.$off("selectionModalOpened");
    this.$eventHub.$off("selectionModalClosed");
  },
  mounted() {},
  methods: {
    customNextCallback(currentStep) {
    },
    customPreviousCallback(currentStep) {
      if (currentStep === 3) {
        this.$eventHub.$emit("closeSelectionModal");
      }
    },
    previousStep() {
      if (this.$tours["helpTour"].currentStep === 2) {
        if (document.querySelector("#sideBarBtn")) {
          this.$sidebar.displaySidebar(false);
          this.$tours["helpTour"].previousStep();
        } else {
          this.$tours["helpTour"].currentStep = 0;
          this.$tours["helpTour"].previousStep();
        }
      } else if (this.$tours["helpTour"].currentStep === 6) {
        this.$tours["helpTour"].previousStep();
      } else if (this.$tours["helpTour"].currentStep !== 7) {
        this.$tours["helpTour"].previousStep();
      } else if (this.$tours["helpTour"].currentStep === 7) {
        this.$eventHub.$emit("open-configurator-selection-modal", {
          selection: []
        });
        setTimeout(() => {
          this.$tours["helpTour"].previousStep();
        }, 200);
      }
    },
    nextStep() {
      if (document.querySelector("#sideBarBtn")) {
        this.$tours["helpTour"].nextStep();
      } else {
        this.$tours["helpTour"].currentStep = 1;
        this.$tours["helpTour"].nextStep();
      }
    }
  }
};
</script>

<style>
:root {
  font-size: 87.5%;
}
</style>

<style scoped>
.btn-skip {
  margin-right: 0.5rem;
}
.btn-previous {
  margin-right: 0.5rem;
}
.btn-next {
  margin-right: 0.5rem;
}
</style>
