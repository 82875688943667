import axios from "../axios";

class ScoreService {
  constructor() {
    this.indicatorScoreChache = [];
    this.sectorScoreCache = [];
  }

  getSectorScores = function(sectorId) {
    if (typeof this.sectorScoreCache[sectorId] !== "undefined") {
      return Promise.resolve(this.sectorScoreCache[sectorId]);
    }
    return axios.get("api/scores", { params: { sectorId: sectorId } }).then(response => {
      this.sectorScoreCache[sectorId] = response.data;
      return response.data;
    });
  };

  getScoresByIndicator = function(indicatorId) {
    if (typeof this.indicatorScoreChache[indicatorId] !== "undefined") {
      return Promise.resolve(this.indicatorScoreChache[indicatorId]);
    }
    return axios.get("api/scores", { params: { indicatorId: indicatorId } }).then(response => {
      this.indicatorScoreChache[indicatorId] = response.data;
      return response.data;
    });
  };

  clearCache() {
    this.indicatorScoreChache = [];
    this.sectorScoreCache = [];
  }
}

export default new ScoreService();
