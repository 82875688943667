<template>
  <icon-base v-bind="$props" viewport="0 0 28 28">
    <path
      :style="style"
      class="st0"
      d="M9.99,19.61c1,1,1.57,2.36,1.57,3.78v4.61H6.6l0-3.11l-5.68-5.68l0-8.35c0-0.6,0.44-1.13,1.04-1.18
	c0.68-0.06,1.25,0.47,1.25,1.14v6.3l3.06,3.06c0.14,0.14,0.38,0.15,0.52,0.01c0.16-0.15,0.16-0.39,0.01-0.54l-2.44-2.45
	c-0.46-0.46-0.45-1.23,0.06-1.67c0.46-0.4,1.16-0.35,1.59,0.08C6.82,16.44,8.65,18.27,9.99,19.61z M18.13,19.61
	c-1,1-1.57,2.36-1.57,3.78v4.61h4.96l0-3.11l5.68-5.68l0-8.35c0-0.6-0.44-1.13-1.04-1.18c-0.68-0.06-1.25,0.47-1.25,1.14v6.3
	l-3.06,3.06c-0.14,0.14-0.38,0.15-0.52,0.01c-0.16-0.15-0.16-0.39-0.01-0.54l2.44-2.45c0.46-0.46,0.45-1.23-0.06-1.67
	c-0.46-0.4-1.16-0.35-1.59,0.08C21.3,16.44,19.47,18.27,18.13,19.61z M20.7,6.8v0.02c0,1.92-0.81,3.75-2.23,5.04
	c-0.53,0.48-0.95,1.05-1.23,1.71c-0.29,0.65-0.44,1.35-0.44,2.06v0.16c0.2,0.08,0.33,0.27,0.33,0.49v2.01
	c0,0.29-0.24,0.53-0.53,0.53h-0.54c-0.26,0.89-1.08,1.54-2.06,1.54s-1.8-0.65-2.06-1.54h-0.42c-0.29,0-0.53-0.24-0.53-0.53v-2.01
	c0-0.22,0.13-0.4,0.32-0.48v-0.16c0-0.72-0.15-1.41-0.44-2.06c-0.29-0.65-0.7-1.23-1.23-1.71c-1.42-1.29-2.23-3.12-2.23-5.04V6.8
	c0-1.78,0.69-3.47,1.93-4.75c1.24-1.27,2.91-2,4.69-2.05l0.03,0l0.01,0c1.78,0.04,3.44,0.77,4.69,2.05
	C20.01,3.33,20.7,5.02,20.7,6.8z M19.6,6.8c0-3.11-2.44-5.62-5.55-5.7c-3.11,0.08-5.55,2.58-5.55,5.7v0.02
	c0,1.61,0.68,3.15,1.87,4.23c0.64,0.58,1.15,1.28,1.5,2.07c0.35,0.79,0.53,1.64,0.53,2.5v0.12h3.29v-0.12c0-0.87,0.18-1.71,0.53-2.5
	c0.35-0.79,0.86-1.49,1.5-2.07c1.19-1.08,1.87-2.62,1.87-4.23V6.8z M11.2,7.71c0,1.33,0.88,2.46,2.09,2.84v2.31
	c0,0.24,0.2,0.44,0.44,0.44c0.24,0,0.44-0.2,0.44-0.44V3.19C14.17,3.19,11.2,6.07,11.2,7.71z M17.13,7.71
	c0-1.14-1.44-2.88-2.31-3.84V6.3l0.36-0.36c0.13-0.13,0.34-0.13,0.46,0c0.13,0.13,0.13,0.34,0,0.46l-0.82,0.82v1.33l1-1
	c0.13-0.13,0.34-0.13,0.46,0c0.13,0.13,0.13,0.34,0,0.46l-1.47,1.47v1.12C16.15,10.31,17.13,9.12,17.13,7.71z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconSectorRenewableEnergy",
  mixins: [iconMixin],
  computed: {
    style() {
      return `fill-rule:evenodd; clip-rule:evenodd; fill: ${this.color}`;
    }
  }
};
</script>
