import axios from "axios";
import Router from "./router";

// create an axios instance
const instance = axios.create({
  baseURL: "/",
  withCredentials: true
});

// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // If status code 403 (or 401??) redirect to login
    if (error.response.status === 401) {
      // redirect to login page
      Router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default instance;
