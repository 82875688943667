<template>
  <icon-base v-bind="$props" viewport="0 0 28 28">
    <path
      :style="style"
      class="st0"
      d="M26.83,25.95V7.57h-3.12V6.45h-6.5v1.12v3.52h1.96v2.88h-1.96v1.43h1.96v2.88h-1.96v1.43h1.96v2.88h-1.96v3.36
	H16.6V1.12h-3.28V0.01H4.46v1.11H1.18v24.83H0v2.06h28v-2.06H26.83z M20.87,11.09h2.91v2.88h-2.91V11.09z M20.87,15.4h2.91v2.88
	h-2.91V15.4z M20.87,19.72h2.91v2.88h-2.91V19.72z M10.15,5.14h4.29v4.25h-4.29V5.14z M10.15,11.5h4.29v4.25h-4.29V11.5z
	 M10.15,17.85h4.29v4.25h-4.29V17.85z M3.34,5.14h4.29v4.25H3.34V5.14z M3.34,11.5h4.29v4.25H3.34V11.5z M3.34,17.85h4.29v4.25H3.34
	V17.85z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconSectorBuilding",
  mixins: [iconMixin]
};
</script>
