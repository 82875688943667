<template>
  <icon-base v-bind="$props" viewport="0 0 32 24">
    <g>
      <polygon
        class="st0"
        points="6.13,0.02 4.02,0.02 4.02,24.02 27.93,24.02 27.93,21.91 6.13,21.91 6.13,0.02 6.13,0.02 	"
      ></polygon>
      <polygon class="st0" points="8.23,7.48 12.05,7.48 12.05,19.82 8.23,19.82 8.23,7.48"></polygon>
      <polygon
        class="st0"
        points="13.49,0.02 17.41,0.02 17.41,19.82 13.49,19.82 13.49,0.02"
      ></polygon>
      <polygon
        class="st0"
        points="18.75,7.48 22.67,7.48 22.67,19.82 18.75,19.82 18.75,7.48"
      ></polygon>
      <polygon
        class="st0"
        points="24.1,13.41 27.93,13.41 27.93,19.82 24.1,19.82 24.1,13.41"
      ></polygon>
    </g>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconBarChart",
  mixins: [iconMixin]
};
</script>
