<template>
  <div v-if="chartdata" class="bar-chart">
    <base-chart
      :id="selector"
      :height="height"
      :width="width"
      :data="chartdata"
      :styles="_styles"
      @chart-mounted="initChart"
      @chart-resized="handleResizeChart"
      @data-changed="updateChartData"
    >
      <g class="bar-chart-wrapper">
        <g class="y-axis">
          <g class="grid"></g>
          <g class="ticks"></g>
        </g>
        <g class="x-axis" :transform="'translate(0,' + innerHeight + ')'">
          <g class="ticks"></g>
        </g>
        <text
          class="chart-label x-axis-label"
          :transform="xLabelTransform"
          style="text-anchor: middle"
          >{{ chartdata.options.xLabel }}</text
        >
        <text
          class="chart-label y-axis-label"
          :transform="yLabelTransform"
          style="text-anchor: middle"
          >{{ chartdata.options.yLabel }}</text
        >
      </g>

      <!--      <template slot="afterChart">-->
      <!--        <div class="tooltip" :id="tooltipSelector">-->
      <!--          <h5 class="tooltip-title mb-0">{{ tooltipName }}</h5>-->
      <!--          <span class="score">{{ tooltipScore }} {{ tooltipUnit }}</span>-->
      <!--        </div>-->
      <!--      </template>-->
    </base-chart>
  </div>
</template>

<script>
import * as d3 from "d3";
import BaseChart from "./BaseChart";
import ChartStylesMixin from "../../mixins/ChartStylesMixin";
import _ from "lodash";

export default {
  name: "BarChart",
  components: {
    BaseChart
  },
  mixins: [ChartStylesMixin],
  props: {
    selector: {
      type: String,
      default: "indicator-bar-chart"
    },
    height: {
      type: Number,
      default: 500,
      description: "Defines the height of the map"
    },
    styles: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 700,
      description: "Defines the width of the map"
    },
    chartdata: {
      type: Object,
      description: "All the data for this chart",
      default: () => {}
    }
  },
  data() {
    return {
      svg: null,
      x0: null,
      x1: null,
      y: null,
      chartReady: false,
      hoveredBar: null,
      tooltipSelector: this.selector + "barTooltip"
    };
  },
  computed: {
    barStyles: function() {
      return {
        fill: this._styles.primaryColor
      };
    },
    tooltipName() {
      return this.hoveredBar ? this.hoveredBar.label : "";
    },
    tooltipScore() {
      return this.hoveredBar && typeof this.hoveredBar.data[0] !== "undefined"
        ? this.hoveredBar.data[0]
        : 0;
    },
    tooltipYear() {
      return this.hoveredBar && typeof this.hoveredBar.data[0] !== "undefined"
        ? this.hoveredBar.meta.year
        : 0;
    },
    tooltipUnit() {
      return this.hoveredBar && typeof this.hoveredBar.data[0] !== "undefined"
        ? this.hoveredBar.meta.unit
        : 0;
    }
  },
  mounted() {
    this.$eventHub.$on("canton-mouse-enter", this.handleCantonMouseEnter);
    this.$eventHub.$on("canton-mouse-out", this.handleCantonMouseOut);
  },
  methods: {
    initChart: function() {
      // select svg element
      this.svg = d3.select("#" + this.selector).select("svg");
      this.chartReady = true;
    },
    transition(name = "bar-chart-transition") {
      return d3.transition(name).duration(this.transDuration(750));
    },
    updateChartData: function(chartdata) {
      let _this = this;
      // create array of sorted labels
      const labels = chartdata.datasets.sort((a, b) => a.data[0] - b.data[0]).map(ds => ds.label);
      // create scales for both x and y axis
      let x0 = d3.scaleBand().rangeRound([0, this.innerWidth], 0.5);
      let x1 = d3.scaleBand().padding(0.2);
      let y = d3.scaleLinear().rangeRound([this.innerHeight, 0]);

      // Create y grid lines
      let yGridLines = d3
        .axisLeft()
        .scale(y)
        .tickSize(-this.innerWidth)
        .ticks(4)
        .tickFormat("");
      this.svg
        .select(".y-axis .grid")
        .transition(this.transition())
        .ease(d3.easeQuad)
        .call(yGridLines);

      // create both axis
      let xAxis = d3
        .axisBottom()
        .scale(x0.padding(0.1))
        .tickValues(labels);
      let yAxis = d3.axisLeft().scale(y);

      // create domain with margin for y
      let y_domain = chartdata.options.range || null;
      if (y_domain == null) {
        y_domain = d3.extent(_.flatten(_.map(chartdata.datasets, "data")));
        const y_margin = (y_domain[1] - y_domain[0]) / 5;
        y_domain[0] = y_domain[0] - y_margin > 0 ? y_domain[0] - y_margin : 0;
        y_domain[1] = y_domain[1] + y_margin;
      }

      // define axes domains
      x0.domain(labels);
      x1.domain(chartdata.labels).rangeRound([0, x0.bandwidth()]);
      y.domain(y_domain);

      // create both axis
      this.svg
        .select(".x-axis .ticks")
        .transition(this.transition("x-axis"))
        .ease(d3.easeQuad)
        .call(xAxis)
        .selectAll(".tick")
        .delay((d, i) => i * this.transDuration(20));
      this.svg
        .select(".y-axis .ticks")
        .transition(this.transition("y-axis"))
        .ease(d3.easeQuad)
        .call(yAxis);

      const wrapper = this.svg.select(".bar-chart-wrapper");

      const groups = wrapper
        .selectAll("g.slice")
        .data(chartdata.datasets, d => d.id) // make d3 know what to delete
        .join(
          enter => enter.append("g").attr("class", d => "slice " + d.properties.canton),
          update => update,
          exit =>
            exit
              .selectAll("rect.bar")
              .style("fill", "red")
              .transition(this.transition("group-exit"))
              .attr("transform", () => "translate(0 -2000)")
              .remove()
        );

      groups
        .transition(this.transition("group-enter-append"))
        .attr("transform", d => "translate(" + x0(d.label) + ",0)");

      groups
        .selectAll("rect.bar")
        .data(d => _.zip(d.data, d.color, Array(d.data.length).fill(d.id)))
        .join(
          enter => enter.append("rect").attr("class", d => "bar " + d[2]),
          update => update.attr("class", d => "bar " + d[2]),
          exit => exit.remove()
        )
        .style("fill", d => d[1])
        // add transitions
        .transition(this.transition("bar-enter-append"))
        .attr("x", (d, i) => x1(chartdata.labels[i]))
        .attr("width", x1.bandwidth())
        .attr("y", d => y(d[0]))
        .attr("height", d => {
          if (typeof d[0] === "undefined") return 0;
          return this.innerHeight - y(d[0]);
        })
        .on("end", function() {
          // add events after transitions have finished
          d3.select(this)
            .on("mouseenter.e", c => _this.$eventHub.$emit("canton-mouse-enter", c[2]))
            .on("mouseout.e", c => _this.$eventHub.$emit("canton-mouse-out", c[2]))
            // .on("mouseenter.t", _this.barHover)
            .on("mousemove.t", _this.showTooltip)
            .on("mouseout.t", _this.hideTooltip);
        });
    },
    handleResizeChart: function() {
      if (this.chartReady && this.chartdata.datasets.length > 0) {
        this.$nextTick(() => {
          this.initChart();
          this.$nextTick(() => this.updateChartData(this.chartdata));
        });
      }
    },
    handleCantonMouseEnter: function(cantonId) {
      this.svg
        .selectAll(`rect.${cantonId}`)
        .transition("canton-mouse-enter")
        .duration(300)
        .style("fill", this._styles.chartHoverColor);
    },
    handleCantonMouseOut: function(cantonId) {
      this.svg
        .selectAll(`rect.${cantonId}`)
        .transition("canton-mouse-out")
        .duration(300)
        .style("fill", d => d[1]);
    },
    // barHover: function(d, i, nodes) {
    // this.hoveredBar = d3.select(nodes[i]).data()[0];
    // },
    showTooltip: function() {
      let mouse = d3.mouse(this.svg.node()).map(d => parseInt(d)); // track the mouse
      d3.select("#" + this.tooltipSelector)
        .style("left", mouse[0] + 15 + "px")
        .style("top", mouse[1] + 30 + "px")
        .style("opacity", 0.9);
    },
    hideTooltip: function() {
      d3.select("#" + this.tooltipSelector).style("opacity", 0);
    }
  }
};
</script>

<style scoped>
div.tooltip {
  position: absolute;
  text-align: left;
  padding: 4px;
  font: 12px sans-serif;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 8px;
  pointer-events: none;
}
div.tooltip .score {
  font-weight: 600;
}
</style>
