import IconBase from "../components/Icons/IconBase";

export default {
  props: {
    iconName: {
      type: String,
      default: "box"
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: null
    }
  },
  components: {
    IconBase
  },
  computed: {
    style() {
      return this.color ? `fill-rule:evenodd; clip-rule:evenodd; fill: ${this.color}` : "";
    }
  }
};
