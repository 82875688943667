<template>
  <div ref="conf-raw-data" class="configurator-raw-data w-100 h-100">
    <perfect-scrollbar class="pr-3 w-100 h-100" :options="{ suppressScrollX: true }">
      <div class="entry-header mb-3">
        <h2>{{ $t("pages.sources.title") }}</h2>
        <p class="text-muted">{{ $t("pages.sources.desc") }}</p>
      </div>

      <div class="row">
        <div v-for="item in config.selection" :key="item._id" class="col-12 col-lg-6 col-xl-4">
          <div class="card">
            <div class="card-header text-center w-100 indicator-modal-header pb-3">
              <component
                :is="sectorIcon(item.indicator.sectorId)"
                :color="'#' + sectorColor(item.indicator.sectorId)"
                :width="50"
                :height="50"
                class="p-2"
              ></component>

              <h3
                class="title w-100 mb-0 text-center mt-1 px-4"
                :style="'color: #' + sectorColor(item.indicator.sectorId)"
              >
                {{ item.indicator.name }}
              </h3>
            </div>

            <div class="card-body text-muted">
              <div class="desc py-3">
                <p>
                  {{ item.indicator.description || $t("pages.sources.desc_not_found") }}
                </p>
              </div>
              <div class="sources py-3 overflow-hidden">
                <h4 class="mb-1">{{ $t("sources") }}</h4>
                <dl v-if="item.indicator.sources" class="w-100 small">
                  <template v-for="(s, index) in item.indicator.sources">
                    <dt :key="'n' + index">{{ s.name }}</dt>
                    <dd :key="'s' + index">
                      <a :href="s.source">{{ s.source }}</a>
                    </dd>
                  </template>
                </dl>
                <p v-if="!item.indicator.sources" class="font-italic">
                  {{ $t("pages.sources.no_sources") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import SectorSrv from "../../../services/SectorSrv";
import {
  IconSectorMobility,
  IconSectorBuildings,
  IconSectorGases,
  IconSectorRenewableEnergy,
  IconSectorData
} from "../../Icons/sectors";

export default {
  name: "ConfiguratorContentSources",
  components: {
    IconSectorMobility,
    IconSectorBuildings,
    IconSectorGases,
    IconSectorRenewableEnergy,
    IconSectorData
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    sectorIcon(id) {
      return SectorSrv.getSectorIcon(id);
    },
    sectorColor(id) {
      return SectorSrv.getSectorColor(id);
    }
  }
};
</script>

<style scoped>
.sources {
  border-top: 6px solid #eee;
}
</style>
