import axios from "axios";

// because we dont want the default interceptor we define a new axios instance here
let _axios = axios.create({
  baseURL: "/",
  withCredentials: true
});

class AuthService {
  isAuth() {
    return _axios
      .get("api/session")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }
  login(email, password) {
    return _axios
      .post("api/session", { email: email, password: password })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }
  logout() {
    return _axios
      .delete("api/session")
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }
}

export default new AuthService();
