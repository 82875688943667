class CantonSrv {
  getCantons() {
    return [
      { canton: "AG", id: "AG", properties: { name: "Aargau" } },
      { canton: "AI", id: "AI", properties: { name: "Appenzell Innerrhoden" } },
      { canton: "AR", id: "AR", properties: { name: "Appenzell Ausserrhoden" } },
      { canton: "BE", id: "BE", properties: { name: "Bern" } },
      { canton: "BL", id: "BL", properties: { name: "Basel-Landschaft" } },
      { canton: "BS", id: "BS", properties: { name: "Basel-Stadt" } },
      { canton: "FR", id: "FR", properties: { name: "Freiburg" } },
      { canton: "GE", id: "GE", properties: { name: "Genf" } },
      { canton: "GL", id: "GL", properties: { name: "Glarus" } },
      { canton: "GR", id: "GR", properties: { name: "Graubünden" } },
      { canton: "JU", id: "JU", properties: { name: "Jura" } },
      { canton: "LU", id: "LU", properties: { name: "Luzern" } },
      { canton: "NE", id: "NE", properties: { name: "Neuenburg" } },
      { canton: "NW", id: "NW", properties: { name: "Nidwalden" } },
      { canton: "OW", id: "OW", properties: { name: "Obwalden" } },
      { canton: "SG", id: "SG", properties: { name: "St. Gallen" } },
      { canton: "SH", id: "SH", properties: { name: "Schaffhausen" } },
      { canton: "SO", id: "SO", properties: { name: "Solothurn" } },
      { canton: "SZ", id: "SZ", properties: { name: "Schwyz" } },
      { canton: "TG", id: "TG", properties: { name: "Thurgau" } },
      { canton: "TI", id: "TI", properties: { name: "Tessin" } },
      { canton: "UR", id: "UR", properties: { name: "Uri" } },
      { canton: "VD", id: "VD", properties: { name: "Waadt" } },
      { canton: "VS", id: "VS", properties: { name: "Wallis" } },
      { canton: "ZG", id: "ZG", properties: { name: "Zug" } },
      { canton: "ZH", id: "ZH", properties: { name: "Zürich" } }
    ];
  }

  getCantonArray() {
    return this.getCantons().map(c => c.canton);
  }

  getCantonNames() {
    return this.getCantons().map(c => c.properties.name);
  }

  getCantonsObject() {
    return this.getCantons().map(c => ({ name: c.properties.name, id: c.id }));
  }
}

export default new CantonSrv();
