<template>
  <div class="content-area">
    <div class="row">
      <div class="col-12">
        <card-component title="CSV-Daten importieren">
          <vue-csv-import
            :url="url"
            :map-fields="mapFields"
            :auto-match-fields="true"
            :headers="true"
            :callback="onSuccessHandler"
            :catch="onErrorHandler"
          />
          <router-link :to="'/admin/posts'">
            Zur Post-Erfassung
          </router-link>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import { VueCsvImport } from "vue-csv-import";
import CantonSrv from "../../services/CantonSrv";

export default {
  name: "CSVImportPage",
  components: { VueCsvImport },
  data() {
    return {
      hasHeaders: true
    };
  },
  computed: {
    url() {
      return "/api/import/json";
    },
    mapFields() {
      let meta = ["year", "sector", "indicator", "unit", "type", "CH"];
      let cantons = CantonSrv.getCantonArray();
      return meta.concat(cantons);
    }
  },
  methods: {
    onSuccessHandler() {
      this.$eventHub.$emit("flash-message", {
        type: "success",
        message: "Daten wurden erfolgreich importiert"
      });
      window.scrollTo(0, 0);
    },
    onErrorHandler(err) {
      this.$eventHub.$emit("flash-message", {
        type: "danger",
        message:
          "Fehler beim Import. Bitte noch einmal versuchen. Details: " +
          JSON.stringify(err.message)
      });
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style scoped></style>
