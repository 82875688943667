<template>
  <icon-base v-bind="$props" viewport="0 0 28 28">
    <path
      :style="style"
      class="st0"
      d="M10.9,5h5.9c0.9,0,1.7-0.8,1.7-1.7V2.7c0-0.3-0.2-0.5-0.5-0.5h-2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2h-2
	c-0.3,0-0.5,0.2-0.5,0.5v0.6C9.2,4.2,10,5,10.9,5z M13.9,1.3c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
	C13,1.7,13.4,1.3,13.9,1.3z M19.3,9.4h-11C8,9.4,7.7,9.2,7.7,8.9S8,8.3,8.3,8.3h11c0.3,0,0.6,0.3,0.6,0.6S19.7,9.4,19.3,9.4z
	 M19.3,13.3h-11c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h11c0.3,0,0.6,0.3,0.6,0.6C19.9,13,19.7,13.3,19.3,13.3z M12.2,20.9
	H8.3c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h4.2C12.4,20.1,12.3,20.5,12.2,20.9z M14.1,17.1H8.3c-0.3,0-0.6-0.3-0.6-0.6
	c0-0.3,0.3-0.6,0.6-0.6h7.2C15,16.2,14.5,16.6,14.1,17.1z M21.4,4.3h-1.9V2.8h2c0.7,0,1.3,0.6,1.3,1.3v11.6c-0.5-0.3-1-0.5-1.5-0.7
	V4.3z M19.3,15.9c-3.3,0-6,2.7-6,6s2.7,6,6,6c3.3,0,6-2.7,6-6S22.6,15.9,19.3,15.9z M22.5,24c0.2,0.2,0.3,0.4,0.3,0.6
	c0,0.5-0.4,0.9-1,0.9c-0.2,0-0.4-0.1-0.6-0.3l-2-2l-2,2c-0.1,0.1-0.4,0.3-0.6,0.3c-0.5,0-1-0.4-1-0.9c0-0.2,0.1-0.4,0.3-0.6l2-2
	l-2-2c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.5,0.4-0.9,1-0.9c0.2,0,0.4,0.1,0.6,0.3l2,2l2-2c0.1-0.1,0.4-0.3,0.6-0.3c0.5,0,1,0.4,1,0.9
	c0,0.2-0.1,0.4-0.3,0.6l-2,2L22.5,24z M13.2,25.8h-7c-0.7,0-1.3-0.6-1.3-1.3V4.2c0-0.7,0.6-1.3,1.3-1.3h2v1.5H6.4v20h6.2
	C12.7,24.8,12.9,25.3,13.2,25.8z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../mixins/IconMixin";
export default {
  name: "IconNoDataFound",
  mixins: [iconMixin]
};
</script>
