import { render, staticRenderFns } from "./IconTable.vue?vue&type=template&id=48595ff7"
import script from "./IconTable.vue?vue&type=script&lang=js"
export * from "./IconTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports