import * as d3 from "d3";

export default {
  methods: {
    textWrap(text, width) {
      text.each(function() {
        let text = d3.select(this),
          words = text
            .text()
            .split(/[-|\s+]/)
            .reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          x = text.attr("x"),
          y = text.attr("y"),
          dy = 0, //parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("style", "width: " + width + "px; word-wrap: break-word;")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", dy + "em");
        // eslint-disable-next-line no-cond-assign
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("style", "width: " + width + "px; white; word-wrap: break-word;")
              .attr("x", x)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }
  }
};
