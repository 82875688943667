<template>
  <div class="swiss-map-chart">
    <base-chart
      :id="selector"
      :height="height"
      :width="width"
      :data="chartdata"
      :styles="_styles"
      @chart-mounted="initMap"
      @chart-resized="resizeMap"
      @data-changed="updateMap"
    >
      <g v-if="path" id="map" class="mapContainer" transform="translate(0, 0)">
        <g :class="'feature feature-canton ' + featureSelector()">
          <path
            v-for="c in features.cantons"
            :id="cantonSelector(c.id)"
            :key="c.id"
            :class="'canton ' + c.id + ' ' + cantonSelector(c.id)"
            :d="path(c)"
          ></path>
          <path class="canton-boundaries" :d="cantonBoundaries"></path>
        </g>
        <g class="feature feature-lake">
          <path v-for="l in features.lakes" :id="l.id" :key="l.id" class="lake" :d="path(l)"></path>
        </g>
      </g>

      <g class="legend map-legend">
        <map-legend
          v-if="chartdata.options.legend === legendTypes.Continuous"
          :label="mapLegendLabel"
          :data="mapLegendData"
          :transform="'translate(5, ' + ( height - 60) + ')'"
          :selector="selector + '_mapLegend'"
        ></map-legend>
        <categorical-legend
          v-if="chartdata.options.legend === legendTypes.Categorical"
          :labels="['Nein', 'Ja']"
          :colors="['#fff', _styles.primaryColor]"
          :transform="'translate(5, ' + (height - 80) + ')'"
          :selector="selector + '_mapLegend'"
        ></categorical-legend>
      </g>

      <template slot="afterChart">
        <div :id="tooltip.selector" class="tooltip">
          <h5 class="tooltip-title mb-0"></h5>
          <span v-if="chartdata.options.type === 'indicator'" class="score">
            <span v-if="chartdata.options.useScores">Score: </span>
            <span class="tooltip-score"></span>
            <span v-if="!chartdata.options.useScores" class="tooltip-unit"></span>
          </span>
          <span v-else class="score">
            <span class="tooltip-score"></span>
          </span>
        </div>
      </template>
    </base-chart>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as topojson from "topojson-client";
import swissTopo from "../../assets/topojson/ch-with-lakes.json";
import MapLegend from "./MapLegend";
import CategoricalLegend from "./legends/CategoricalLegend";
import BaseChart from "./BaseChart";
import ChartStylesMixin from "../../mixins/ChartStylesMixin";
import LegendType from "@/config/enum/LegendTypeEnum";

export default {
  name: "MapChart",
  components: {
    MapLegend,
    BaseChart,
    CategoricalLegend
  },
  filters: {
    thousandsSeparated: function(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    },
    decimal: function(value) {
      return parseFloat(value).toFixed(2);
    }
  },
  mixins: [ChartStylesMixin],
  props: {
    selector: {
      type: String,
      default: "swiss-map-chart"
    },
    height: {
      type: Number,
      default: 500,
      description: "Defines the height of the map"
    },
    width: {
      type: Number,
      default: 700,
      description: "Defines the width of the map"
    },
    styles: {
      type: Object,
      default: () => {}
    },
    chartdata: {
      type: Object,
      description: "All the data for this chart",
      default: () => {}
    }
  },
  data() {
    return {
      svg: null,
      features: {
        cantons: [],
        lakes: []
      },
      path: d3.geoPath().projection(null),
      mapInitialized: false,
      tooltip: {
        position: { left: 0, top: 0 },
        opacity: 0.0,
        selector: this.selector + "cantonTooltip",
        score: "",
        name: "",
        year: "",
        unit: ""
      },
      scales: {},
      mapLegendData: [],
      defaultStyles: {
        // overrides mixins default styles
        margin: { top: 0, right: 0, bot: 0, left: 0 }
      },
      colorScale: null,
      legendTypes: LegendType
    };
  },
  computed: {
    cantonBoundaries: function() {
      return this.path(
        topojson.mesh(swissTopo, swissTopo.objects.cantons, (a, b) => a.id !== b.id)
      );
    },
    mapLegendLabel: function() {
      if (this.data && this.data.length > 0)
        return this.data.name + " (" + this.data.meta.year + ")";
      else {
        return "";
      }
    }
  },
  created() {
    this.$eventHub.$on("canton-mouse-enter", this.cantonMouseEnter);
    this.$eventHub.$on("canton-mouse-out", this.cantonMouseOut);
  },
  mounted() {
    // get feature canton
    this.features.cantons = topojson.feature(
      swissTopo,
      JSON.parse(JSON.stringify(swissTopo.objects.cantons)) // we need a clone and not a reference
    ).features;

    // get feature lake
    this.features.lakes = topojson.feature(
      swissTopo,
      JSON.parse(JSON.stringify(swissTopo.objects.lakes)) // we need a clone and not a reference
    ).features;

    this.createProjection(this.innerWidth, this.innerHeight);
  },
  methods: {
    featureSelector() {
      return this.selector + "_feature_canton";
    },
    cantonSelector(cantonId) {
      return this.selector + cantonId;
    },
    createProjection: function(width, height) {
      height -= 40;
      let projection = d3.geoIdentity().fitExtent(
        [
          [0, 0],
          [width, height]
        ],
        topojson.feature(swissTopo, swissTopo.objects.cantons)
      );
      this.path = d3.geoPath().projection(projection);
    },
    createColorScale: function(values) {
      let type = "linear";
      let colorSchema = this._styles.colorSchema(2);
      // returning scale depending on scaleType
      let scale;
      switch (type) {
        case "ordinal":
          scale = d3
            .scaleOrdinal()
            .domain(values)
            .range(colorSchema);
          break;
        case "sequential":
          scale = d3
            .scaleSequential()
            .domain(d3.extent(values))
            .interpolator(d3.interpolateBrBG);
          break;
        case "quantile":
          scale = d3
            .scaleQuantile()
            .domain(values)
            .range(colorSchema);
          break;
        case "linear":
        default:
          scale = d3
            .scaleLinear()
            .domain(d3.extent(values))
            .range(colorSchema);
          break;
      }
      scale.type = type;
      return scale;
    },
    initMap: function() {
      // select svg and store reference
      this.svg = d3.select("#" + this.selector).select("svg");

      // grab the tooltip here
      let tooltip = d3.select("#" + this.selector).select("#" + this.tooltip.selector);

      // bind data to cantons, define scale and add events
      this.svg
        .select("." + this.featureSelector())
        .selectAll(".canton")
        .data(this.features.cantons)
        .on("mouseenter.t", d => {
          let c = this.features.cantons.find(c => c.id === d.canton);
          let score = c.properties.data.score;
          tooltip.select(".tooltip-title").text(c.properties.name);

          // show popup depending on item type
          if (this.chartdata.options.type === "indicator") {
            tooltip.select(".tooltip-score").text(score);
            tooltip.select(".tooltip-unit").text(c.properties.data.unit);
          } else if (this.chartdata.options.type === "policy_measure") {
            let value = score === 1 || score === 100 ? "Ja" : "Nein";
            tooltip.select(".tooltip-score").text(value);
          }
        })
        .on("mousemove.t", () => {
          let mouse = d3.mouse(this.svg.node()).map(d => parseInt(d));
          tooltip
            .style("left", mouse[0] + 15 + "px")
            .style("top", mouse[1] + 30 + "px")
            .style("opacity", 0.9);
        })
        .on("mouseout.t", () => tooltip.style("opacity", 0))
        .on("mouseenter.e", c => this.$eventHub.$emit("canton-mouse-enter", c.canton))
        .on("mouseout.e", c => this.$eventHub.$emit("canton-mouse-out", c.canton));
    },
    updateMap: function(data) {
      let _this = this;
      let mapLegendData = [];
      let featuresCantons = this.features.cantons;

      // first create a color scale for the score set
      this.colorScale = this.createColorScale(data.datasets.map(c => c.data[0]));

      // then we mount the score together with the color
      featuresCantons.forEach(canton => {
        let dataset = data.datasets.find(ds => canton.id === ds.id);
        canton.canton = canton.id; // used for consistent hover events

        canton.properties.data = {
          score: dataset.data[0],
          color: _this.colorScale(dataset.data[0]),
          unit: data.meta.unit,
          year: data.meta.year
        };
        mapLegendData.push(canton.properties.data);
      });
      this.mapLegendData = mapLegendData;

      // update cantons with primary score
      this.svg
        .select("." + this.featureSelector())
        .selectAll(".canton")
        .transition()
        .duration(1000)
        .style("fill", d => d.properties.data.color);
    },
    resizeMap: function() {
      this.createProjection(this.innerWidth, this.innerHeight);
    },
    cantonMouseEnter: function(cantonId) {
      this.svg
        .select("." + this.featureSelector())
        .selectAll(`.${cantonId}`)
        .transition()
        .duration(300)
        .style("fill", this._styles.chartHoverColor);
    },
    cantonMouseOut: function(cantonId) {
      this.svg
        .select(`#${this.cantonSelector(cantonId)}`)
        .transition()
        .duration(300)
        .style("fill", d => d.properties.data.color);
    }
  }
};
</script>

<style scoped>
#map {
  position: relative;
  margin: 0 auto;
  padding: 20px;
}
#map path {
  stroke: #444 !important;
  stroke-width: 0.5 !important;
  stroke-opacity: 0.5 !important;
}
.feature-canton .canton {
  fill: #bbb;
}
.feature-canton .canton-boundaries {
  stroke-width: 1px;
  stroke: #fbfbfb;
  fill: none;
  z-index: 1;
  stroke-opacity: 0.6;
}

.feature-lake .lake {
  fill: steelblue;
  z-index: 15;
}

.secondary-score {
  stroke-width: 1px;
  stroke: #fff;
  border: 1px solid white;
}

div.tooltip {
  position: absolute;
  text-align: left;
  padding: 4px;
  font: 12px sans-serif;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 8px;
  pointer-events: none;
}
div.tooltip .score {
  font-weight: 600;
}
</style>
