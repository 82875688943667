<template>
  <icon-base v-bind="$props" viewport="0 0 32 24">
    <path
      class="st0"
      d="M28.51,9.21c-0.08-0.26-12.44-9.18-12.65-9.18c-0.22,0-12.56,8.9-12.65,9.18C3.11,9.5,8.03,24,8.03,24
	l15.63,0.03C23.65,24.02,28.6,9.5,28.51,9.21z M22.51,21.83l-0.93-1.23c0.1-0.19,0.15-0.41,0.15-0.64c0-0.17-0.03-0.32-0.08-0.47
	l1.61-7.12c0.5-0.22,0.85-0.71,0.85-1.29c0,0,0,0,0-0.01l2.1-0.66L22.51,21.83z M5.55,10.44l1.27,0.4c0,0.01,0,0.03,0,0.04
	c0,0.73,0.56,1.34,1.28,1.4l3.01,4.95c-0.2,0.24-0.32,0.55-0.32,0.89c0,0.37,0.14,0.7,0.37,0.96l-2,2.81l-2.02-6.4L5.55,10.44z
	 M21.3,11.12l-5.1,1.6V7.97c0.31-0.11,0.57-0.32,0.74-0.59l4.57,2.96c-0.13,0.21-0.21,0.47-0.21,0.74
	C21.29,11.09,21.29,11.1,21.3,11.12z M21.54,11.87c0.2,0.3,0.52,0.51,0.88,0.58l-1.42,6.27c-0.2-0.11-0.44-0.18-0.69-0.18
	c-0.07,0-0.14,0.01-0.21,0.02l-3.67-5.09L21.54,11.87z M19.39,18.91c-0.19,0.17-0.34,0.4-0.41,0.66l-5.37-1.35c0-0.03,0-0.07,0-0.1
	c0-0.29-0.09-0.55-0.23-0.77l2.43-3.4L19.39,18.91z M15.4,12.72L9.63,10.9c0-0.01,0-0.01,0-0.02c0-0.28-0.08-0.55-0.23-0.77
	l5.1-2.79c0.19,0.34,0.51,0.59,0.89,0.68V12.72z M15.16,13.47l-2.4,3.36c-0.17-0.08-0.37-0.12-0.57-0.12c-0.15,0-0.3,0.02-0.43,0.07
	l-2.84-4.67C9.11,12,9.28,11.84,9.4,11.66L15.16,13.47z M11.84,19.49c0.11,0.03,0.23,0.04,0.35,0.04c0.46,0,0.87-0.22,1.12-0.56
	l5.67,1.42c0.18,0.57,0.71,0.98,1.34,0.98c0.25,0,0.49-0.07,0.7-0.19l0.76,1.06l-11.9-0.02L11.84,19.49z M19.1,4.56l6.93,5.07
	l-2.14,0.67c-0.25-0.39-0.69-0.64-1.18-0.64c-0.21,0-0.4,0.04-0.58,0.13l-4.98-3.22c-0.03-0.59-0.42-1.08-0.95-1.26V2.42L19.1,4.56z
	 M15.4,5.27c-0.58,0.14-1.02,0.64-1.07,1.25L8.74,9.57c-0.16-0.06-0.34-0.1-0.52-0.1c-0.48,0-0.91,0.24-1.16,0.62l-1.5-0.47
	l4.78-3.4l5.06-3.79V5.27z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconSpiderChart",
  mixins: [iconMixin]
};
</script>
