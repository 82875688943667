<template>
  <icon-base v-bind="$props" viewport="0 0 18 18">
    <path
      class="st0"
      d="M0,0h8.05v7.98H0V0L0,0z M9.95,0v7.98H18V0H9.95L9.95,0z M9.95,10.02V18H18v-7.98H9.95L9.95,10.02z M0,10.02V18
	h8.05v-7.98H0L0,10.02z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconViewMultiple",
  mixins: [iconMixin]
};
</script>
