<template>
  <!-- Main template includes all the different parts of the view -->
  <div class="h-100 w-100" :class="{ 'nav-open': $sidebar.showSidebar }">
    <div class="content-area w-100">
      <div class="row h-100 position-relative m-0">

        <!-- Left bar (graphic types/sources/tables) - gets hidden and displayed in configurator when certain display zoom is reached -->
        <div v-show="showConfig" :class="{ 'configurator-nav col-12 pl-0 col-lg-1 d-none d-lg-block': showConfig }">
          <nav class="configurator-nav position-relative overflow-hidden clearfix">
            <configurator-link to="/configurator/bar">
              <icon-bar-chart width="32" height="24" :class="{ active: isActive('bar') }"></icon-bar-chart>
            </configurator-link>

            <configurator-link :id="this.$sidebar.showSidebar ? '' : 'step-7'" to="/configurator/map">
              <icon-map width="32" height="24" :class="{ active: isActive('map') }"></icon-map>
            </configurator-link>

            <configurator-link to="/configurator/trend">
              <icon-trend-chart width="32" height="24" :class="{ active: isActive('trend') }"></icon-trend-chart>
            </configurator-link>

            <configurator-link to="/configurator/spider">
              <icon-spider-chart width="32" height="24" :class="{ active: isActive('spider') }"></icon-spider-chart>
            </configurator-link>

            <div class="meta-tabs w-100">
              <configurator-link :id="$sidebar.showSidebar ? '' : 'step-8'" to="/configurator/sources">
                <icon-source width="42" height="30" :class="{ active: isActive('sources') }"></icon-source>
              </configurator-link>

              <configurator-link :id="$sidebar.showSidebar ? '' : 'step-9'" to="/configurator/raw">
                <icon-table width="42" height="30" :class="{ active: isActive('raw') }"></icon-table>
              </configurator-link>
            </div>
            <button
              class="d-lg-none float-right m-0 configurator-link btn btn-outline-primary px-0 text-center w-100"
              @click="toggleConfigSidebar()"
            >
              <icon-config width="32" height="24"></icon-config>
            </button>
          </nav>
        </div>

        <!-- Content/Diagram view (middle) -->
        <div
          :class="[
            'configurator-content-area',
            {
              'col-12 col-lg-8': showConfig
            },
            { 'col-lg-12': !showConfig }
          ]"
        >
          <flash-message class="mb-4"></flash-message>
          <router-view :config="configContent" :cantons="cantonSet" :scores="scores"></router-view>
        </div>

        <!-- Configurator (right side) -->
        <div
          v-show="showConfig"
          :class="{
            'configurator-config-area pr-0 col-lg-3 h-100': showConfig
          }"
        >
          <configurator-config v-model="config" :sector-set="sectorSet" :canton-set="cantonSet"></configurator-config>
        </div>
      </div>
    </div>

    <!-- Footer - canton selection -->
    <transition>
      <configurator-canton-footer
        v-show="isFooterActive && showConfig"
        v-model="cantonSet"
        class="configurator-footer"
        mode="select"
        :config="configContent"
      ></configurator-canton-footer>
    </transition>
  </div>
</template>

<script>
import SectorSrv from "../../services/SectorSrv";
import ScoreSrv from "../../services/ScoreSrv";
import ConfiguratorConfig from "./ConfiguratorConfig";
import ConfiguratorLink from "./partials/ConfiguratorLink";
import ConfiguratorCantonFooter from "./ConfiguratorFooter";
import cloneDeep from "lodash/cloneDeep";
import Icons from "../Icons/graphicTypes";

export default {
  name: "ConfiguratorMain",
  components: {
    ConfiguratorCantonFooter,
    ConfiguratorLink,
    ConfiguratorConfig,
    ...Icons
  },
  data() {
    return {
      sectorSet: [], // loaded on start, contains all sectors and indicators
      cantonSet: [], // holds a list of all cantons with selected and highlighted state..
      scoreSet: {}, // holds the scores for the currently selection (for all years and units)
      config: {}, // contains the config from `configuratorConfig`
      configContent: {}, // copy used to prevent double firing of computed properties
      isSidebarActive: false,
      configSidebar: false,
      showConfig: true
    };
  },
  computed: {
    isFooterActive() {
      return this.config.useCantonRestriction;
    },
    scores() {
      return Object.values(this.scoreSet).flat();
    }
  },
  watch: {
    /**
     * This watcher is fired on config changes. Because of the async score loading we wait
     * with passing the config to the children until the scores are loaded. Otherwise children
     * watchers and computed properties are fired multiple times.
     * This watcher checks for both add and removal of indicators and calls one or the other
     * corresponding function.
     */
    config: {
      handler(config) {
        let conf = cloneDeep(config);
        this.loadScores(conf.selection).then(scoreSet => {
          this.configContent = this.config;
          this.scoreSet = scoreSet;
        });
      },
      deep: true
    }
  },
  mounted() {
    SectorSrv.getSectors().then(sectors => {
      this.sectorSet = sectors;
    });
    this.checkQuery();
  },
  beforeDestroy() {
    this.$eventHub.$off("canton-mouse-enter");
    this.$eventHub.$off("canton-mouse-out");
  },
  methods: {
    checkQuery() {
      if (this.$route.query.disableConfig === "true") {
        this.showConfig = false;
      } else {
        this.showConfig = true;
      }
    },
    itemSelected(index) {
      // console.log(index);
    },
    isActive(icon) {
      return icon === this.$route.name;
    },
    toggleConfigSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    },
    loadScores(indicators) {
      let scoreSet = Object.assign({}, this.scoreSet);
      let promises = [];
      indicators.forEach(indicator => {
        scoreSet[indicator._id] = [];
        promises.push(
          ScoreSrv.getScoresByIndicator(indicator.indicator.id).then(
            scores => (scoreSet[indicator._id] = scores.filter(s => s.unit === indicator.meta.unit))
          )
        );
      });
      return Promise.all(promises).then(() => {
        return scoreSet;
      });
    }
  }
};
</script>

<style>
/* Make scrollbars always visible */
.ps__rail-x,
.ps__rail-y {
  opacity: 0.2 !important;
}
@media (max-width: 960px) {
  .container-fluid {
    padding: 0 !important;
  }
}
</style>

<style scoped>
.configurator-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  font-size: 1.5vh;
}

.content-area {
  padding-bottom: 25px;
}
.smooth-enter-active,
.smooth-leave-active {
  transition: height 0.5s;
  overflow: hidden;
}
.smooth-enter,
.smooth-leave-to {
  height: 0;
}
.smooth-enter-to,
.smooth-leave {
  height: 100px;
}

/* CSS for whole view which contains the diagrams / sources / tables / posts*/
@media screen and (min-width: 1024px) {
  .content-area {
    height: calc(100% - 50px);
  }
  .configurator-footer {
    height: 50px;
  }
}
@media screen and (max-width: 960px) {
  .content-area {
    height: calc(100% - 60px);
  }
  .configurator-footer {
    height: 60px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .content-area {
    height: calc(100% - 50px);
  }
  .configurator-footer {
    height: 50px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .content-area {
    height: calc(100% - 85px);
  }
  .configurator-footer {
    height: 85px;
  }
}
@media screen and (max-device-width: 667px) and (orientation: landscape) {
  .content-area {
    height: calc(100% - 50px);
  }
  .configurator-footer {
    height: 50px;
  }
}
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .content-area {
    height: calc(100% - 95px);
  }
  .configurator-footer {
    height: 95px;
  }
}

/* css for navigation bar / configurator / view only for the diagrams */
@media (max-width: 400px) {
  .configurator-config-area {
    transform: translate3d(412px, 0, 0) !important;
    width: calc(100% - 15px);
  }

  .nav-open .configurator-config-area {
    transform: translate3d(0px, 0, 0) !important;
  }
}
@media (max-width: 960px) and (min-width: 400px) {
  .configurator-config-area {
    transform: translate3d(412px, 0, 0) !important;
    width: 400px;
  }

  .nav-open .configurator-config-area {
    transform: translate3d(0, 0, 0) !important;
  }
}

@media (max-width: 960px) {
  .configurator-nav {
    height: 70px;
    width: 100%;
  }
  .configurator-nav .meta-tabs {
    display: inline;
    margin: 0 !important;
  }
  .configurator-content-area {
    height: 100% !important;
    transform: translate3d(0px, 0, 0) !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .configurator-config-area {
    right: 0 !important;
    position: absolute;
    padding-right: 0 !important;
    padding-left: 0 !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .nav-open .configurator-config-area {
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .nav-open .configurator-content-area {
    transform: translate3d(-415px, 0, 0) !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}

@media screen and (min-width: 960px) {
  .content-area .configurator-content-area,
  .content-area .configurator-config-area {
    height: 100%;
  }
  .configurator-nav {
    height: 100%;
  }
  .meta-tabs {
    position: absolute;
    bottom: 0;
    margin: 0 !important;
  }
}
</style>

<style>
.ps__rail-x {
  display: none !important;
}
.full-width {
    width: 5%;
  }
</style>
