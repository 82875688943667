<template>
  <div class="wrapper">
    <side-bar title="CEIS Backend">
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/admin/csvImport">
        <i class="nc-icon nc-cloud-upload-94"></i>
        <p style="color: white">CSV Import</p>
      </sidebar-link>
      <sidebar-link to="/admin/sectors">
        <i class="nc-icon nc-grid-45"></i>
        <p style="color: white">Daten</p>
      </sidebar-link>
      <sidebar-link to="/admin/posts">
        <i class="nc-icon nc-paper-2"></i>
        <p style="color: white">Change-Feed</p>
      </sidebar-link>

      <template slot="bottom-links">
        <li>
          <span class="singdin-as ml-3"
            ><small class="font-weight-light"
              >Eingeloggt als {{ user }}</small
            ></span
          >
          <a class="btn nav-link bg-secondary" @click="handleLogout">
            <i class="nc-icon nc-single-02"></i>
            <p>Abmelden</p>
          </a>
        </li>
      </template>
    </side-bar>

    <div class="main-panel">
      <ceis-content @click="toggleSidebar"> </ceis-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import ContentFooter from "./Partials/ContentFooter.vue";
import CeisContent from "./Partials/ContentMain.vue";
import MobileMenu from "./Partials/MobileMenu.vue";

export default {
  components: {
    ContentFooter,
    CeisContent,
    MobileMenu
  },
  computed: {
    user() {
      return this.$store.state.auth.user
        ? this.$store.state.auth.user.username
        : "";
    }
  },
  mounted() {
    this.$store.dispatch("auth/tryToAuthenticate");
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    handleLogout: function(e) {
      e.preventDefault();
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/configurator");
      });
    }
  }
};
</script>
