// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import Vuex from "vuex";
import vClickOutside from "v-click-outside";
import VueTour from "vue-tour";

// import plugin files used to define globals
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SidebarPlugin from "./layout/Partials/Sidebar";
import i18n from "./services/i18nSrv";
import VueFlashMessage from "vue-flash-message";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import ToggleButton from "vue-js-toggle-button";

// Asset import
import { BootstrapVue } from "bootstrap-vue";
import "./assets/sass/clean-energy-index.scss";
import "vue-tour/dist/vue-tour.css";

// Globally toggle, scrollbar and flash message
Vue.use(ToggleButton);
Vue.use(PerfectScrollbar);
Vue.use(VueFlashMessage);

// Import our Vuex store
import store from "./store";

Vue.config.productionTip = false;

// Install BootstrapVue
Vue.use(BootstrapVue);

// Define globals
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(SidebarPlugin);

// we are using vuex for auth
Vue.use(Vuex);

Vue.use(vClickOutside);

Vue.use(VueTour);

// define global event bus
Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
