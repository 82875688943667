import CONST from "../consts";
import Chartdata from "../../models/chartdataModel";
import i18n from "../../services/i18nSrv";
import _ from "lodash";
import Charts from "../../components/Charts";

export default {
  // name of the chart
  name: CONST.CHARTS.TREND,

  // component name
  component: Charts.TrendChart.name,

  // the supported views
  supportedViews: ["single", "multiple", "combined"],

  // the supported views that require scaled data
  supportedViewsReqScaledData: ["combined"],

  // the maximum number of items in single view
  maxItemsSingle: null,

  // the maximum number of items in combined view
  maxItemsCombined: null,

  // the maximum number of cantons in single view
  maxCantonsSingle: [],

  // the maximum number of contains in combined view
  maxCantonsCombined: 26,

  // determines if the graphic type supports the selection of cantons
  isCantonSelectionSupported: true,

  // determines if the graphic type uses single year selection or year range
  isYearRange: true,

  // the aspect ration between width and height (width*aspect-ration=height)
  aspectRatio: 0.6,

  //
  chartOptions(item, useScores) {
    return {
      range: useScores ? [0, 100] : null,
      yLabel: useScores ? i18n.t("score") : item.meta.unit,
      xLabel: i18n.t("canton")
    };
  },

  // create a chart title for single view
  chartTitleSingle(item, year_from, year_to) {
    return `${[item.indicator.name]} in ${item.meta.unit} (${year_from} - ${year_to})`;
  },

  // returns the chart title vor combined view
  chartTitleCombined(selection, from_year, to_year) {
    let titles = selection.map(i => {
      return i18n.t("chart.title.with_unit", {
        label: i.indicator.name,
        unit: i.meta.unit
      });
    });
    if (titles.length === 1) return titles[0];
    const firsts = titles.slice(0, titles.length - 1);
    const last = titles[titles.length - 1];
    const title = firsts.join(", ") + " " + i18n.t("and") + " " + last;
    const year = i18n.t("in_year_range", { year_from: from_year, year_to: to_year });
    return title + " " + year;
  },

  // computes chartdata used to display trend chart in multiple view
  chartdataMultiple(selection, cantons, scores, year, useScores) {
    if (!year) {
      return [];
    }
    const year_from = year[0];
    const year_to = year[1];
    const range = _.range(year_from, year_to + 1);
    const g_scores = _.groupBy(scores, "indicatorId");

    return selection.map(item => {
      const title = this.chartTitleSingle(item, year_from, year_to);
      const options = this.chartOptions(item, useScores);
      const meta = { ...item.meta, indicator: item.indicator.name };
      let chartdata = new Chartdata(range, item._id, title, options, meta);
      chartdata.setDefaultData(NaN);
      chartdata.createDatasetsFromCantons(cantons, [item.color], item);
      chartdata.setLabelKey("year");
      chartdata.applyScores(g_scores[item.indicator.id], useScores);
      this.isChartdataValid(chartdata, CONST.VIEWS.MULTIPLE);
      return chartdata;
    });
  },

  // computes chartdata used to display trend chart in multiple single
  chartdataSingle(index, selection, cantons, scores, year, useScores) {
    const item = selection[index];
    const year_from = year[0];
    const year_to = year[1];
    const range = _.range(year_from, year_to + 1);
    const title = this.chartTitleSingle(item, year_from, year_to);
    const options = this.chartOptions(item, useScores);
    const meta = { ...item.meta, indicator: item.indicator.name };

    // filter scores
    scores = scores.filter(s => s.indicatorId === item.indicator.id);

    // create chartdata
    let chartdata = new Chartdata(range, item._id, title, options, meta);
    chartdata.setDefaultData(NaN);
    chartdata.createDatasetsFromCantons(cantons, [item.color], item);
    chartdata.setLabelKey("year");
    chartdata.applyScores(scores, useScores);
    this.isChartdataValid(chartdata, CONST.VIEWS.SINGLE);
    return chartdata;
  },

  // computes chartdata used to display trend chart in multiple combined
  chartdataCombined(selection, cantons, scores, year, useScores) {
    const year_from = year[0];
    const year_to = year[1];
    const range = _.range(year_from, year_to + 1);
    const title = this.chartTitleCombined(selection, year_from, year_to);
    const options = this.chartOptions(selection, useScores);

    let chartdata = new Chartdata(range, "all", title, options);
    chartdata.setDefaultData(NaN);
    chartdata.createDatasetsFromCantonsAndIndicators(cantons, selection, []);
    chartdata.setLabelKey("year");
    chartdata.applyScores(scores);
    this.isChartdataValid(chartdata, CONST.VIEWS.COMBINED);
    return chartdata;
  },

  isChartdataValid(chartdata) {
    if (chartdata.datasets.length === 0) {
      chartdata.isValid = false;
      chartdata.errorMessage = i18n.t("chart.no_canton_selected");
    }
    if (chartdata.labels.length === 0) {
      chartdata.isValid = false;
      chartdata.errorMessage = i18n.t("chart.no_item_selected");
    }
  },

  //
  whatAmILookingAtSingle(chartdata, year, useScores) {
    return i18n.t("whatAmiLookingAt.trendChartSingle", {
      indicator: chartdata.meta.indicator,
      from_year: year[0],
      to_year: year[1],
      unit: chartdata.meta.unit,
      data_form: useScores
        ? i18n.t("whatAmiLookingAt.data_scores")
        : i18n.t("whatAmiLookingAt.data_raw")
    });
  },

  //
  whatAmILookingAtCombined(chartdata, year, useScores) {
    return i18n.t("whatAmiLookingAt.trendChartCombined", {
      title: chartdata.title, // we reuse title here..
      from_year: year[0],
      to_year: year[1],
      data_form: useScores
        ? i18n.t("whatAmiLookingAt.data_scores")
        : i18n.t("whatAmiLookingAt.data_raw")
    });
  }
};
