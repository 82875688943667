import CONST from "../consts";
import Chartdata from "../../models/chartdataModel";
import i18n from "../../services/i18nSrv";
import Charts from "../../components/Charts";

export default {
  // name of the chart
  name: CONST.CHARTS.BAR,

  // component name
  component: Charts.BarChart.name,

  // the supported views
  supportedViews: ["single", "multiple", "combined"],

  // the supported views that require scaled data
  supportedViewsReqScaledData: ["combined"],

  // the maximum number of items in single view
  maxItemsSingle: null,

  // the maximum number of items in combined view
  maxItemsCombined: null,

  // the maximum number of cantons in single view
  maxCantonsSingle: [],

  // the maximum number of contains in combined view
  maxCantonsCombined: 26,

  // determines if the graphic type supports the selection of cantons
  isCantonSelectionSupported: true,

  // determines if the graphic type uses single year selection or year range
  isYearRange: false,

  // the aspect ration between width and height (width*aspect-ration=height)
  aspectRatio: 0.6,

  // returns the options used to display a bar chart
  chartOptions(item, useScores) {
    return {
      range: useScores ? [0, 100] : null,
      yLabel: useScores ? i18n.t("score") : item.meta.unit,
      xLabel: i18n.t("canton")
    };
  },

  // returns the title used for single view
  chartTitleSingle(item, year) {
    let key = "chart.title.with_year";
    // check if indicator has year
    if (typeof item.indicator.years[year] !== "undefined") {
      // check if indicator has more than one unit
      if (item.indicator.years[year].length > 1) {
        key = "chart.title.complete";
      }
    }
    return i18n.t(key, {
      label: item.indicator.name,
      unit: item.meta.unit,
      year: year
    });
  },

  // returns the title for combined view
  chartTitleCombined(selection, year) {
    let titles = selection.map(i => {
      return i18n.t("chart.title.with_unit", {
        label: i.indicator.name,
        unit: i.meta.unit
      });
    });
    if (titles.length === 1) return titles[0];
    const firsts = titles.slice(0, titles.length - 1);
    const last = titles[titles.length - 1];
    const title = firsts.join(", ") + " " + i18n.t("and") + " " + last;
    return title + " " + i18n.t("in_year", { year: year });
  },

  // returns the chartdata used for view `combined`
  chartdataSingle(index, selection, cantons, scores, year, useScores = true) {
    const item = selection[index];
    const title = this.chartTitleSingle(item, year);
    const labels = [item.indicator.name];
    const options = this.chartOptions(item, useScores);
    // retrieve the relevant scores
    scores = scores.filter(s => s.year === year);
    // create chartdata
    let chartdata = new Chartdata(labels, item._id, title, options, item.meta);
    chartdata.createDatasetsFromCantons(cantons, [item.color], item);
    chartdata.applyScores(scores, useScores);
    this.isChartdataValid(chartdata, CONST.VIEWS.SINGLE);
    return chartdata;
  },

  // returns the chartdata used for view multiple
  chartdataMultiple(selection, cantons, scores, year, useScores = true) {
    scores = scores.filter(s => s.year === year);
    // create chartdata for each item
    return selection.map(item => {
      const title = this.chartTitleSingle(item, year);
      const labels = [item.indicator.name];
      const options = this.chartOptions(item, useScores);
      let chartdata = new Chartdata(labels, item._id, title, options, item.meta);
      chartdata.createDatasetsFromCantons(cantons, [item.color], item);
      chartdata.applyScores(scores, useScores);
      this.isChartdataValid(chartdata, CONST.VIEWS.MULTIPLE);
      return chartdata;
    });
  },

  chartdataCombined(selection, cantons, scores, year, useScores) {
    const title = this.chartTitleCombined(selection, year);
    const labels = selection.map(item => item.indicator.name);
    const colors = selection.map(item => item.color);
    const options = this.chartOptions(null, true);
    const meta = selection.map(i => i.meta);
    // filter scores
    scores = scores.filter(s => s.year === year);
    // create chartdata
    let chartdata = new Chartdata(labels, 1, title, options, meta);
    chartdata.createDatasetsFromCantons(cantons, colors);
    chartdata.applyScores(scores, useScores);
    this.isChartdataValid(chartdata, CONST.VIEWS.COMBINED);
    return chartdata;
  },

  // checks the chartdata for validity
  isChartdataValid(chartdata, view) {
    if (chartdata.datasets.some(ds => ds.data.includes(null))) {
      if (view !== CONST.VIEWS.COMBINED) {
        chartdata.isValid = false;
        chartdata.errorMessage = i18n.t("chart.no_data_for_this_year");
      }
    }
    if (chartdata.labels.length === 0) {
      chartdata.isValid = false;
      chartdata.errorMessage = i18n.t("chart.no_item_selected");
    }
    if (chartdata.datasets.length === 0) {
      chartdata.isValid = false;
      chartdata.errorMessage = i18n.t("chart.no_canton_selected");
    }
  },

  whatAmILookingAtSingle(chartdata, year, useScores) {
    let key =
      !chartdata.isScoreTopDown && useScores
        ? "whatAmiLookingAt.barChartSingleReversed"
        : "whatAmiLookingAt.barChartSingle";
    return i18n.t(key, {
      indicator: chartdata.labels[0],
      year: year,
      unit: chartdata.meta.unit,
      data_form: useScores
        ? i18n.t("whatAmiLookingAt.data_scores")
        : i18n.t("whatAmiLookingAt.data_raw")
    });
  },

  whatAmILookingAtCombined(chartdata, year, useScores) {
    return i18n.t("whatAmiLookingAt.barChartCombined", {
      title: chartdata.title, // we reuse title here..
      year: year,
      orderByIndicator: chartdata.labels[0],
      orderByUnit: chartdata.meta[0].unit,
      data_form: useScores
        ? i18n.t("whatAmiLookingAt.data_scores")
        : i18n.t("whatAmiLookingAt.data_raw")
    });
  }
};
