<template>
  <b-modal
    v-model="show"
    size="lg"
    hide-footer
    body-class="bg-light border-top border-light"
    header-class="pb-3"
    centered
  >
    <template v-slot:modal-header="{ close }">
      <div class="w-100 indicator-modal-header pb-3">
        <h2 class="title w-100 m-0 p-0">
          {{ $t("config.indicator.add") }}
        </h2>
      </div>
      <b-button class="close" variant="outline-light" @click="close()">
        x
      </b-button>
    </template>

    <template v-slot:default="{ close }">
      <div class="indicator-modal-body h-100">
        <div class="row">
          <div class="col-5 pb-4">
            <div id="step-3" class="h2 w-100">
              <a
                class="btn btn-outline-light m-2"
                :class="{ 'btn-outline-primary': activeSector === 0 }"
                @click="filter(0)"
              >
                {{ $t("all") }}
              </a>
              <a
                v-for="sector in sectorSet"
                :key="sector.id"
                class="btn btn-outline-light m-2"
                :class="{ 'btn-outline-primary': activeSector === sector.id }"
                @click="filter(sector.id)"
              >
                <component :is="sector.icon" :color="'#' + sector.color" :width="20" :height="20"></component>
                <span class="pl-2">{{ sector.name }}</span>
              </a>
            </div>
            <hr />
            <div id="step-4">
              <b-form-input v-model="search" size="sm" placeholder="Suche..."></b-form-input>
              <p></p>
              <b-form-checkbox v-model="filterIndicators" name="check-button" :disabled="filterMeasures" switch>
                Filtern nach Indikatoren
              </b-form-checkbox>
              <b-form-checkbox v-model="filterMeasures" name="check-button" :disabled="filterIndicators" switch>
                Filtern nach Politikmassnahmen
              </b-form-checkbox>
            </div>
          </div>
          <div id="step-2" class="col-7 pb-4">
            <perfect-scrollbar class="w-100 pr-4" style="max-height: 70vh">
              <ul class="list-group">
                <li
                  v-for="indicator in indicatorList"
                  :key="'indicator' + indicator.id"
                  class="list-group-item d-flex justify-content-between align-items-center"
                  @click="select(indicator)"
                >
                  <h5 class="m-0">
                    {{ indicator.name }}

                    <span class="ml-2 badge badge-white badge-sector px-2">
                      <component
                        :is="getSectorIcon(indicator.sectorId)"
                        :color="'#' + getSectorColor(indicator.sectorId)"
                        :width="12"
                        :height="12"
                      ></component>
                    </span>

                    <span class="ml-2 badge badge-white badge-indicator-type px-2">
                      {{ $t(indicator.type) }}
                    </span>
                  </h5>

                  <span
                    v-if="!isSelected(createId(indicator.sectorId, indicator.id))"
                    class="badge badge-light text-white"
                    >✓</span
                  >
                  <span
                    v-if="isSelected(createId(indicator.sectorId, indicator.id))"
                    class="badge badge-primary text-white"
                    >✓</span
                  >
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>

        <div class="sources py-3 overflow-hidden">
          <div class="w-50 float-left pr-2">
            <b-button class="w-100" variant="danger" @click="close">
              {{ $t("cancel") }}
            </b-button>
          </div>
          <div class="w-50 float-left pl-2">
            <b-button id="step-5" class="w-100" variant="primary" @click="addAndClose">
              {{ $t("apply") }}
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import SectorSrv from "../../../services/SectorSrv";
import {
  IconSectorMobility,
  IconSectorBuildings,
  IconSectorGases,
  IconSectorRenewableEnergy,
  IconSectorData,
} from "../../Icons/sectors";

export default {
  name: "ConfiguratorSelectionModal",
  components: {
    IconSectorMobility,
    IconSectorBuildings,
    IconSectorGases,
    IconSectorRenewableEnergy,
    IconSectorData,
  },
  props: {
    id: {
      type: String,
      default: "config-selection-modal",
    },
    sectorSet: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      events: {
        openModal: "open-configurator-selection-modal",
        closeModal: "closeSelectionModal",
      },
      show: false,
      selection: [],
      activeSector: 0,
      onSelected: null,
      search: "",
      filterIndicators: false,
      filterMeasures: false,
    };
  },
  computed: {
    indicatorList() {
      let indicators = [];
      this.sectorSet.map((sector) => {
        if (this.activeSector && this.activeSector === sector.id) {
          indicators = sector.indicatorSet;
        } else if (this.activeSector === 0) {
          indicators = indicators.concat(sector.indicatorSet);
        }
      });
      var sortedIndicators = indicators.sort((a, b) => {
        var indA = a.name.toUpperCase();
        var indB = b.name.toUpperCase();
        if (indA < indB) {
          return -1;
        } else if (indA > indB) {
          return 1;
        } else {
          return 0;
        }
      });
      return sortedIndicators.filter((e) => {
        if (this.filterIndicators) {
          return e.type === "indicator";
        } else if (this.filterMeasures) {
          return e.type === "policy_measure";
        } else {
          return e.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        }
      });
    },
  },
  mounted() {
    this.$eventHub.$on(this.events.openModal, (event) => this.open(event.selection, event.cb));
    this.$eventHub.$on(this.events.closeModal, () => {
      this.addAndClose();
    });
  },
  beforeDestroy() {
    this.$eventHub.$off(this.events.openModal);
    this.$eventHub.$off(this.events.closeModal);
  },
  methods: {
    getSectorColor(sectorId) {
      return SectorSrv.getSectorColor(sectorId);
    },
    getSectorIcon(sectorId) {
      return SectorSrv.getSectorIcon(sectorId);
    },
    open(selection, cb) {
      this.show = true;
      this.onSelected = cb;
      this.selection = selection;
    },
    filter(sectorId) {
      this.activeSector = sectorId;
    },
    createId(sectorId, indicatorId) {
      return parseInt("" + sectorId + indicatorId);
    },
    select(indicator) {
      let _id = this.createId(indicator.sectorId, indicator.id);
      if (this.isSelected(_id)) {
        this.selection = this.selection.filter((s) => s._id !== _id);
      } else {
        let years = Object.keys(indicator.years);
        this.selection.push({
          _id: _id,
          meta: {
            year: parseInt(years[years.length - 1]),
            unit: indicator.years[years[years.length - 1]][0],
          },
          indicator: indicator,
          color: "#" + SectorSrv.getSectorColor(indicator.sectorId),
        });
      }
    },
    isSelected(id) {
      return this.selection.map((s) => s._id).includes(id);
    },
    addAndClose() {
      if (this.onSelected) {
        this.onSelected(this.selection);
      }
      this.$eventHub.$emit("selectionModalClosed");
      this.show = false;
    },
  },
};
</script>

<style scoped>
.score-list {
  height: 200px;
}

.badge-light {
  background-color: #ddd;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item:hover .badge-light {
  background-color: #666;
}
</style>
