<template>
  <nav v-if="showConfig" class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand" href="/#/pages/home">
        <img src="../../../public/logo_ceis.svg" alt="CEIS" height="30" />
      </a>

      <div class="d-flex align-items-center ml-auto">
        <div id="step-10">
          <button class="btn btn-sm btn-info" @click="openChangeFeed">
            <i class="fa fa-newspaper-o"></i>
          </button>
        </div>

        <div id="step-0" class="icon mx-3">
          <i class="fa fa-question-circle" @click="startHelpTour"></i>
        </div>

        <button
          v-if="navCollapsed"
          id="sideBarBtn"
          type="button"
          class="btn btn-outline-light ml-5"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <icon-config
            width="32"
            height="24"
            style="color: white"
          ></icon-config>
        </button>
      </div>
    </div>
  </nav>
</template>
<script>
import Icons from "../../components/Icons/graphicTypes";

export default {
  components: {
    ...Icons
  },
  data() {
    return {
      showConfig: true,
      navCollapsed: false
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user
        ? this.$store.state.auth.user.username
        : "-";
    }
  },
  mounted() {
    this.checkNavbarState();
    window.addEventListener("resize", this.checkNavbarState);
  },
  created() {
    this.checkQuery();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkNavbarCollapse);
  },
  methods: {
    checkNavbarState() {
      this.navCollapsed = window.innerWidth < 992;
    },
    checkQuery() {
      if (this.$route.query.disableConfig === "true") {
        this.showConfig = false;
      } else {
        this.showConfig = true;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      this.$eventHub.$emit("sideBarBtnPressed");
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    handleLogout: function(e) {
      e.preventDefault();
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login");
      });
    },
    copyToClipboard: function() {
      navigator.clipboard.writeText("boog@zhaw.ch");
    },
    openChangeFeed() {
      this.$router.push("/pages/posts");
    },
    startHelpTour() {
      this.$tours["helpTour"].start();
    }
  }
};
</script>

<style scoped>
.icon {
  cursor: pointer;
  font-size: 2.5em;
  color: #0064a6;
}

.pointer {
  cursor: pointer;
}
</style>
