<template>
  <ul class="nav nav-mobile-menu">
    <base-dropdown title="Dropdown">
      <a class="dropdown-item" href="#">Action</a>
      <a class="dropdown-item" href="#">Another action</a>
      <a class="dropdown-item" href="#">Something</a>
      <a class="dropdown-item" href="#">Something else here</a>
      <div class="divider"></div>
      <a class="dropdown-item" href="#">Separated link</a>
    </base-dropdown>
  </ul>
</template>
<script>
export default {
  name: "MobileMenu"
};
</script>
<style></style>
