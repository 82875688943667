var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 w-100",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('div',{staticClass:"content-area w-100"},[_c('div',{staticClass:"row h-100 position-relative m-0"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfig),expression:"showConfig"}],class:{ 'configurator-nav col-12 pl-0 col-lg-1 d-none d-lg-block': _vm.showConfig }},[_c('nav',{staticClass:"configurator-nav position-relative overflow-hidden clearfix"},[_c('configurator-link',{attrs:{"to":"/configurator/bar"}},[_c('icon-bar-chart',{class:{ active: _vm.isActive('bar') },attrs:{"width":"32","height":"24"}})],1),_c('configurator-link',{attrs:{"id":this.$sidebar.showSidebar ? '' : 'step-7',"to":"/configurator/map"}},[_c('icon-map',{class:{ active: _vm.isActive('map') },attrs:{"width":"32","height":"24"}})],1),_c('configurator-link',{attrs:{"to":"/configurator/trend"}},[_c('icon-trend-chart',{class:{ active: _vm.isActive('trend') },attrs:{"width":"32","height":"24"}})],1),_c('configurator-link',{attrs:{"to":"/configurator/spider"}},[_c('icon-spider-chart',{class:{ active: _vm.isActive('spider') },attrs:{"width":"32","height":"24"}})],1),_c('div',{staticClass:"meta-tabs w-100"},[_c('configurator-link',{attrs:{"id":_vm.$sidebar.showSidebar ? '' : 'step-8',"to":"/configurator/sources"}},[_c('icon-source',{class:{ active: _vm.isActive('sources') },attrs:{"width":"42","height":"30"}})],1),_c('configurator-link',{attrs:{"id":_vm.$sidebar.showSidebar ? '' : 'step-9',"to":"/configurator/raw"}},[_c('icon-table',{class:{ active: _vm.isActive('raw') },attrs:{"width":"42","height":"30"}})],1)],1),_c('button',{staticClass:"d-lg-none float-right m-0 configurator-link btn btn-outline-primary px-0 text-center w-100",on:{"click":function($event){return _vm.toggleConfigSidebar()}}},[_c('icon-config',{attrs:{"width":"32","height":"24"}})],1)],1)]),_c('div',{class:[
          'configurator-content-area',
          {
            'col-12 col-lg-8': _vm.showConfig
          },
          { 'col-lg-12': !_vm.showConfig }
        ]},[_c('flash-message',{staticClass:"mb-4"}),_c('router-view',{attrs:{"config":_vm.configContent,"cantons":_vm.cantonSet,"scores":_vm.scores}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfig),expression:"showConfig"}],class:{
          'configurator-config-area pr-0 col-lg-3 h-100': _vm.showConfig
        }},[_c('configurator-config',{attrs:{"sector-set":_vm.sectorSet,"canton-set":_vm.cantonSet},model:{value:(_vm.config),callback:function ($$v) {_vm.config=$$v},expression:"config"}})],1)])]),_c('transition',[_c('configurator-canton-footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFooterActive && _vm.showConfig),expression:"isFooterActive && showConfig"}],staticClass:"configurator-footer",attrs:{"mode":"select","config":_vm.configContent},model:{value:(_vm.cantonSet),callback:function ($$v) {_vm.cantonSet=$$v},expression:"cantonSet"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }