import CONST from "../consts";
import Chartdata from "../../models/chartdataModel";
import i18n from "../../services/i18nSrv";
import _ from "lodash";
import Charts from "../../components/Charts";

export default {
  // name of the chart
  name: CONST.CHARTS.SPIDER,

  // component name
  component: Charts.SpiderChart.name,

  // the supported views
  supportedViews: ["multiple", "single"], // todo allow combined

  // the supported views that require scaled data
  supportedViewsReqScaledData: ["multiple", "single", "combined"],

  // the maximum number of items in single view
  maxItemsSingle: null,

  // the maximum number of items in combined view
  maxItemsCombined: null,

  // the maximum number of cantons in single view
  maxCantonsSingle: [],

  // the maximum number of contains in combined view
  maxCantonsCombined: 26,

  // determines if the graphic type supports the selection of cantons
  isCantonSelectionSupported: true,

  // determines if the graphic type uses single year selection or year range
  isYearRange: false,

  // Define the charts max width
  maxWidth: 750,

  // the aspect ration between width and height (width*aspect-ration=height)
  aspectRatio: 1,

  //
  singleClickOnCantonAction: "open", // vs select/deselect

  // returns a combined chart title
  chartTitleCombined(selection, year) {
    let titles = selection.map(i => {
      return i18n.t("chart.title.with_unit", {
        label: i.indicator.name,
        unit: i.meta.unit
      });
    });
    if (titles.length === 1) return titles[0];
    const firsts = titles.slice(0, titles.length - 1);
    const last = titles[titles.length - 1];
    const title = firsts.join(", ") + " " + i18n.t("and") + " " + last;
    return title + " " + i18n.t("in_year", { year: year });
  },

  // returns the chartdata used to display multiple spider charts
  chartdataMultiple(selection, cantons, scores, year, useScores = false) {
    scores = scores.filter(s => s.year === year);
    const g_scores = _.groupBy(scores, "canton");
    const labels = selection.map(i => i.indicator.name);

    return cantons.map(canton => {
      const title = canton.properties.name + ": " + this.chartTitleCombined(selection, year);
      let chartdata = new Chartdata(labels, canton.id, title);
      chartdata.createDatasetsFromCantons([canton], "#3D85C6");
      chartdata.applyScores(g_scores[canton.canton], useScores);
      this.isChartdataValid(chartdata);
      return chartdata;
    });
  },

  // returns teh chartdata used to display a single spider chart by (canton-)index
  chartdataSingle(index, selection, cantons, scores, year, useScores = true) {
    // get selected canton
    const canton = typeof cantons[index] === "undefined" ? cantons[0] : cantons[index];
    const labels = selection.map(i => i.indicator.name);
    const title = canton.properties.name + ": " + this.chartTitleCombined(selection, year);

    // filter scores
    scores = scores.filter(s => s.year === year);

    // create chartdata
    let chartdata = new Chartdata(labels, "spider", title);
    chartdata.createDatasetsFromCantons([canton], "#3D85C6");
    chartdata.applyScores(scores, useScores);
    chartdata.datasets[0].id = "spidersingle"; // this ensures transitions in single view
    this.isChartdataValid(chartdata);
    return chartdata;
  },

  // returns the chartdata used to display a combined spider chart
  chartdataCombined(selection, cantons, scores, year, useScores = true) {
    const labels = selection.map(i => i.indicator.name);
    const title = this.chartTitleCombined(selection, year);
    // filter scores
    scores = scores.filter(s => s.year === year);

    // create chartdata
    let chartdata = new Chartdata(labels, "all", title);
    chartdata.createDatasetsFromCantons(cantons, "#3D85C6");
    chartdata.applyScores(scores, useScores);
    this.isChartdataValid(chartdata);
    return chartdata;
  },

  isChartdataValid(chartdata) {
    if (chartdata.length === 0) {
      chartdata.isValid = false;
      chartdata.errorMessage = i18n.t("chart.no_canton_selected");
    }
    if (chartdata.labels.length <= 2) {
      chartdata.isValid = false;
      chartdata.errorMessage = i18n.t("chart.min_select_indicators", { n: 3 });
    }
  },

  // returns the "what am i looking"-text for single chart
  whatAmILookingAtSingle(chartdata, year, useScores) {
    return i18n.t("whatAmiLookingAt.spiderChartSingle", {
      title: chartdata.title, // we reuse title here..
      canton: chartdata.datasets[0].properties.cantonName,
      year: year,
      data_form: useScores
        ? i18n.t("whatAmiLookingAt.data_scores")
        : i18n.t("whatAmiLookingAt.data_raw")
    });
  },

  // returns the "what am i looking"-text for combined chart
  whatAmILookingAtCombined(chartdata, year, useScores) {
    return i18n.t("whatAmiLookingAt.spiderChartCombined", {
      title: chartdata.title, // we reuse title here..
      year: year,
      data_form: useScores
        ? i18n.t("whatAmiLookingAt.data_scores")
        : i18n.t("whatAmiLookingAt.data_raw")
    });
  }
};
