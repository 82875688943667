export default {
  computed: {
    chartType() {
      return this.$route.name;
    },
    isChartTrend() {
      return this.chartType === "trend";
    },
    isChartBar() {
      return this.chartType === "bar";
    },
    isChartMap() {
      return this.chartType === "map";
    },
    isChartSpider() {
      return this.chartType === "spider";
    }
  }
};
