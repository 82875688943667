<template>
  <div class="configurator-content w-100 h-100">
    <perfect-scrollbar :options="{ suppressScrollX: true }" class="h-100">
      <div ref="confcontent" class="row h-100" style="margin-right: 15px">
        <div v-if="!showCharts" class="col-12">
          <configurator-content-welcome></configurator-content-welcome>
        </div>

        <div v-if="isViewSingle && showCharts" class="col-12">
          <h4 class="chart-title text-center mb-2 text-secondary">
            {{ chartdataSingle.title }}
          </h4>
          <div v-if="chartdataSingle.isValid">
            <component
              :is="chartComponent"
              :chartdata="chartdataSingle"
              :height="chart.height"
              :width="chart.width"
              :styles="chart.styles"
              :selector="'chart_single_' + chart.type"
              :animations="displayAnimations"
            >
            </component>
            <div v-if="isWhatAmILookingAtActive" class="m-2 text-center">
              <small style="line-height: 1">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="$t(whatAmILookingAt(chartdataSingle))"></span>
              </small>
            </div>
          </div>
          <div v-else class="chart-data-missing text-muted" :style="'height: ' + chart.height + 'px'">
            {{ chartdataSingle.errorMessage }}
          </div>
        </div>

        <div v-if="isViewCombined && showCharts" class="col-12">
          <h4 class="chart-title text-center mb-2 text-secondary">
            {{ chartdataCombined.title }}
          </h4>
          <div v-if="chartdataCombined.isValid">
            <component
              :is="chartComponent"
              :chartdata="chartdataCombined"
              :height="chart.height"
              :width="chart.width"
              :styles="chart.styles"
              :selector="'chart_combined_' + chart.type"
              :animations="displayAnimations"
            >
            </component>
            <div v-if="isWhatAmILookingAtActive" class="m-2 mb-4 text-center">
              <small style="line-height: 1">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="$t(whatAmILookingAt(chartdataCombined))"></span>
              </small>
            </div>
          </div>
          <div v-else class="chart-data-missing text-muted" :style="'height: ' + chart.height + 'px'">
            {{ chartdataCombined.errorMessage }}
          </div>
        </div>

        <template v-if="isViewMultiple && showCharts">
          <div v-for="(chartdata, i) in chartdataMultiple" :key="'chart_' + i" :class="col">
            <h4 class="chart-title text-center mb-2 text-secondary">
              {{ chartdata.title }}
            </h4>

            <div v-if="chartdata.isValid">
              <component
                :is="chartComponent"
                :chartdata="chartdata"
                :height="chart.height"
                :width="chart.width"
                :styles="chart.styles"
                :selector="'chart_' + i"
                :animations="displayAnimations"
              >
              </component>
              <div v-if="isWhatAmILookingAtActive" class="m-2 mb-4 text-center">
                <small style="line-height: 1">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="$t(whatAmILookingAt(chartdata))"></span>
                </small>
              </div>
            </div>

            <div v-else :style="'height: ' + chart.height + 'px'" class="chart-data-missing text-muted">
              <p class="text-center">
                <icon-no-data-found width="40" height="40" color="#bbbbbb"></icon-no-data-found>
                <br />
                {{ chartdata.errorMessage }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import ViewTypesMixin from "../../../mixins/ViewTypesMixin";
import ChartTypesMixin from "../../../mixins/ChartTypesMixin";
import IconNoDataFound from "../../Icons/IconNoDataFound";
import Charts from "../../Charts";
import getChart from "../../../config/charts";
import ConfiguratorContentWelcome from "@/components/Configurator/contents/ConfiguratorContentWelcome";

export default {
  name: "ConfiguratorContent",
  components: {
    ConfiguratorContentWelcome,
    IconNoDataFound,
    ...Charts
  },
  mixins: [ViewTypesMixin, ChartTypesMixin],
  props: {
    config: {
      type: Object,
      required: true
    },
    cantons: {
      type: Array,
      required: true,
      default: () => []
    },
    scores: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      chart: {
        height: 300,
        width: 500,
        show: false,
        styles: {
          margin: { top: 0, right: 0, bot: 0, left: 0 }
        }
      }
    };
  },
  computed: {
    // computes the current chart depending on the route name
    chart_config() {
      return getChart(this.chartType);
    },

    // computes current chart component
    chartComponent() {
      return this.chart_config.component;
    },

    // computes the data used to call the chart type related chartdata methods
    chartdataParams() {
      return [this.selection, this.cantons, this.scores, this.year, this.displayScores];
    },

    // computes the chart data used for single view depending on chart type
    chartdataSingle() {
      if (this.isViewSingle) {
        return this.chart_config.chartdataSingle(this.config.selectedItem || 0, ...this.chartdataParams);
      }
      return null;
    },

    // computes the chart data used for combined view depending on chart type
    chartdataCombined() {
      if (this.isViewCombined) {
        return this.chart_config.chartdataCombined(...this.chartdataParams);
      }
      return null;
    },

    // computes the chart data used for multiple view depending on chart type
    chartdataMultiple() {
      if (this.isViewMultiple) {
        return this.chart_config.chartdataMultiple(...this.chartdataParams);
      }
      return null;
    },

    // config related computed
    selection() {
      return typeof this.config.selection !== "undefined" ? this.config.selection : [];
    },
    selectedItem() {
      return this.selection[this.config.selectedItem];
    },
    view() {
      return this.config.view;
    },
    year() {
      return this.config.year;
    },
    displayAnimations() {
      return this.config.useAnimations;
    },
    displayScores() {
      return this.config.useScores;
    },
    isWhatAmILookingAtActive() {
      return this.config.useLookingAt;
    },
    numItems() {
      if (this.isViewMultiple) {
        return this.chartdataMultiple.length;
      }
      return 1;
    },

    showCharts() {
      return this.chart.show && this.selection.length > 0;
    },

    // display related computed
    col() {
      let col = "mb-3 col-12";
      if (this.config.view === "multiple") {
        if (this.numItems > 1) {
          col += " col-xxl-6";
        }
        // if (this.numItems > 4) {
        //   col += " col-xxxl-4";
        // }
      }
      return col;
    },
    highlightedCantons() {
      return this.cantons.filter(c => c.highlighted);
    }
  },
  watch: {
    view() {
      this.handleResizeEvent();
    },
    displayHelp() {
      this.handleResizeEvent();
    },
    chartType() {
      this.chart.show = false;
      this.handleResizeEvent();
    },
    selection: {
      handler() {
        this.chart.show = true;
        this.handleResizeEvent();
      },
      deep: true
    },
    cantons() {
      this.handleResizeEvent();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResizeEvent);
  },
  mounted() {
    this.handleResizeEvent();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResizeEvent);
  },
  methods: {
    chartHasConfig(key) {
      return typeof this.chart_config[key] != "undefined";
    },

    /**
     * This method takes both the window width and the reference width of 'confcontent'
     * and determines the width of each graphic. The width is also depending on the selected
     * view type.
     */
    handleResizeEvent() {
      // Only proceed if the ref is set
      if (typeof this.$refs.confcontent == "undefined") {
        return;
      }

      // get clients and window height/width
      let clientHeight = this.$refs.confcontent?.clientHeight;
      let clientWidth = this.$refs.confcontent?.clientWidth;
      let windowWidth = window.innerWidth;

      let width = clientWidth;
      let height = clientHeight;

      let n_charts = this.config.view === "multiple" ? this.numItems : 1;
      const aspectRatio = this.chart_config.aspectRatio || 0.5;

      let grid_rows_x = 1;

      // define grid and breakpoints
      // xxl >= 1400, xl >= 1200, lg >= 992, md >= 768, sm >= 576
      switch (true) {
        case windowWidth >= 1600 && n_charts > 4: // xxxl
          grid_rows_x = 2;
          break;
        case windowWidth >= 1400 && n_charts > 1: // xxxl
          grid_rows_x = 2;
          break;
      }

      width = width / grid_rows_x - 120;

      // Check for content max width
      // if chart has max width set, check if content width exceeds this value
      if (this.chartHasConfig("maxWidth") && width > this.chart_config.maxWidth) {
        width = this.chart_config.maxWidth;
      }

      // define padding depending on what am i looking at
      let py = this.isWhatAmILookingAtActive ? 120 : 60;

      // if the calculate height is > than the actual content height, keep the aspect ratio
      if (width * aspectRatio > height - py) {
        height -= py;
        width = (1 / aspectRatio) * height;
      } else {
        height = width * aspectRatio;
      }

      // calculate margins from num grid and width
      let margin_x = Math.max((clientWidth / grid_rows_x - width) / 2, 0);
      let margin_y = 20;

      // define padding
      this.chart.styles.margin = { top: margin_y, right: margin_x, bot: margin_y, left: margin_x };

      // apply padding and set height/width
      this.chart.width = width + margin_x + margin_x;
      this.chart.height = height + margin_y + margin_y;
    },

    whatAmILookingAt(chartdata) {
      if (typeof chartdata != "undefined") {
        if (this.isViewSingle || this.isViewMultiple) {
          return this.chart_config.whatAmILookingAtSingle(chartdata, this.year, this.displayScores);
        } else {
          return this.chart_config.whatAmILookingAtCombined(chartdata, this.year, this.displayScores);
        }
      }
      return "";
    }
  }
};
</script>

<style>
text {
  font-size: 0.8em;
}
</style>

<style scoped>
.chart-data-missing {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.chart-data-missing p {
  font-size: 1.2em;
  color: #ccc;
}
.chart-title {
  line-height: 1.25;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0 20px;
}
</style>
