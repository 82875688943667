import IconBarChart from "./IconBarChart";
import IconMap from "./IconMap";
import IconTable from "./IconTable";
import IconSpiderChart from "./IconSpiderChart";
import IconTrendChart from "./IconTrendChart";
import IconSource from "./IconSource";
import IconPosts from "./IconPosts";
import IconConfig from "./IconConfig";

export default {
  IconBarChart,
  IconMap,
  IconTable,
  IconSpiderChart,
  IconTrendChart,
  IconSource,
  IconPosts,
  IconConfig
};
