<template>
  <icon-base v-bind="$props" viewport="0 0 32 24">
    <g>
      <path
        class="st0"
        d="M3.28,8.54c2.12,0,3.27,1.41,3.27,3.3c0,0.95-0.29,1.87-0.89,2.49l0.57,0.53c0.07,0.06,0.12,0.15,0.12,0.25
		c0,0.25-0.24,0.55-0.5,0.55c-0.22,0-0.3-0.07-0.42-0.19l-0.56-0.53c-0.44,0.23-0.96,0.36-1.57,0.36c-2.31,0-3.27-1.51-3.27-3.46
		C0.01,9.95,1.16,8.54,3.28,8.54z M3.28,14.12c0.22,0,0.42-0.03,0.59-0.08l-0.5-0.47c-0.07-0.06-0.12-0.15-0.12-0.25
		c0-0.25,0.24-0.55,0.5-0.55c0.22,0,0.3,0.07,0.42,0.19l0.51,0.47c0.27-0.4,0.39-0.97,0.39-1.59c0-1.24-0.59-2.12-1.79-2.12
		s-1.79,0.88-1.79,2.12C1.48,13.08,1.89,14.12,3.28,14.12z"
      ></path>
      <path
        class="st0"
        d="M11.67,14.62c0,0.39-0.18,0.66-0.59,0.66c-0.41,0-0.59-0.26-0.59-0.66v-0.17h-0.02
		c-0.37,0.48-0.84,0.83-1.59,0.83c-0.79,0-1.65-0.39-1.65-1.72V11c0-0.35,0.14-0.65,0.65-0.65c0.51,0,0.66,0.31,0.66,0.65v2.29
		c0,0.53,0.26,0.91,0.82,0.91c0.46,0,1-0.38,1-1.03V11c0-0.35,0.14-0.65,0.65-0.65c0.51,0,0.65,0.31,0.65,0.65V14.62z"
      ></path>
      <path
        class="st0"
        d="M13.61,13.06c0,0.69,0.45,1.19,1.21,1.19c0.99,0,0.99-0.65,1.52-0.65c0.31,0,0.54,0.27,0.54,0.51
		c0,0.79-1.27,1.16-2.06,1.16c-1.83,0-2.52-1.24-2.52-2.38c0-1.52,0.92-2.55,2.48-2.55c1.51,0,2.37,1.07,2.37,2.14
		c0,0.45-0.13,0.56-0.57,0.56H13.61z M15.9,12.3c-0.07-0.6-0.52-1.04-1.1-1.04c-0.65,0-1.05,0.4-1.19,1.04H15.9z"
      ></path>
      <path
        class="st0"
        d="M17.83,9.23c0-0.43,0.26-0.66,0.66-0.66s0.66,0.22,0.66,0.66v5.38c0,0.43-0.26,0.66-0.66,0.66
		s-0.66-0.22-0.66-0.66V9.23z"
      ></path>
      <path
        class="st0"
        d="M20.11,9.23c0-0.43,0.26-0.66,0.65-0.66c0.4,0,0.66,0.22,0.66,0.66v5.38c0,0.43-0.26,0.66-0.66,0.66
		c-0.39,0-0.65-0.22-0.65-0.66V9.23z"
      ></path>
      <path
        class="st0"
        d="M23.42,13.06c0,0.69,0.45,1.19,1.21,1.19c0.99,0,0.99-0.65,1.52-0.65c0.31,0,0.54,0.27,0.54,0.51
		c0,0.79-1.27,1.16-2.06,1.16c-1.83,0-2.52-1.24-2.52-2.38c0-1.52,0.92-2.55,2.48-2.55c1.51,0,2.37,1.07,2.37,2.14
		c0,0.45-0.13,0.56-0.57,0.56H23.42z M25.71,12.3c-0.07-0.6-0.52-1.04-1.1-1.04c-0.66,0-1.05,0.4-1.19,1.04H25.71z"
      ></path>
      <path
        class="st0"
        d="M27.57,11c0-0.39,0.18-0.65,0.59-0.65c0.41,0,0.59,0.26,0.59,0.65v0.17h0.02c0.37-0.48,0.84-0.82,1.59-0.82
		c0.79,0,1.65,0.39,1.65,1.72v2.55c0,0.35-0.14,0.66-0.65,0.66c-0.51,0-0.66-0.31-0.66-0.66v-2.29c0-0.53-0.26-0.91-0.83-0.91
		c-0.46,0-0.99,0.38-0.99,1.03v2.16c0,0.35-0.14,0.66-0.66,0.66c-0.51,0-0.66-0.31-0.66-0.66V11z"
      ></path>
    </g>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconSource",
  mixins: [iconMixin]
};
</script>
