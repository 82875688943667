<template>
  <g :id="selector" class="legend" transform="translate(5,25)">
    <text fill="#444" text-anchor="start" font-weight="bold">{{ label }}</text>
    <g class="legend-rect" :transform="'translate(' + padding + ',5)'">
      <rect
        :height="barHeight"
        :width="barWidth - padding * 2"
        :fill="'url(#' + legendGradientSelector + ')'"
      ></rect>
      <g class="cantonTicks" :clip-path="'url(#' + clipPath + ')'"></g>
      <g class="legendTicks"></g>
    </g>
    <defs>
      <linearGradient :id="legendGradientSelector">
        <stop
          v-for="(s, i) in orderedScores"
          :key="'lg-' + i"
          :offset="linearGradientOffset(s.score)"
          :stop-color="s.color"
        ></stop>
      </linearGradient>
    </defs>
    <defs>
      <clipPath :id="clipPath">
        <rect :height="barHeight" :width="barWidth - padding * 2"></rect>
      </clipPath>
    </defs>
  </g>
</template>

<script>
import * as d3 from "d3";
import _ from "lodash";

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    data: {
      type: Array
    },
    selector: {
      type: String,
      default: "legendGradient"
    }
  },
  data() {
    return {
      barHeight: 14,
      barWidth: 180,
      padding: 10,
      extent: [0, 100],
      ticks: [],
      showCantonScores: true,
      legendGradientSelector: this.selector + "_legendGradient",
      clipPath: this.selector + "_clipPath"
    };
  },
  computed: {
    orderedScores: function() {
      return _.sortBy(this.data, "score");
    }
  },
  watch: {
    data: {
      handler: function(scores) {
        if (scores.length > 0) {
          this.updateLegend();
        }
      },
      deep: true
    }
  },
  methods: {
    /**
     * Offset for the gradient of the color scale
     * @param d
     * @returns {string}
     */
    linearGradientOffset: function(d) {
      let extent = d3.extent(this.data.map(d => d.score));
      return ((d - extent[0]) / (extent[1] - extent[0])) * 100 + "%";
    },

    /**
     * Returns an array with ticks for cantons (cantons/ticks) placed in the legend.
     * @param ticks
     * @returns {[]}
     */
    getCantonTicks: function(ticks = 26) {
      let data = _.sortBy(this.data.map(d => d.score));
      let xTicks = [];
      let steps = Math.round(data.length / ticks);
      let y;
      for (y = 0; y * steps < data.length; y++) {
        xTicks.push(data[y * steps]);
      }
      if (data.length !== y * steps) {
        xTicks.push(data[data.length - 1]);
      }
      return xTicks;
    },

    /**
     * Returns an array with ticks depending on the scaleType
     * @returns {[*, *]|*[]}
     */
    getTicks: function() {
      let extent = d3.extent(this.data.map(d => d.score));
      let scaleType = "linear";

      switch (scaleType) {
        case "quantile": {
          let quantiles = this.scale.quantiles();
          return [extent[0], quantiles[0], quantiles[1], quantiles[2], extent[1]];
        }
        case "linear":
          return extent;
      }
    },

    /**
     * Is responsible for both the initialization and update of the legend
     */
    updateLegend: function() {
      // create linear scale with the inner width of the legend
      let xScale = d3
        .scaleLinear()
        .range([0, this.barWidth - this.padding * 2])
        .domain(d3.extent(this.data.map(d => d.score)));

      // create an axis with a tick for each canton
      let xAxis = d3
        .axisBottom(xScale)
        .tickSize(this.barHeight / 2)
        .tickValues(this.getCantonTicks())
        .tickFormat("");

      // create an axis for the defined ticks
      let xAxis2 = d3
        .axisBottom(xScale)
        .tickSize(this.barHeight * 1.5)
        .tickValues(this.getTicks());

      // select the dom-element and call the axis
      let g = d3.select("#" + this.selector).select(".legend-rect");
      g.select(".cantonTicks")
        .transition() // todo
        .call(xAxis)
        .select(".domain")
        .remove();
      g.select(".legendTicks")
        .transition() // todo
        .call(xAxis2)
        .select(".domain")
        .remove();
    }
  }
};
</script>

<style scoped>
.legend {
  font-size: 0.8em;
}
.cantonTicks {
  opacity: 0.5;
}
</style>
