export default {
  computed: {
    view() {
      return this.config.view;
    },
    viewType() {
      return this.config.view;
    },
    isViewSingle() {
      return this.view === "single";
    },
    isViewMultiple() {
      return this.view === "multiple";
    },
    isViewCombined() {
      return this.view === "combined";
    }
  }
};
