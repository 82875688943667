<template>
  <b-modal :id="id" size="lg" @ok="handleSubmitSources">
    <template v-if="indicator" slot="modal-title">
      <h3 class="title w-100 mb-0">
        <span class="font-weight-bold">{{ indicator.name }}</span>
        <span>: Quellen bearbeiten</span>
      </h3>
    </template>

    <div v-for="(s, index) in sourceSet" :key="index" class="row w-100">
      <div class="col-5">
        <label class="w-100">
          <input v-model="s.name" type="text" class="form-control" placeholder="Bezeichnung" />
        </label>
      </div>
      <div class="col-6">
        <label class="w-100">
          <input v-model="s.source" type="text" class="form-control" placeholder="Quelle" />
        </label>
      </div>
      <div class="col-1">
        <b-button variant="danger" @click="handleRemoveSource(index)">
          <i class="nc-icon nc-simple-remove font-weight-bold"></i>
        </b-button>
      </div>
    </div>

    <template v-slot:modal-footer>
      <b-button @click="handleAddSource">
        Neue Quelle hinzufügen
      </b-button>
      <b-button variant="success" @click="handleSubmitSources()">
        Quellen speichern
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "IndicatorSourcesModal",
  props: {
    id: {
      type: String,
      description: ""
    },
    indicator: {
      type: Object,
      description: "An indicator representation"
    }
  },
  data() {
    return {
      sourceSet: []
    };
  },
  computed: {
    modalTitle() {
      return this.indicator.name + ": Quellen bearbeiten";
    }
  },
  watch: {
    indicator: {
      handler: function(indicator) {
        this.sourceSet = indicator.sources || [];
      },
      deep: true
    }
  },
  mounted() {
    if (this.indicator) {
      this.sourceSet = this.indicator.sources || [];
    }
  },
  methods: {
    handleAddSource() {
      this.sourceSet.push({ name: "", source: "" });
    },
    handleRemoveSource(index) {
      this.sourceSet.splice(index, 1);
    },
    handleSubmitSources() {
      this.$emit("sourcesSubmitted", {
        indicatorId: this.indicator.id,
        sourceSet: this.sourceSet
      });
    }
  }
};
</script>

<style scoped></style>
