<template>
  <icon-base v-bind="$props" viewport="0 0 18 18">
    <path
      class="st0"
      d="M4.93,1.62H1.61v3.33H0V4.92V0h4.9h0.03V1.62z M17.94,0h-4.88h-0.03h-0.03v1.61h3.32v3.32h0.05h1.57H18V0H17.94
	z M4.93,16.39H1.61v-3.32H0v0.03V18h4.9h0.03V16.39z M18,18v-4.93h-0.06h-1.57h-0.05v3.32h-3.32V18h0.03h0.03h4.88H18z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconViewSingle",
  mixins: [iconMixin]
};
</script>
