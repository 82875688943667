<template>
  <div class="flash-messages">
    <template v-for="(m, index) in newestMessages">
      <b-alert :key="index" :variant="m.type" :show="true" dismissible>
        {{ m.message }}
      </b-alert>
    </template>
  </div>
</template>

<script>
export default {
  name: "FlashMessages",
  data() {
    return {
      messages: [],
      limit: 3
    };
  },
  computed: {
    newestMessages() {
      return this.messages.length > this.limit - 1
        ? this.messages.slice(Math.max(this.messages.length - this.limit, 1))
        : this.messages;
    }
  },
  created() {
    this.$eventHub.$on("flash-message", flash => {
      this.addMessage(flash.type, flash.message);
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("flash-message");
  },
  methods: {
    addMessage(type, message) {
      this.messages.push({ type: type, message: message });
    }
  }
};
</script>

<style scoped></style>
