const charts = {
  bar: {
    configRestrictions: {
      supportedViews: ["single", "multiple", "combined"],
      maxItemsSingle: null,
      maxItemsCombined: null,
      maxCantonsSingle: [],
      maxCantonsCombined: 12,
      forceScores: true,
      primaryProperty: "indicator"
    }
  }
};

let getCharts = function() {
  return charts;
};

let getChart = function(chartType) {
  return charts[chartType];
};

exports.getCharts = getCharts;
exports.getChart = getChart;
