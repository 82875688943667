import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./AuthModule";
// import { configurator } from "./ConfiguratorModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth
    // configurator
  }
});
