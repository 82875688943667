/**
 * removes any leading hash tag (#) from a string
 * @param {string} hex - colour
 * @returns {string}
 */
function _stripHash(hex) {
  return hex.substr(0, 1) === "#" ? hex.substr(1) : hex;
}

/**
 * checks that a string contains only characters
 * valid for a hexadecimal colour code (excluding #)
 * @param {string} hex - candidate for testing as valid hex colour
 * @returns {Boolean}
 */
function _containsValidHexChars(hex) {
  return /^[0-9a-f]+$/i.test(hex);
}

/**
 * adds leading 0s if a string is shorter than 6 characters
 * (it assumes a number has originally been entered with leading 0s that have been stripped away)
 * @param {string} hex
 * @returns {string}
 */
function _padWith0(hex) {
  return "000000".substr(0, 6 - hex.length) + hex;
}

/**
 * expands a shorthand hex colour code (a03) to a longhand (full-length) hex colour code (aa0033)
 * (i could have used a loop but found it pointless for six chars)
 * @param {string} hex - possible shorthand
 * @returns {string} longhand
 */
function _expandHex(hex) {
  if (hex.length === 3) {
    hex.split("");

    hex = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]].join("");
  }

  return hex;
}

/**
 * makes sure that a usable hex colur code is being used, by
 *  - stripping leading #
 *  - checks for valid characters only
 *  - expanding shorthand codes
 *  - pads short numbers with leading 0s
 *  - removes excess characters
 * if tests fails it returns hex code for black
 *
 * @param {string} candidate - the string to be validates
 * @returns {string} valid hex code or '#000000'
 * @requires col._stripHash, col._containsValidHexChars, col._expandHex, col._padWith0
 */
function _validateHex(candidate) {
  let hex = _stripHash(candidate.toString());

  if (_containsValidHexChars(hex)) {
    if (hex.length === 3) {
      hex = _expandHex(hex);
    } else if (hex.length < 6) {
      hex = _padWith0(hex);
    } else if (hex.length > 6) {
      hex = hex.substr(0, 6);
    }
    return hex;
  }
  return "000000";
}

/**
 * converts a decimal colour code (0-255) to hex code (00-ff)
 * @param {string || number} decimal
 * @returns {string} hex
 */
function _decimalToHex(decimal) {
  return decimal.toString(16);
}

/** @function _hexToDecimal
 @private
 converts a hex code (00-ff) to decimal colour code (0-255)
 @param {string} hex
 @returns {number} decimal
 */
function _hexToDecimal(hex) {
  return parseInt(hex, 16);
}

/**
 * mixes two colours based on the weight specified
 * @author based on https://gist.github.com/jedfoster/7939513
 * @param {string} color1 - the first hex colour to be mixed
 * @param {string} color2 - the second hex colour to be mixed
 * @param {number} weight - the weight of colour1 used in the mix
 * @returns {string} hex code of colour mix
 * @requires col._validateHex, col._hexToDecimal, col._decimalToHex
 *
 */
function mix(color1, color2, weight) {
  // make sure the colors are 6 digit hex colors without leading #
  color1 = _validateHex(color1);
  color2 = _validateHex(color2);

  // if weight hasn't been set (undefined)
  // or outside allowed range (0-100)
  // well make it 50 (an equal mix of both colors)
  weight =
    typeof weight === "undefined" || parseInt(weight) < 0 || parseInt(weight) > 100 ? 50 : weight;

  // holding variable for new colour
  let newColor = "";

  // loop through hex
  // we want to extract each of the colour triplets (rr, gg, bb)
  // so we use a step of 2
  for (let i = 0; i < 6; i += 2) {
    // retrieve individual color triplet
    // and convert to decimal
    let color1triplet = _hexToDecimal(color1.substr(i, 2));
    let color2triplet = _hexToDecimal(color2.substr(i, 2));

    // blend colour triplets
    let calculatedTriplet = Math.floor(
      color1triplet + (color2triplet - color1triplet) * (weight / 100)
    );

    // convert decimal back to hex
    calculatedTriplet = _decimalToHex(calculatedTriplet);

    // if only one digit prepend with 0
    // and add to newColor variable
    newColor += calculatedTriplet.length === 1 ? "0" + calculatedTriplet : calculatedTriplet;
  }
  return "#" + newColor;
}

/** @function blendRange
 @public
 creates an array of colours from two colours with a requested number of midpoints
 the array contains the original two colours
 @param {string} color1 - the first hex colour to be mixed
 @param {string} color2 - the second hex colour to be mixed
 @param {number} midpoints - number of intermediate colours to be created
 @returns {array} intermediate colours' hex codes bookended by the original colours
 @requires col._validateHex, col.mix
 */
function blendRange(color1, color2, midpoints) {
  // if the number of midpoints is equals 0 we return the two original colors
  if (midpoints === 0) return [color1, color2];

  // make sure the colors are 6 digit hex colors without leading #
  color1 = _validateHex(color1);
  color2 = _validateHex(color2);

  // make sure that the number of midpoints is bigger than 0
  // and add 1 because we want to include the original colours
  midpoints = parseInt(midpoints) >= 1 ? parseInt(midpoints) + 1 : 2;

  // create array for the blended colours
  var blendArray = [];

  // loop through number of midpoints
  for (var i = 0, len = midpoints; i < len + 1; i++) {
    // mix the two colours with a weight calculated on the position in the blend
    // and add to blendArray
    blendArray.push(mix(color1, color2, (100 / len) * i));
  }

  // ... and ship back
  return blendArray;
}

export default { blendRange };
