// utils.js

function normalizeUnits(value) {
  if (!value) return value;

  // normalize units
  value = value.replace(/CO²|CO2|CO₂/g, "CO2");
  value = value.replace(/m²|m2|m₂/g, "m2");
  value = value.replace(/m³|m3|m₃/g, "m3");
  value = value.replace(/H₂O|H²O|H2O/g, "H2O");
  value = value.replace(/CH₄|CH4/g, "CH4");
  value = value.replace(/°C/g, "C");
  value = value.replace(/m\/s²|m\/s2|m\/s₂/g, "m_s2");
  value = value.replace(/N\/m²|N\/m2|N\/m₂/g, "N_m2");
  value = value.replace(/kg\/m³|kg\/m3|kg\/m₃/g, "kg_m3");

  // normalize text and remove Umlaute
  value = value.toLowerCase();
  value = value.replace(/ä/g, "ae");
  value = value.replace(/ö/g, "oe");
  value = value.replace(/ü/g, "ue");
  value = value.replace(/ß/g, "ss");

  // remove unwanted characters and replace double spaces
  value = value.replace(/[^a-z0-9_\s]/g, "");
  value = value.replace(/\s+/g, "_");
  return value;
}

module.exports = {
  normalizeUnits
};
