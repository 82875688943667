<template>
  <b-modal
    v-model="show"
    size="xl"
    hide-footer
    body-class="bg-light border-top border-light"
    header-class="d-none"
    centered
    style="padding-left: 200px"
  >
    <div v-click-outside="close" class="row">
      <div class="col-8">
        <div class="container" style="background-color: gray;">
          <iframe :key="iframeKey" :src="url" frameborder="0"></iframe>
        </div>
      </div>
      <div class="col-4">
        <b-button class="close" variant="outline-light" @click="close()">
          x
        </b-button>

        <div class="w-100 indicator-modal-header py-2">
          <h2 class="title w-100 m-0 p-0">
            Ansicht Einbetten
          </h2>
          <p class="mt-4">
            Mittels untenstehendem Code können Sie die aktuelle Ansicht,
            <em>(Vorschau auf der linken Seite)</em> nahtlos in Ihre Webseite, Blogs oder andere Online-Plattformen
            einbinden. Bei Bedarf können Sie im untenstehenden Feld die Werte der <em>width</em> sowie
            <em>height</em> properties anpassen, um die Grösse zu verändern. Um die Anpassungen zu widerrufen können Sie
            den <em>zurücksetzen</em> Button klicken. Falls Sie mit der Konfiguration zufrieden sind, klicken Sie den
            <em>Kopieren</em> Button, um den Code für das iframe zu kopieren.
          </p>
        </div>
        <div>
          <p>
            Aktivieren Sie untenstehende Option, falls sie lediglich die Diagramme (ohne Konfigurator) einbetten wollen.
          </p>
          <label style="font-size: 13px; font-weight: bold;"
            ><input id="configDisabledCheckbox" v-model="configDisabled" type="checkbox" /> Konfigurator ausblenden
          </label>
          <textarea id="iframeTxtArea" v-model="iframeHTML" rows="1" autocomplete="off" autocapitalize="none"></textarea
          ><br />
          <div class="row py-2">
            <div class="col-lg-6">
              <b-button
                class="btn btn-primary"
                size="sm"
                :style="{ backgroundColor: copyBtnBackgroundColor }"
                @click="copyIframeCode()"
                >{{ copyBtnText }}</b-button
              >
            </div>
            <div class="col-lg-6">
              <b-button
                class="btn btn-primary float-right"
                size="sm"
                :style="{ backgroundColor: resetBtnBackgroundColor }"
                @click="resetIframeCode()"
                >{{ resetBtnText }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import "font-awesome/scss/font-awesome.scss";

export default {
  name: "EmbedModal",
  props: {
    id: {
      type: String,
      default: "embed-modal"
    },
    queryParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      event: "open-embed-modal",
      show: false,
      iframeKey: 0,
      url: this.generateUrl(),
      copyBtnText: "Kopieren",
      resetBtnText: "Zurücksetzen",
      copyBtnBackgroundColor: "",
      resetBtnBackgroundColor: "",
      iframeHTML: "",
      iframeWidth: "100%",
      iframeHeight: "100%"
    };
  },
  computed: {
    configDisabled: {
      get() {
        return this.queryParams.disableConfig === "true";
      },
      set(value) {
        this.$emit("update-query-params", value);
      }
    }
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        this.url = this.generateUrl();
        this.iframeKey++;
      }
    },
    url() {
      this.generateIframe();
    }
  },
  mounted() {
    this.$eventHub.$on(this.event, (event) => this.open());
    this.url = window.location.href;
    this.generateIframe();
  },
  beforeDestroy() {
    this.$eventHub.$off(this.event);
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("update-query-params", false);
    },
    open() {
      this.show = true;
    },
    shareAndClose() {
      if (this.onSelected) {
        this.onSelected(this.selection);
      }
      this.show = false;
    },
    generateUrl() {
      return window.location.href;
    },
    generateIframe() {
      if (this.iframeHTML) {
        this.iframeWidth = this.iframeHTML.match(/width+\s*=\s*"(.*?)"/)[1];
        this.iframeHeight = this.iframeHTML.match(/height+\s*=\s*"(.*?)"/)[1];
      } else {
        this.iframeWidth = "100%";
        this.iframeHeight = "100%";
      }
      this.iframeHTML = `<iframe width="${this.iframeWidth}" height="${this.iframeHeight}" src="${this.url}"></iframe>`;
    },
    resetIframeCode() {
      this.iframeHTML = `<iframe width="100%" height="100%" src="${this.url}"></iframe>`;
      this.resetBtnText = "iframe zurückgesetzt";
      this.resetBtnBackgroundColor = "green";

      setTimeout(() => {
        this.resetBtnText = "Zurücksetzen";
        this.resetBtnBackgroundColor = "";
      }, 1000);
    },
    copyIframeCode() {
      navigator.clipboard
        .writeText(this.iframeHTML)
        .then(() => {
          this.copyBtnText = "Ansicht kopiert";
          this.copyBtnBackgroundColor = "green";

          setTimeout(() => {
            this.copyBtnText = "Kopieren";
            this.copyBtnBackgroundColor = "";
          }, 1000);
        })
        .catch(function(err) {
          console.error("Error in copying text: ", err);
        });
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 56.5%; /* Adjust this based on the desired aspect ratio */
}

.container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

#iframeTxtArea {
  width: 100%;
  height: 200px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 12px;
  resize: none;
}
</style>
