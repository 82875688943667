<template>
  <div class="wrapper overflow-hidden">
    <div class="main-panel full-width clear">
      <top-navbar></top-navbar>

      <ceis-content></ceis-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./Partials/PagesTopNavbar";
import CeisContent from "./Partials/ContentMain";
import ContentFooter from "./Partials/ContentFooter";

export default {
  components: {
    TopNavbar,
    CeisContent,
    ContentFooter
  }
};
</script>

<style lang="scss"></style>
