var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tour',{attrs:{"steps":_vm.steps,"options":_vm.myOptions,"callbacks":_vm.myCallbacks,"name":"helpTour"},scopedSlots:_vm._u([{key:"default",fn:function(tour){return [_c('transition',{attrs:{"name":"fade"}},[(tour.steps[tour.currentStep])?_c('v-step',{key:tour.currentStep,attrs:{"step":tour.steps[tour.currentStep],"previous-step":tour.previousStep,"next-step":tour.nextStep,"stop":tour.stop,"skip":tour.skip,"is-first":tour.isFirst,"is-last":tour.isLast,"labels":tour.labels}},[[_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('b-progress',{staticClass:"mb-3",attrs:{"value":tour.currentStep,"max":_vm.steps.length - 1,"striped":"","animated":_vm.animate,"height":"0.5rem"}}),_c('hr',{staticStyle:{"background-color":"white","height":"1px"}}),(tour.currentStep === 0)?_c('div',[_c('b-button',{staticClass:"btn-skip",attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":tour.skip}},[_vm._v(" Tour beenden ")]),_c('b-button',{staticClass:"btn-next",attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" Weiter ")])],1):_vm._e(),(
                tour.currentStep !== 0 &&
                  tour.currentStep !== 1 &&
                  tour.currentStep !== 2 &&
                  tour.currentStep !== 6 &&
                  tour.currentStep !== _vm.steps.length - 1
              )?_c('div',[_c('b-button',{staticClass:"btn-skip",attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":tour.skip}},[_vm._v(" Tour beenden ")]),_c('b-button',{staticClass:"btn-previous",attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":function($event){return _vm.previousStep()}}},[_vm._v(" Zurück ")]),_c('b-button',{staticClass:"btn-next",attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":tour.nextStep}},[_vm._v(" Weiter ")])],1):_vm._e(),(tour.currentStep === 1 || tour.currentStep === 2 || tour.currentStep === 6)?_c('div',[_c('b-button',{staticClass:"btn-skip",attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":tour.skip}},[_vm._v(" Tour beenden ")]),_c('b-button',{staticClass:"btn-previous",attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":function($event){return _vm.previousStep()}}},[_vm._v(" Zurück ")])],1):_vm._e(),(tour.currentStep === _vm.steps.length - 1)?_c('div',[_c('b-button',{staticClass:"btn-previous",attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":function($event){return _vm.previousStep()}}},[_vm._v(" Zurück ")]),_c('b-button',{attrs:{"pill":"","variant":"primary","size":"sm"},on:{"click":tour.stop}},[_vm._v(" Tour abschliessen ")])],1):_vm._e()],1)]],2):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }