<template>
  <div>
    <div class="spider-chart">
      <base-chart
        :id="selector"
        :height="height"
        :width="width"
        :data="chartdata"
        :styles="_styles"
        @chart-mounted="initChart"
        @chart-resized="handleResizeChart"
        @data-changed="updateChart"
      >
        <g :transform="`translate(0, ${descriptionHeight})`">
          <g id="spider-chart-net"></g>
          <g id="spider-chart-values"></g>
        </g>
      </base-chart>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import BaseChart from "./BaseChart";
import ChartStylesMixin from "../../mixins/ChartStylesMixin";
import _cloneDeep from "lodash/cloneDeep";
import ChartMethodsMixin from "../../mixins/ChartMethodsMixin";

export default {
  name: "SpiderChart",
  components: {
    BaseChart
  },
  mixins: [ChartStylesMixin, ChartMethodsMixin],
  props: {
    selector: {
      type: String,
      default: "indicator-bar-chart"
    },
    height: {
      type: Number,
      default: 500,
      description: "Defines the height of the map"
    },
    width: {
      type: Number,
      default: 700,
      description: "Defines the width of the map"
    },
    chartdata: {
      type: Object,
      description: "All the data for this chart",
      default: () => {}
    }
  },
  data() {
    return {
      svg: null,
      netValues: [100, 80, 60, 40, 20],
      focusIndex: 0,
      defaultStyles: { margin: { top: 100, right: 100, bot: 100, left: 100 } },
      descriptionHeight: 0,
      chartReady: false
    };
  },
  computed: {
    datasets() {
      return this.chartdata.datasets;
    },
    radarRadius() {
      return this.innerHeight >= this.innerWidth ? this.innerWidth / 2 : this.innerHeight / 2;
    },
    textRadius() {
      return this.radarRadius + 80;
    },
    nets() {
      return this.netValues.map(v => {
        return this.chartdata.labels
          .map((label, i) => {
            const point = this.valueToPoint(v, i);
            return point.x + "," + point.y;
          })
          .join(" ");
      });
    },
    mostOutsizePolygon() {
      return this.chartdata.labels.map((_, i) => {
        return this.valueToPoint(100, i);
      });
    },
    dataPolygons() {
      const ds = _cloneDeep(this.datasets).map((d, i) => {
        d.index = i;
        return d;
      });
      // move focused item to the end so its is above the others
      const focusItem = ds[this.focusIndex];
      ds.splice(this.focusIndex, 1);
      ds.push(focusItem);

      return ds.map(item => {
        const d = item.data
          .map((v, i) => {
            const point = this.valueToPoint(v, i);
            return point.x + "," + point.y;
          })
          .join(" ");
        return { index: item.index, id: item.id, d };
      });
    },
    dataPoints() {
      const ds = _cloneDeep(this.datasets).map((d, i) => {
        d.index = i;
        return d;
      });
      // move focused item to the end so its is above the others
      const focusItem = ds[this.focusIndex];
      ds.splice(this.focusIndex, 1);
      ds.push(focusItem);

      return ds
        .map(item => {
          return item.data.map((v, i) => {
            const point = this.valueToPoint(v, i);
            return { index: item.index, id: item.id, x: point.x, y: point.y };
          });
        })
        .flat();
    },
    angles() {
      let total = this.chartdata.labels.length;
      return this.chartdata.labels.map((_, i) => ((Math.PI * 2) / total) * i);
    }
  },
  methods: {
    transition(name = "spider-transition") {
      return d3.transition(name).duration(this.transDuration(750));
    },
    valueToPoint(value, index) {
      const total = this.chartdata.labels.length;
      const r = this.radarRadius;
      const x = 0;
      const y = (-r * value) / 100;
      const angle = ((Math.PI * 2) / total) * index;
      const cos = Math.cos(angle);
      const sin = Math.sin(angle);
      const tx = x * cos - y * sin + r;
      const ty = x * sin + y * cos + r;
      return {
        x: tx.toFixed(2),
        y: ty.toFixed(2)
      };
    },
    initChart: function() {
      this.svg = d3.select("#" + this.selector).select("svg");
      this.chartReady = true;
    },
    updateChart: function() {

      // makes margin left dynamic to always center the spider chart
      this._styles.margin.left = (this.width - this.radarRadius * 2) / 2;

      // first update the data... todo only update on net change?
      this.createNest(this.svg.select("#spider-chart-net"));

      // update dimension labels... todo only update on net change?
      this.createDimensionLabels(this.svg.select("#spider-chart-net"));

      // insert values as polygons
      this.createDataPolygons(this.svg.select("#spider-chart-values"));
      this.createDataPoints(this.svg.select("#spider-chart-values"));
    },
    createNest(selector) {
      const r = this.radarRadius;

      // first create the net with polygons
      selector
        .selectAll("polygon.net")
        .data(this.nets)
        .join(
          enter => enter.append("polygon").attr("class", "net"),
          join => join,
          exit => exit
        )
        .attr("points", n => n)
        .attr("style", "fill: none; stroke: gray");

      // add add lines from center to each corner
      selector
        .selectAll("line.most-outsize-polygon")
        .data(this.mostOutsizePolygon)
        .join(
          enter => enter.append("line").attr("class", "most-outsize-polygon"),
          join => join
        )
        .attr("x1", p => p.x)
        .attr("y1", p => p.y)
        .attr("x2", () => this.radarRadius)
        .attr("y2", () => this.radarRadius)
        .attr("style", "stroke: gray");

      // add labels for each net value
      selector
        .selectAll("text.netLabel")
        .data(this.netValues)
        .join(
          enter => enter.append("text").attr("class", "netLabel"),
          join => join
        )
        .attr("transform", v => `translate(${r + 4}, ${(r * (100 - v)) / 100})`)
        .text(d => d);
    },
    createDimensionLabels(selector) {
      const width = this.innerWidth;
      const rr = this.radarRadius;
      const rt = this.textRadius;
      const angles = this.angles;
      const px = 1.05;
      const py = 0.88;
      const xTrans = i => (rr + rt * Math.sin(angles[i]) * px).toFixed();
      const yTrans = i => (rr - rt * Math.cos(angles[i]) * py).toFixed();
      let textWrap = this.textWrap;

      selector
        .selectAll("text.dimension-label")
        .data(this.chartdata.labels)
        .join(
          enter => enter.append("text").attr("class", "dimension-label"),
          join => join
        )
        .attr("x", (_, i) => xTrans(i))
        .attr("y", (_, i) => yTrans(i))
        .text(d => d)
        .attr("text-anchor", (_, i) => {
          let textAnchor = "start";
          // change text-anchor depending on y transform
          if (xTrans(i) < this.innerWidth * 0.4) {
            textAnchor = "start";
          } else if (xTrans(i) > this.innerWidth * 0.6) {
            textAnchor = "end";
          } else {
            textAnchor = "middle";
          }
          return textAnchor;
        })
        .each(function(_, i) {
          const x = xTrans(i);
          let w = x > width * 0.6 || x < width * 0.3 ? 80 : 100;
          textWrap(d3.select(this), w);
        });
    },
    createDataPolygons(selector) {
      selector
        .selectAll("polygon.data-polygon")
        .data(this.dataPolygons, d => d.id)
        .join(
          enter => enter.append("polygon").attr("class", "data-polygon"),
          join => join
        )
        .transition(this.transition("data-polygon"))
        .attr("points", item => item.d)
        .attr(
          "style",
          item =>
            `fill: none;
            stroke: ${this.datasets[item.index].color}`
        );
    },
    createDataPoints(selector) {
      selector
        .selectAll("circle.data-circle")
        .data(this.dataPoints, d => d.id)
        .join(
          enter => enter.append("circle").attr("class", "data-circle"),
          join => join
        )
        .transition(this.transition("data-circle"))
        .attr("cx", item => item.x)
        .attr("cy", item => item.y)
        .attr("r", "3")
        .attr("style", item => `fill: ${this.datasets[item.index].color};`);
    },
    createLegend(selector) {
      // first create the net with polygons
      let group = selector
        .selectAll("g.dataPolygons")
        .data(this.dataPolygons)
        .join(
          enter => enter.append("g").attr("class", "group"),
          join => join,
          exit => exit
        )
        .attr("transform", (_, i) => `translate(${90 * i}, 0)`);

      let label = group.append("g");

      label
        .append("text")
        .attr("x", 30)
        .attr("y", 8)
        .text("est");
      label
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("fill", "blue")
        .attr("width", 20)
        .attr("height", 8);
    },
    handleResizeChart() {
      if (this.chartReady && this.chartdata.datasets.length > 0) {
        this.$nextTick(() => {
          this.$nextTick(() => this.updateChart(this.chartdata));
        });
      }
    }
  }
};
</script>

<style>
polygon.data-polygon {
  stroke-width: 2 !important;
  fill-opacity: 0.2 !important;
}
polygon.data-polygon:hover {
  fill-opacity: 0.8 !important;
}
.spider-chart .chart {
  justify-content: center !important;
  display: flex;
}
</style>
