<template>
  <footer class="footer">
    <div class="container-fluid text-center py-3">
      <div class="d-inline-block canton-select-wrapper">
        <span class="canton-select-tag none bg-primary" @click="none()">
          <span>None</span>
        </span>
      </div>
      <div
        v-for="(canton, i) in cantons"
        :id="'canton-container' + canton.id"
        :key="canton.id"
        class="d-inline-block canton-select-wrapper text-center"
      >
        <span
          :id="'canton-' + canton.id"
          v-longclick="() => highlightCanton(canton)"
          :class="tagClass(canton)"
          @click="click(canton, i)"
          @mouseenter="$eventHub.$emit('canton-mouse-enter', canton.id)"
          @mouseleave="$eventHub.$emit('canton-mouse-out', canton.id)"
          ><span>{{ canton.id }}</span></span
        >

        <b-tooltip
          :target="'canton-' + canton.id"
          :show="showCantonTooltip(canton)"
          triggers="hover"
          placement="top"
          :container="'canton-container' + canton.id"
        >
          {{ canton.properties.name }}
        </b-tooltip>
      </div>
      <div class="d-inline-block canton-select-wrapper">
        <span class="canton-select-tag bg-primary" @click="all()">
          <span>All</span>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import CantonSrv from "../../services/CantonSrv";
import ChartSrv from "../../services/ChartSrv";
import ChartTypesMixin from "../../mixins/ChartTypesMixin";
import ViewTypesMixin from "../../mixins/ViewTypesMixin";
import _ from "lodash";

export default {
  name: "ConfiguratorCantonFooter",
  mixins: [ChartTypesMixin, ViewTypesMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: "select" // highlight
    },
    // todo remove in the future. Is used for viewType detection for the moment
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      cantons: CantonSrv.getCantons(),
      selection: [],
      activeCanton: null,
      isClickingDisabled: false
    };
  },
  computed: {
    // todo maybe more global solution?
    isSingleSelect() {
      return this.chartType === "spider" && this.viewType === "single";
    },

    chartConfigConstraints() {
      return ChartSrv.getChart(this.chartType);
    }
  },
  watch: {
    chartType(chartType) {
      this.updateForSpider(chartType, this.viewType);
    },
    viewType(viewType) {
      this.updateForSpider(this.chartType, viewType);
    }
  },
  mounted() {
    this.$eventHub.$on("canton-mouse-enter", this.handleCantonMouseEnter);
    this.$eventHub.$on("canton-mouse-out", this.handleCantonMouseOut);

    // set initial cantons
    if (this.$route.query.restrictCantons === "true") {
      const cantons = this.$route.query.cantons?.split(",") || [];
      this.cantons.forEach(c => (c.selected = cantons.includes(c.id)));
    } else {
      this.cantons.forEach(c => (c.selected = true));
    }

    this.selection = this.cantons.filter(c => c.selected);
    this.updateFooterConfig();
  },
  methods: {
    updateForSpider(chartType, viewType) {
      if (chartType === "spider" && viewType === "single") {
        this.cantons.forEach((c, i) => (c.selected = i === 0));
        this.selection = [this.cantons[0]];
        this.updateFooterConfig();
      }
    },
    updateFooterConfig() {
      this.$forceUpdate();
      this.$emit("input", _.cloneDeep(this.selection));
    },
    handleCantonMouseEnter(canton) {
      this.activeCanton = canton;
    },
    handleCantonMouseOut() {
      this.activeCanton = null;
    },
    highlightCanton(canton) {
      canton.highlighted = !canton.highlighted;
      this.updateFooterConfig();
      this.isClickingDisabled = true;
    },
    click(canton, index) {
      // prevent click after long click
      if (this.isClickingDisabled) {
        this.isClickingDisabled = false;
        return;
      }

      // if spider chart and view single, we use canton buttons to select
      // current canton
      if (this.isSingleSelect) {
        this.cantons.forEach((c, i) => (c.selected = i === index));
        this.selection = [canton];
      } else {
        // add/remove canton for local display
        if (this.mode === "select") {
          canton.selected = !canton.selected;
        } else if (this.mode === "highlight") {
          canton.highlighted = !canton.highlighted;
        }

        // add/remove canton for v-model
        if (this.selection.indexOf(canton) === -1) {
          this.selection.push(canton);
        } else {
          this.selection = this.selection.filter(c => c.id !== canton.id);
        }
      }

      this.updateFooterConfig();
    },
    none() {
      if (this.mode === "select") {
        this.cantons.forEach(c => (c.selected = false));
      } else if (this.mode === "highlight") {
        this.cantons.forEach(c => (c.highlighted = false));
      }
      this.selection = [];
      this.updateFooterConfig();
    },
    all() {
      if (this.mode === "select") {
        this.cantons.forEach(c => (c.selected = true));
      } else if (this.mode === "highlight") {
        this.cantons.forEach(c => (c.highlighted = true));
      }
      this.selection = this.cantons;
      this.updateFooterConfig();
    },
    tagClass(canton) {
      let cls = "canton-select-tag";
      if (canton.id === this.activeCanton) cls += " bg-primary";
      if (canton.selected) cls += " selected";
      if (canton.highlighted) cls += " highlighted";
      return cls;
    },
    showCantonTooltip(canton) {
      return canton.id === this.activeCanton;
    }
  }
};
</script>

<style scoped>
.canton-select-wrapper {
  padding: 0 2px;
}
span.canton-select-tag {
  position: relative;
  display: inline-block;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  color: #fff;
  line-height: 1;
  background: #888888;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  font-size: 0.8em;
  cursor: pointer;
}
span.canton-select-tag.none {
  margin-right: 4px;
}
span.canton-select-tag.all {
  margin-left: 4px;
}
span.canton-select-tag.selected {
  background: #444444;
}
span.canton-select-tag.highlighted {
  background: #cc6600;
}
span.canton-select-tag.selected.highlighted {
  background: #993300 !important;
}
span.canton-select-tag.selected.highlighted:active,
span.canton-select-tag.selected.highlighted:focus,
span.canton-select-tag.selected.highlighted:hover {
  background: #ad8400 !important;
}
span.canton_select_tag span {
  box-sizing: border-box;
}
</style>
