<template>
  <perfect-scrollbar style="height: 100%; width: 100%;">
    <div class="container">
      <h1 class="text-center">
        Clean Energy Index Switzerland, CEIS
      </h1>
      <h2 class="text-center">Was ist der CEIS?</h2>
      <p class="text-center">
        Der CEIS sammelt bestehende Daten zu energie- und klimapolitischen
        Themen auf Kantonsebene. <br />
        Diese Daten bildet der CEIS ab und ermöglicht den Vergleich zwischen den
        Kantonen.
      </p>
      <div id="wrapper">
        <a
          class="btn btn-sm btn-primary mr-3"
          href="/#/configurator"
          style="color:white"
        >
          CEIS öffnen
        </a>
        <button
          class="btn btn-sm btn-primary"
          style="color:white"
          @click="scrollToNews"
        >
          Zu den News
        </button>
      </div>
      <hr />
      <h2 class="text-center mt-5">Auswahl an Sektoren</h2>
      <div class="row mt-4">
        <div
          v-for="(e, index) in iframes"
          :key="e.type"
          class="col-12 col-sm-6 col-md-4 mb-4"
          :class="{ 'center-iframe': index === 3 }"
        >
          <h3 class="center mb-3">{{ e.title }}</h3>
          <div class="card center">
            <div class="containerIFrame">
              <iframe :src="baseUrl + e.url"></iframe>
            </div>
            <div class="card-body">
              <button
                class="btn btn-sm btn-primary mt-3"
                @click="openCEIS(e.type)"
              >
                Im CEIS öffnen
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <h2 id="currentNews" class="text-center">Aktuelle News</h2>
      <div class="center">
        <div class="card">
          <img class="card-img-top" src="../../../public/blog.png" alt="Blog" />
          <div class="card-body">
            <h3 class="card-title">
              Artikel über den CEIS in <em>der Volkswirtschaft</em>
            </h3>
            <p class="card-text mt-2">
              Regina Betz, Nina Boogen und Stephan Rösli haben im März 2023
              einen Artikel in der Volkswirtschaft veröffentlicht. Dabei stellen
              sie den CEIS anhand von Auswertungen zur erneuerbaren
              Stromproduktion vor. Der Artikel gehörte zu den meistgelesenen
              Artikeln 2023 in Französisch.
            </p>
            <button class="btn btn-primary btn-sm" @click="goToBlog()">
              Zum Artikel
            </button>
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import PostSrv from "../../services/PostSrv";

export default {
  name: "LandingPage",
  components: {},
  data() {
    return {
      postSet: [],
      baseUrl: "",
      iframes: [
        {
          url:
            "configurator/map?indicators=6&colors=ff9500&units=chf_pro_kopf&year=2022&view=multiple&useScores=false&useLookingAt=false&useAnimations=true&restrictCantons=false&disableConfig=true",
          title: "Gebäude",
          type: "geb",
        },
        {
          url:
            "configurator/trend?indicators=12&colors=dc3545&units=&year=2015-2020&view=single&useScores=false&useLookingAt=false&useAnimations=true&restrictCantons=true&disableConfig=true&cantons=AI,BE,BL,FR,ZH",
          title: "Mobilität",
          type: "mob",
        },
        {
          url:
            "configurator/spider?indicators=2,1,16&colors=28a745,28a745,28a745&units=chf_pro_kopf,gwh,kwhchf&year=2020&view=single&useScores=true&useLookingAt=false&useAnimations=true&restrictCantons=true&disableConfig=true&cantons=GR",
          title: "Erneuerbarer Strom",
          type: "strom",
        },
      ],
    };
  },
  created() {
    this.setBaseUrl();
  },
  mounted() {
    PostSrv.getPosts().then((posts) => {
      this.postSet = posts.slice(0, 3);
    });
  },
  methods: {
    setBaseUrl() {
      // set base-url (deployment/production)
      const protocol = window.location.protocol;
      const host = window.location.host;
      this.baseUrl = `${protocol}//${host}/#/`;
    },
    showBlog(id) {
      this.$router.push({ name: "specificPost", params: { postId: id } });
    },
    goToBlog() {
      window.open(
        "https://dievolkswirtschaft.ch/de/2023/03/welche-kantone-investieren-am-meisten-in-erneuerbare-energien/",
        "_blank"
      );
    },
    openCEIS(type) {
      let url;
      this.iframes.forEach((e) => {
        if (e.type === type) {
          url = e.url;
        }
      });
      if (!url) {
        console.error("URL type not defined:", type);
        return;
      }
      const newUrl = new URL(url, this.baseUrl);
      // we have to set the param "disableConfig" to false again because the component do not get loaded if its true
      newUrl.searchParams.set("disableConfig", "false");

      // push the adjusted url to the router
      this.$router.push(newUrl.pathname + newUrl.search);
    },
    scrollToNews() {
      const element = document.getElementById("currentNews");
      element.scrollIntoView();
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: bold;
  font-size: 32px;
  color: #0364a6;
  font-family: "Varela Round", sans-serif;
}
.welcome,
.welcome p {
  font-size: 1.06em;
}

a {
  color: #0364a6;
  font-weight: 600;
}

.contentPages {
  padding: 0px 30px;
  height: 100%;
}

.defaultImg {
  width: 100%;
  height: 75%;
}

.center {
  display: flex;
  margin: 0;
  justify-content: center;
}

.containerIFrame {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
}

.containerIFrame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130%;
  border: none;
}

.center-iframe {
  margin: 0 auto;
}

.container {
  max-width: 1550px;
  width: 100%;
  margin: auto;
}

.card {
  width: 100%;
  max-width: 40rem;
}

.card-img-top {
  width: 100%;
  height: auto;
}

#wrapper {
  display: flex;
  justify-content: center;
}
</style>
