<template>
  <b-modal
    v-model="show"
    size="md"
    hide-footer
    body-class="bg-light border-top border-light"
    header-class="overflow-hidden"
    centered
  >
    <template v-slot:modal-header>
      <div class="w-100 indicator-modal-header pb-3">
        <img src="share.jpeg" alt="Share" style="margin: -25px -25px 25px;width: 500px" />
        <b-button variant="outline-light" size="sm" style="margin: -250px -20px 80px;" @click="closeModal()"
          >X</b-button
        >
        <h2 class="title w-100 m-0 p-0" style="text-align: center">
          Ansicht Teilen
        </h2>
        <p class="my-4" style="text-align: center">
          Teilen Sie diese Ansicht des "Clean Energy Index Switzerland" mit Ihren Kollegen. Nutzen Sie die Gelegenheit,
          um eine effiziente Zusammenarbeit und Diskussion über die Klimaentwicklung in der Schweiz zu fördern.
        </p>
      </div>
    </template>

    <div class="d-flex justify-content-center align-items-center flex-wrap py-2">
      <span
        class="d-flex flex-column align-items-center mx-2 small"
        type="button"
        style="width: 60px;"
        @click="openEmbedModal"
      >
        <i class="fa fa-code"></i>
        <span class="py-1">Einbetten</span>
      </span>
      <span class="d-flex flex-column align-items-center mx-2 small" type="button" style="width: 60px;">
        <a href="mailto:?subject=CEIS&amp;" class="fa fa-paper-plane"> </a>
        <span class="py-1">E-Mail</span>
      </span>
      <!-- Add more icons and names as needed -->
    </div>

    <div class="input-group py-4">
      <input
        type="text"
        class="form-control input-lg"
        placeholder="Link"
        aria-label="Link"
        aria-describedby="basic-addon2"
        :value="url"
        style="background-color: #FFFFFF"
        readonly
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          :style="{ backgroundColor: btnBackgroundColor }"
          type="button"
          @click="copyLink()"
        >
          {{ btnText }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import "font-awesome/scss/font-awesome.scss";

export default {
  name: "ShareModal",
  props: {
    id: {
      type: String,
      default: "share-modal"
    },
    queryParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      event: "open-share-modal",
      show: false,
      btnText: "Link kopieren",
      btnBackgroundColor: "",
      url: ""
    };
  },
  computed: {},
  watch: {
    queryParams: {
      deep: true,
      handler() {
        this.url = this.generateUrl();
      }
    }
  },
  mounted() {
    this.$eventHub.$on(this.event, (event) => this.open());
    this.url = window.location.href;
  },
  beforeDestroy() {
    this.$eventHub.$off(this.event);
  },
  methods: {
    open() {
      this.show = true;
    },
    openEmbedModal() {
      this.show = false;
      this.$eventHub.$emit("open-embed-modal");
    },
    closeModal() {
      this.show = false;
    },
    shareAndClose() {
      if (this.onSelected) {
        this.onSelected(this.selection);
      }
      this.show = false;
    },
    generateUrl() {
      return window.location.href;
    },
    copyLink() {
      navigator.clipboard
        .writeText(this.url)
        .then(() => {
          this.btnText = "Link kopiert";
          this.btnBackgroundColor = "green";

          setTimeout(() => {
            this.btnText = "Link Kopieren";
            this.btnBackgroundColor = "";
          }, 3000);
        })
        .catch((err) => {
          console.error("Error in copying link: ", err);
        });
    }
  }
};
</script>

<style scoped>
.fa {
  padding: 13px;
  font-size: 26px;
  width: 52px;
  height: 52px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  display: inline;
  background: #3b5998;
  color: white;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-pinterest {
  background: #cb2027;
  color: white;
}

.fa-snapchat-ghost {
  background: #fffc00;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.fa-skype {
  background: #00aff0;
  color: white;
}

.fa-android {
  background: #a4c639;
  color: white;
}

.fa-vimeo {
  background: #45bbff;
  color: white;
}

.fa-tumblr {
  background: #2c4762;
  color: white;
}

.fa-vine {
  background: #00b489;
  color: white;
}

.fa-foursquare {
  background: #45bbff;
  color: white;
}

.fa-stumbleupon {
  background: #eb4924;
  color: white;
}

.fa-flickr {
  background: #f40083;
  color: white;
}

.fa-yahoo {
  background: #430297;
  color: white;
}

.fa-soundcloud {
  background: #ff5500;
  color: white;
}

.fa-reddit {
  background: #ff5700;
  color: white;
}

.fa-rss {
  background: #ff6600;
  color: white;
}

.fa-paper-plane {
  background: #ea4c89;
  color: white;
}

.fa-code {
  background: #424242;
  color: white;
}
</style>
