<template>
  <div v-if="chartdata" class="trend-chart">
    <base-chart
      :id="selector"
      :height="height"
      :width="width"
      :data="chartdata"
      :styles="_styles"
      @chart-mounted="initChart"
      @chart-resized="handleResizeChart"
      @data-changed="updateChart"
    >
      <g class="trend-chart">
        <g class="y-axis">
          <g class="grid"></g>
          <g class="ticks"></g>
        </g>
        <g class="x-axis" :transform="'translate(0,' + innerHeight + ')'">
          <g class="ticks"></g>
        </g>
        <text
          class="chart-label x-axis-label"
          :transform="xLabelTransform"
          style="text-anchor: middle"
          >{{ chartdata.options.xLabel }}</text
        >
        <text
          class="chart-label y-axis-label"
          :transform="yLabelTransform"
          style="text-anchor: middle"
          >{{ chartdata.options.yLabel }}</text
        >
      </g>
    </base-chart>
  </div>
</template>

<script>
import * as d3 from "d3";
import BaseChart from "./BaseChart";
import ChartStylesMixin from "../../mixins/ChartStylesMixin";
import _ from "lodash";

export default {
  name: "TrendChart",
  components: {
    BaseChart
  },
  mixins: [ChartStylesMixin],
  props: {
    selector: {
      type: String,
      default: "trend-chart"
    },
    styles: {
      type: Object,
      default: () => {}
    },
    height: {
      type: Number,
      default: 500,
      description: "Defines the height of the map"
    },
    width: {
      type: Number,
      default: 700,
      description: "Defines the width of the map"
    },
    chartdata: {
      type: Object,
      description: "All the data for this chart",
      default: () => {}
    },
    disableTransitions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      svg: null,
      lines: [],
      axis: {
        x: { scale: null, label: this.$t("year"), ticks: 0 },
        y: { scale: null, label: "", ticks: 0 }
      },
      chartReady: false,
      hoveredBar: null,
      tooltipSelector: this.selector + "barTooltip",
      defaultStyles: { margin: { top: 10, right: 30, bot: 34, left: 40 } }
    };
  },
  mounted() {
    this.$eventHub.$on("canton-mouse-enter", this.handleCantonMouseEnter);
    this.$eventHub.$on("canton-mouse-out", this.handleCantonMouseOut);
  },
  methods: {
    transition(name) {
      return d3.transition(name).duration(this.transDuration(750));
    },
    transitionLong(name) {
      return d3.transition(name).duration(this.transDuration(1400));
    },
    initChart() {
      this.svg = d3.select("#" + this.selector).select("svg");
      this.chartReady = true;
    },
    updateChart(data) {
      let _this = this;

      // extent for both axis
      const x_extent = d3.extent(data.labels);
      let y_extent = data.options.range || null;
      // define y_extent if not predefined
      if (y_extent == null) {
        y_extent = d3.extent(_.flatten(_.map(data.datasets, "data")));
        const y_margin = (y_extent[1] - y_extent[0]) / 5;
        y_extent[0] = y_extent[0] - y_margin > 0 ? y_extent[0] - y_margin : 0;
        y_extent[1] = y_extent[1] + y_margin;
      }

      // create x scale for years
      let xScale = d3
        .scaleLinear()
        .domain(x_extent)
        .range([0, this.innerWidth]);

      // create y scale for indicator scores
      let yScale = d3
        .scaleLinear()
        .domain(y_extent)
        .range([this.innerHeight, 0]);

      // insert both axis
      this.svg
        .select(".x-axis .ticks")
        .transition(this.transition("x-axis"))
        .call(
          d3
            .axisBottom(xScale)
            .tickValues(data.labels)
            .tickFormat(d3.format("d"))
        );
      this.svg
        .select(".y-axis .ticks")
        .transition(this.transition("y-axis"))
        .call(d3.axisLeft(yScale));

      // Create y grid lines
      let yGridLines = d3
        .axisLeft()
        .scale(yScale)
        .tickSize(-this.innerWidth)
        .ticks(4)
        .tickFormat("");
      this.svg
        .select(".y-axis .grid")
        .transition(this.t)
        .ease(d3.easeQuad)
        .call(yGridLines);

      // assign data to the selection
      const wrapper = this.svg.select(".trend-chart");

      // method to create lines
      const line = d3
        .line()
        .defined(d => !isNaN(d[1]))
        .x(d => xScale(d[0]))
        .y(d => yScale(d[1]))
        .curve(d3.curveLinear);

      const entries = wrapper
        .selectAll("g.entry")
        .data(data.datasets, d => d.id)
        .join(
          enter => {
            let g = enter.append("g").attr("class", "entry");

            // create line for each entry
            g.append("path")
              .attr("d", d => line(data.labels.map((l, i) => [l, d.data[i]])))
              .attr("class", d => "line " + d.properties.canton)
              .attr("fill", "none")
              .style("stroke", d => d.color)
              .style("opacity", "0")
              .call(this.lineTransition);
            return g;
          },
          append => {
            // update entries line
            append
              .select("path.line")
              .style("stroke", d => d.color)
              .transition(this.transition("line-path-append"))
              .attr("stroke-dasharray", "none")
              .attr("d", d => line(data.labels.map((l, i) => [l, d.data[i]])));
            return append;
          },
          // delete line, dots and labels
          exit => {
            exit
              .select("path.line")
              .style("stroke", "red")
              .transition(this.transition("path-lines-exit"))
              .remove();
            exit
              .selectAll("circle.dot")
              .style("fill", "red")
              .transition(this.transition("path-dots-exit"))
              .remove();
            exit
              .selectAll("text.label")
              .style("fill", "red")
              .transition(this.transition("path-label-exits"))
              .remove()
              .on("end", () => exit.remove());
          }
        )
        .on("mouseenter.e", c => _this.$eventHub.$emit("canton-mouse-enter", c.id))
        .on("mouseout.e", c => _this.$eventHub.$emit("canton-mouse-out", c.id));

      // insert dots for each data point
      entries
        .selectAll("circle.dot")
        .data(d => _.zip(d.data, Array(d.data.length).fill(d.color)))
        .join(enter => {
          return enter
            .append("circle")
            .style("opacity", "0")
            .attr("class", "dot")
            .attr("r", "2");
        })
        .style("fill", d => d[1])
        .attr("cx", (d, i) => xScale(data.labels[i]))
        .attr("cy", d => yScale(d[0]))
        .transition(this.transitionLong("circle"))
        .style("opacity", d => (isNaN(d[0]) ? "0" : "0.6"));

      // insert labels
      entries
        .selectAll("text.label")
        .data(d => {
          return [
            {
              id: d.label,
              x: Math.max(0, data.labels[data.labels.length - 1]),
              y: d.data.filter(item => !isNaN(item)).pop(-1)
            }
          ];
        })
        .join(enter => {
          return enter
            .append("text")
            .attr("class", "label")
            .attr("x", 5);
        })
        .attr("transform", d => {
          return "translate(" + (xScale(d.x) + 10) + "," + (yScale(d.y) + 5) + ")";
        })
        .text(d => d.id);
    },
    handleResizeChart() {
      if (this.chartReady && this.chartdata.datasets.length > 0) {
        this.$nextTick(() => {
          this.initChart();
          this.$nextTick(() => this.updateChart(this.chartdata));
        });
      }
    },
    handleCantonMouseEnter(cantonId) {
      this.svg
        .selectAll(`path.line.${cantonId}`)
        .transition()
        .duration(300)
        .style("stroke", this._styles.chartHoverColor)
        .style("stroke-width", "3");
    },
    handleCantonMouseOut(cantonId) {
      this.svg
        .selectAll(`path.line.${cantonId}`)
        .transition()
        .duration(300)
        .style("stroke", d => d.color)
        .style("stroke-width", "1");
    },
    /**
     * Line transition that is used for new lines
     * @source https://d19jftygre6gh0.cloudfront.net/pjsier/28d1d410b64dcd74d9dab348514ed256
     * @param path
     */
    lineTransition(path) {
      function tweenDash() {
        let l = this.getTotalLength();
        let i = d3.interpolateString("0," + l, l + "," + l);
        return function(t) {
          return i(t);
        };
      }
      path
        .transition("trendLine")
        .duration(this.transDuration(2000))
        .delay(() => Math.random() * this.transDuration(500))
        .style("opacity", 1)
        .attrTween("stroke-dasharray", tweenDash);
    }
  }
};
</script>

<style scoped>
.chart-label {
  text-anchor: middle;
}

/* 13. Basic Styling with CSS */

/* Style the lines by removing the fill and applying a stroke */
.line {
  fill: none;
  stroke: #ffab00;
  stroke-width: 1;
}

.overlay {
  fill: none;
  pointer-events: all;
}

/* Style the dots by assigning a fill and stroke */
.dot {
  fill: #ffab00;
  stroke: #fff;
}

.focus circle {
  fill: none;
  stroke: steelblue;
}

text {
  fill: black; /* <== Set the fill */
  text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;
  cursor: move;
}
</style>
