<template>
  <div class="configurator-welcome w-100">
    <div class="entry-header mb-3">
      <h1 class="title mb-0">Clean Energy Index Switzerland, CEIS</h1>
      <p class="text-muted"></p>
    </div>

    <div class="welcome overflow-hidden">
      <h2>Was macht der CEIS?</h2>
      <p>
        Der CEIS sammelt bestehende Daten zu energie- und klimapolitischen
        Themen auf Kantonsebene. Diese Daten bildet der CEIS ab und ermöglicht
        den Vergleich zwischen den Kantonen.
      </p>
      <h2>Wie funktioniert der CEIS?</h2>

      <!-- Video -->
      <video-player
        ref="videoPlayer"
        class="video-player-box pb-4"
        :options="playerOptions"
        :playsinline="true"
        style="max-width: 100%"
      ></video-player>

      <p>
        Der CEIS funktioniert wie ein Konfigurator. In wenigen Schritten lassen
        sich Daten in verschiedenen Grafiken abbilden, um Kantone zu
        vergleichen.
      </p>

      <ul class="list">
        <li class="pb-2">
          Wähle zuerst über
          <a id="tooltip-indicators">Vergleichswerte auswählen</a> aus, welche
          Daten Du abbilden möchtest.

          <b-tooltip
            target="tooltip-indicators"
            title="Tooltip Vergleichswerte auswählen"
            triggers="hover"
            placement="bottom"
          >
            Normierte Werte entstehen durch eine Normierung der Rohdaten, wobei
            der Vergleich mit den anderen Kantonen zählt. Ein Wert von 100
            bedeutet, dass die kantonale Ausprägung im Vergleich zu den anderen
            Kantonen den energiepolitischen Zielvorstellungen am stärksten
            entspricht. Ein Wert von 0 bedeutet das Gegenteil. Dazwischen liegt
            eine lineare Skala.
          </b-tooltip>
        </li>

        <li class="pb-2">
          Wähle Kantone aus, die Du vergleichen möchtest. Das kannst Du im
          unteren Bereich Deines Bildschirms machen oder über “Auswahl an
          Kantonen einschränken“
        </li>

        <li class="pb-2">
          Wähle einen Grafikytpen aus. Du kannst zwischen
          <router-link to="/configurator/bar">Balkendiagram</router-link>,
          <router-link to="/configurator/map">Schweizerkarte</router-link>,
          <router-link to="/configurator/trend">Trend</router-link> und
          <router-link to="/configurator/spider">Spider</router-link> wählen.
        </li>
      </ul>

      <p>
        Lass Dir die
        <router-link to="/configurator/sources">Quellen</router-link> der
        Vergleichswerte anzeigen. Woher stammen die Daten der von Dir
        gestalteten Abbildung?
      </p>
      <p>
        <router-link to="/configurator/raw"
          >Exportiere Dir die Daten-Tabelle</router-link
        >, auf der Deine Abbildung basiert.
      </p>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

export default {
  name: "ConfiguratorContentWelcome",
  components: { videoPlayer },
  data() {
    return {
      playerOptions: {
        height: "420",
        autoplay: false,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: process.env.BASE_URL + "instructions_v1.mp4"
          }
        ]
      }
    };
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

h1 {
  font-weight: bold;
  font-size: 32px;
  color: #0364a6;
  font-family: "Varela Round", sans-serif;
}
.welcome,
.welcome p {
  font-size: 1.06em;
}

a {
  color: #0364a6;
  font-weight: 600;
}
</style>
