<template>
  <div class="content-area">
    <div class="row">
      <div class="col-12">
        <h2>Sektoren verwalten</h2>
        <p class="text-secondary">
          Im Moment werden Sektoren über den CSV-Import hinzugefügt. Auf dieser Seite kann ein
          Sektor um die Beschreibung, Farbe, und das Icon ergänzt werden. Künftig könnte der
          Workflow so angepasst werden, dass Sektoren nur hier erfass werden können und lediglich
          Score importiert werden.
        </p>
        <card-component class="stripped-tabled-with-hover" body-classes="table-full-width table-responsive">
          <table class="table table-hover table-striped">
            <thead>
              <tr>
                <th class="id">ID</th>
                <th class="name">Sektor</th>
                <th class="desc">Beschreibung</th>
                <th class="color">Farbe</th>
                <th class="icon">Icon</th>
                <th class="actions">Aktionen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sector, index) in sectorSet" :key="index">
                <td>{{ sector.id }}</td>
                <td>{{ sector.name }}</td>
                <td>
                  <label class="w-100">
                    <textarea
                      v-model="sector.description"
                      type="text"
                      name="description"
                      class="form-control"
                    ></textarea>
                  </label>
                </td>
                <td>
                  <label class="w-100">
                    <input
                      v-model="sector.color"
                      class="form-control"
                      type="text"
                      maxlength="6"
                      name="color"
                    />
                  </label>
                </td>
                <td>
                  <label class="w-100">
                    <input v-model="sector.icon" class="form-control" type="text" name="icon" />
                  </label>
                </td>
                <td class="justify-content-end">
                  <router-link
                    class="d-none btn btn-sm btn-secondary mr-2"
                    :to="`/admin/sectors/${sector.id}/`"
                    >Indikatoren anzeigen</router-link
                  >
                  <button class="d-none btn btn-sm btn-info" @click="handleUpdateSector(sector)">
                    Speichern
                  </button>

                  <router-link
                    class="btn-circle btn btn-secondary mr-1"
                    :to="`/admin/sectors/${sector.id}/`"
                    title="Indikatoren anzeigen"
                  >
                    <i class="nc-icon nc-stre-right font-weight-bold"></i>
                  </router-link>

                  <button
                    class="btn-circle btn btn-danger mr-1"
                    title="Sektor löschen"
                    @click="handleDeleteSector(sector)"
                  >
                    <i class="nc-icon nc-simple-remove font-weight-bold"></i>
                  </button>

                  <button
                    class="btn-circle btn btn-success"
                    title="Änderungen speichern"
                    @click="handleUpdateSector(sector)"
                  >
                    <i class="nc-icon nc-check-2 font-weight-bold"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import SectorSrv from "../../services/SectorSrv";

export default {
  name: "SectorsPage",
  data() {
    return {
      tableColumns: ["Id", "Name"],
      sectorSet: []
    };
  },
  mounted() {
    return SectorSrv.getSectors().then(sectors => {
      this.sectorSet = sectors;
    });
  },
  methods: {
    handleUpdateSector(sector) {
      SectorSrv.updateSector(sector.id, sector)
        .then(() => {
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `Sektor ${sector.name} wurde erfolgreich aktualisiert.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Aktualisieren des Sektors. Bitte noch einmal versuchen."
          });
        });
    },
    handleDeleteSector(sector) {
      SectorSrv.deleteSector(sector.id)
        .then(() => {
          // remove sector from list
          this.sectorSet = this.sectorSet.filter(s => s.id !== sector.id);
          // flash a success message
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `Sektor ${sector.name} wurde erfolgreich gelöscht.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Löschen des Sektors. Bitte noch einmal versuchen."
          });
        });
    }
  }
};
</script>

<style scoped>
th.id {
  width: 4%;
}
th.name {
  width: 18%;
}
th.desc {
  width: 45%;
}
th.color,
th.icon {
  width: 10%;
}
</style>
