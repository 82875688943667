import axios from "../axios";

class PostService {
  constructor() {
    this.posts = [];
  }

  getPosts() {
    return axios.get("api/posts").then((res) => {
      this.posts = res.data;
      return res.data;
    });
  }

  getPost(id) {
    return axios.get("api/posts/" + id + "/indicators").then((res) => {
      this.post = res.data;
      return res.data;
    });
  }

  addPost(data) {
    return axios.post("api/post", data).then((res) => {
      return res.data;
    });
  }

  addIndicatorToPost(postId, data) {
    return axios.post("api/posts/" + postId + "/indicators", data).then((res) => {
      return res.data;
    });
  }

  updatePost(id, data) {
    return axios.put("api/posts/" + id, data).then((res) => {
      return res.data;
    });
  }

  updateIndicatorInPost(postId, indId, data) {
    return axios.put("/api/posts/" + postId + "/indicators/" + indId, data).then((res) => {
      return res.data;
    });
  }

  deletePost(id) {
    return axios.delete("api/posts/" + id).then((res) => {
      return res.data;
    });
  }

  deleteIndicatorInPost(postId, indId) {
    return axios.delete("/api/posts/" + postId + "/indicators/" + indId).then((res) => {
      return res.data;
    });
  }
}

export default new PostService();
