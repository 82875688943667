<template>
  <b-modal :id="id" size="lg" @ok="handleSubmitPost">
    <template slot="modal-title">
      <h3 class="title w-100 mb-0">
        <span class="font-weight-bold">Neuen Post erstellen</span>
      </h3>
    </template>

    <div class="row">
      <div class="col-6">
        <label class="w-100">
          <input v-model="post.title" type="text" class="form-control" placeholder="Titel" />
        </label>
      </div>
      <div class="col-6">
        <label class="w-100">
          <input v-model="post.date" type="date" class="form-control" placeholder="Datum" />
        </label>
      </div>
      <div class="col-12">
        <label class="w-100">
          <textarea
            v-model="post.content"
            type="text"
            class="form-control"
            placeholder="Beschreibung"
          ></textarea>
        </label>
      </div>
      <div class="col-12">
        <label class="w-100">
          <textarea
            v-model="post.summary"
            type="text"
            class="form-control"
            placeholder="Zusammenfassung"
            required
          ></textarea>
        </label>
      </div>
      <div class="col-6">
        <label class="w-100">
          <input ref="fileInput" type="file" @change="handleFileChange" />
        </label>
      </div>
    </div>
    <h3 class="title w-100 mb-4">
      <span class="font-weight-bold">Indikatoren hinzufügen</span>
    </h3>
    <div class="col-12 pb-4">
      <b-button size="sm" class="mb-2 float-right" @click="sortIndicatorsByDate()"
        >Neuste zuerst</b-button
      >
      <perfect-scrollbar class="w-100 pr-4" style="max-height: 40vh">
        <ul class="list-group">
          <li
            v-for="indicator in indicatorSet"
            :key="'indicator' + indicator.id"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <h5 class="m-0">
              {{ indicator.name }}
              <span class="ml-2 badge badge-white badge-sector px-2">
                <component
                  :is="getSectorIcon(indicator.sectorId)"
                  :color="'#' + getSectorColor(indicator.sectorId)"
                  :width="12"
                  :height="12"
                ></component>
              </span>
              <span class="ml-2 badge badge-white badge-indicator-type px-2">
                {{ $t(indicator.type) }}
              </span>
            </h5>
            <label class="w-25">
              <input
                v-model="indicator.description"
                type="text"
                class="form-control"
                placeholder="Beschreibung"
              />
            </label>
            <label for="checkbox"></label>
            <input v-model="selection" :value="indicator" type="checkbox" />
          </li>
        </ul>
      </perfect-scrollbar>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="success" @click="handleSubmitPost()">
        Post speichern
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import SectorSrv from "../../services/SectorSrv";
import {
  IconSectorMobility,
  IconSectorBuildings,
  IconSectorGases,
  IconSectorRenewableEnergy,
  IconSectorData
} from "../Icons/sectors";
export default {
  name: "PostModal",
  components: {
    IconSectorMobility,
    IconSectorBuildings,
    IconSectorGases,
    IconSectorRenewableEnergy,
    IconSectorData
  },
  props: {
    id: {
      type: String,
      description: ""
    }
  },
  data() {
    return {
      indicatorSet: [],
      sectorSet: [],
      selection: [],
      selectedFile: null,
      post: {
        indicators: [],
        title: "",
        date: null,
        content: "",
        summary: "",
        img: ""
      }
    };
  },
  watch: {},
  mounted() {
    SectorSrv.getSectors().then((sectors) => {
      this.sectorSet = sectors;
      let indicators = [];
      this.sectorSet.map((sector) => {
        indicators = indicators.concat(sector.indicatorSet);
      });
      this.indicatorSet = indicators.sort((a, b) => {
        var indA = a.name.toUpperCase();
        var indB = b.name.toUpperCase();
        if (indA < indB) {
          return -1;
        } else if (indA > indB) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  },
  methods: {
    getSectorColor(sectorId) {
      return SectorSrv.getSectorColor(sectorId);
    },
    getSectorIcon(sectorId) {
      return SectorSrv.getSectorIcon(sectorId);
    },
    sortIndicatorsByDate() {
      this.indicatorSet.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    handleSubmitPost() {
      // this.selection.forEach((e) => {
      //   this.post.indicators.push({
      //     id: e.id,
      //     showInPost: true,
      //     description: e.description
      //   });
      // });
      // this.$emit("postSubmitted", this.post);

      const formData = new FormData();
      formData.append("title", this.post.title);
      formData.append("date", this.post.date);
      formData.append("content", this.post.content);
      formData.append("summary", this.post.summary);

      // only needed for dev that images are visible
      if (window.location.host === "localhost:8080") {
        formData.append("img", "http://localhost:3000/uploads/" + this.selectedFile.name);
      } else {
        formData.append("img", "uploads/" + this.selectedFile.name);
      }
      formData.append("file", this.selectedFile);

      this.selection.forEach((e) => {
        this.post.indicators.push({
          id: e.id,
          description: e.description,
          showInPost: true
        });
      });
      formData.append("indicators", JSON.stringify(this.post.indicators));
      this.$emit("postSubmitted", formData);
    }
  }
};
</script>

<style scoped></style>
