import axios from "../axios";

class SectorService {
  constructor() {
    this.sectors = [];
  }

  getSectors() {
    return axios.get("api/sectors").then(response => {
      this.sectors = response.data;
      return response.data;
    });
  }

  getSector(id) {
    return axios.get("api/sectors/" + id).then(response => {
      return response.data;
    });
  }

  getSectorColor(id) {
    let sector = this.sectors.find(s => s.id === id);
    return sector ? sector.color : false;
  }
  getSectorIcon(id) {
    let sector = this.sectors.find(s => s.id === id);
    return sector ? sector.icon : false;
  }

  updateSector(id, data) {
    return axios.put(`api/sectors/${id}`, data).then(response => {
      return response.data;
    });
  }

  deleteSector(id) {
    return axios.delete(`api/sectors/${id}`).then(response => {
      return response.data;
    });
  }
}

export default new SectorService();
