<template>
  <div class="contentPages w-100 h-100">
    <perfect-scrollbar style="height: calc(100% - 60px);">
      <div class="container">
        <div class="entry-header mb-3">
          <h1 class="title mb-3">Posts</h1>
          <a class="pl-0" href="/#/configurator">Zurück zum CEIS</a>
        </div>
        <div class="row cards">
          <b-card-group
            v-for="post in postSet"
            :key="post.id"
            class="col-md-3 mb-4"
            deck
          >
            <b-card
              :title="post.title + ' - ' + post.date"
              :img-src="post.img"
              style="max-width: 30rem;"
              img-alt="Image"
              img-top
            >
              <b-card-text>
                {{ post.summary }}
              </b-card-text>
              <button class="btn btn-primary" @click="showBlog(post.id)">
                Weiterlesen
              </button>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import PostsSrv from "../../services/PostSrv";
export default {
  name: "PostsPage",
  data() {
    return {
      postSet: []
    };
  },
  mounted() {
    PostsSrv.getPosts().then((posts) => {
      this.postSet = posts;
    });
  },
  methods: {
    showBlog(id) {
      this.$router.push({ name: "specificPost", params: { postId: id } });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

h1 {
  font-weight: bold;
  font-size: 32px;
  color: #0364a6;
  font-family: "Varela Round", sans-serif;
}

a {
  color: #0364a6;
  font-weight: 600;
}
</style>
