import CONST from "../consts";
import Chartdata from "../../models/chartdataModel";
import CantonSrv from "../../services/CantonSrv";
import i18n from "../../services/i18nSrv";
import Charts from "../../components/Charts";
import LegendType from "../enum/LegendTypeEnum";
import IndicatorType from "../enum/IndicatorTypeEnum";

export default {
  // name of the chart
  name: CONST.CHARTS.MAP,

  // component name
  component: Charts.MapChart.name,

  // the supported views
  supportedViews: ["single", "multiple"],

  // the supported views that require scaled data
  supportedViewsReqScaledData: [],

  // the maximum number of items in single view
  maxItemsSingle: null,

  // the maximum number of items in combined view
  maxItemsCombined: null,

  // the maximum number of cantons in single view
  maxCantonsSingle: [],

  // the maximum number of contains in combined view
  maxCantonsCombined: 26,

  // determines if the graphic type supports the selection of cantons
  isCantonSelectionSupported: false,

  // determines if the graphic type uses single year selection or year range
  isYearRange: false,

  // the aspect ration between width and height (width*aspect-ration=height)
  aspectRatio: 0.6,

  // map legend type
  legendType: LegendType.Continuous,

  // type of the index
  type: IndicatorType.Policy_Measure,

  // returns the title used for single and multiple view
  chartTitleSingle(item, year) {
    let key = "chart.title.with_year";
    // check if indicator has year
    if (typeof item.indicator.years[year] !== "undefined") {
      // check if indicator has more than one unit
      if (item.indicator.years[year].length > 1) {
        key = "chart.title.complete";
      }
    }
    return i18n.t(key, {
      label: item.indicator.name,
      unit: item.meta.unit,
      year: year
    });
  },

  // computes chartdata used to display trend chart in multiple view
  chartdataMultiple(selection, cantons, scores, year, useScores) {
    // filter scores
    scores = scores.filter(s => s.year === year);
    cantons = CantonSrv.getCantons();

    return selection.map(item => {
      const type = item.indicator.type;
      const title = this.chartTitleSingle(item, year);
      const labels = [item.indicator.name];
      const meta = {
        year: year,
        unit: item.meta.unit
      };
      const styles = {
        primaryColor: item.color,
        secondaryColor: "#ffffff"
      };
      const options = {
        useScores,
        legend: type === "indicator" ? LegendType.Continuous : LegendType.Categorical,
        type: type
      };
      let chartdata = new Chartdata(labels, item._id, title, options, meta, styles);
      chartdata.createDatasetsFromCantons(cantons, [item.color], item);
      chartdata.applyScores(scores, useScores);
      this.isChartdataValid(chartdata, CONST.VIEWS.MULTIPLE);
      return chartdata;
    });
  },

  // computes chartdata used to display trend chart in multiple single
  chartdataSingle(index, selection, cantons, scores, year, useScores) {
    const item = selection[index];
    const type = item.indicator.type;
    const title = this.chartTitleSingle(item, year);
    const labels = [item.indicator.name];
    const meta = {
      year: year,
      unit: item.meta.unit
    };
    const styles = {
      primaryColor: item.color,
      secondaryColor: "#ffffff"
    };
    // filter scores
    scores = scores.filter(s => s.year === year);
    cantons = CantonSrv.getCantons();
    // create chartdata
    const options = {
      useScores,
      legend: type === "indicator" ? LegendType.Continuous : LegendType.Categorical,
      type: type
    };
    let chartdata = new Chartdata(labels, item._id, title, options, meta, styles);
    chartdata.createDatasetsFromCantons(cantons, [item.color], item);
    chartdata.applyScores(scores, useScores);
    this.isChartdataValid(chartdata, CONST.VIEWS.SINGLE);
    return chartdata;
  },

  // not supported!
  chartdataCombined() {
    return false;
  },

  //
  isChartdataValid(chartdata) {
    if (chartdata.datasets.some(ds => ds.data.includes(null))) {
      if (!this.isViewCombined) {
        chartdata.isValid = false;
        chartdata.errorMessage = i18n.t("chart.no_data_for_this_year");
      }
    }
    if (chartdata.labels.length === 0) {
      chartdata.isValid = false;
      chartdata.errorMessage = i18n.t("chart.no_item_selected");
    }
  },

  //
  whatAmILookingAtSingle(chartdata, year, useScores) {
    if (typeof chartdata != "undefined" && chartdata.labels.length > 0) {
      // define the key depending on type, isScoreTopDown and useScores
      let key;
      if (chartdata.options.type === "policy_measure") {
        key = "whatAmiLookingAt.mapChartPolicyMeasure";
      } else {
        key = chartdata.isScoreTopDown
          ? "whatAmiLookingAt.mapChartSingle"
          : "whatAmiLookingAt.mapChartSingleReversed";

        if (useScores) {
          key += "Score";
        }
      }

      return i18n.t(key, {
        indicator: chartdata.labels[0],
        year: year,
        unit: chartdata.meta.unit,
        data_form: useScores
          ? i18n.t("whatAmiLookingAt.data_scores")
          : i18n.t("whatAmiLookingAt.data_raw")
      });
    }
    return "";
  },

  whatAmILookingAtCombined() {
    return "";
  }
};
