<template>
  <icon-base v-bind="$props" viewport="0 0 28 28">
    <path
      :style="style"
      class="st0"
      d="M13.89,28c-3.49,0-6.32-2.83-6.32-6.33l0-16.85c0-2.2,1.45-4.21,3.6-4.7c3.02-0.68,5.72,1.63,5.72,4.55l0,15.54
	c0,1.48-1.01,2.84-2.46,3.08c-1.9,0.32-3.53-1.11-3.53-2.96V9.32h2v11.01c0,0.57,0.43,1,1,1c0.57,0,1-0.43,1-1l0-15.6
	c0-1.15-0.69-2.24-1.79-2.59c-1.83-0.59-3.53,0.78-3.53,2.53l0,16.85c0,2.22,1.6,4.2,3.8,4.45c2.63,0.31,4.85-1.73,4.85-4.3V9.4h2
	v12.27C20.22,25.17,17.39,28,13.89,28L13.89,28z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconSectorData",
  mixins: [iconMixin]
};
</script>
