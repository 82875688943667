<template>
  <div id="app">
    <router-view />
    <help-tour></help-tour>
  </div>
</template>

<script>
import HelpTour from "./components/Configurator/HelpTour.vue";
export default {
  name: "App",
  components: {
    HelpTour
  }
};
</script>

<style>
:root {
  font-size: 87.5%;
}
</style>