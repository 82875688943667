<template>
  <div :id="selector" class="chart">
    <div v-if="$slots.beforeChart" slot="beforeChart" class="before-chart">
      <slot name="beforeChart"></slot>
    </div>

    <div v-if="$slots.default" slot="default">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :viewBox="'0 0 ' + width + ' ' + height"
        :aria-labelledby="chartName"
        class="chart"
        preserveAspectRatio="xMidYMid meet"
      >
        <title :id="chartName" lang="en"></title>
        <g
          class="chart-wrapper"
          :transform="'translate(' + styles.margin.left + ',' + styles.margin.top + ')'"
        >
          <slot />
        </g>
      </svg>
    </div>

    <div v-if="$slots.afterChart" slot="afterChart" class="after-chart">
      <slot name="afterChart"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseChart",
  props: {
    chartName: {
      type: String,
      default: "chart"
    },
    selector: {
      type: String,
      default: "chartId"
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    data: {
      type: Object,
      description: "Contains all the data rows",
      required: true
    },
    styles: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chart: {
        height: this.height,
        width: this.width
      }
    };
  },
  watch: {
    height: function(val, oldVal) {
      if (val !== oldVal) this.handleResizeEvent(val);
    },
    width: function(val, oldVal) {
      if (val !== oldVal) this.handleResizeEvent(val);
    },
    data: {
      handler: function(val) {
        this.handleDataChange(val);
      },
      deep: true
    }
  },
  mounted() {
    this.handleChartMounted();
  },
  methods: {
    handleResizeEvent: function() {
      this.$nextTick(() => {
        this.$emit("chart-resized", {
          height: this.chart.height,
          width: this.chart.width
        });
      });
    },
    handleDataChange: function(val) {
      this.$nextTick(() => this.$emit("data-changed", val));
    },
    handleChartMounted: function() {
      // fire chart mounted event on next tick
      this.$nextTick(() => {
        this.$emit("chart-mounted");
        // we also fire a data change
        this.handleDataChange(this.data);
      });
    }
  }
};
</script>

<style scoped>
svg.chart {
  display: inline-block;
  vertical-align: baseline;
}
</style>

<style>
svg.chart {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #444;
}
svg.chart text {
  fill: #444;
}
svg.chart .grid line {
  stroke: #e5e5e5;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

svg.chart .x-axis path,
svg.chart .y-axis path {
  stroke: #858585;
}

svg.chart .grid path {
  stroke-width: 0;
}
</style>
