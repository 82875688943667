import ColorBlenderSrv from "../services/ColorBlenderSrv";

export default {
  props: {
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 400
    },
    animations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultStyles: {
        primaryColor: "#458B74",
        secondaryColor: "#ffffff",
        colorSchema: (midpoints = 2) =>
          ColorBlenderSrv.blendRange(
            this._styles.secondaryColor,
            this._styles.primaryColor,
            midpoints - 2
          ),
        chartHoverColor: "#fcd12a",
        margin: { top: 10, right: 10, bot: 10, left: 10 }
      }
    };
  },
  computed: {
    _styles: function() {
      return {
        ...this.defaultStyles,
        ...this.chartdata.styles,
        ...this.styles
      };
    },
    innerHeight: function() {
      return Math.max(this.height - this._styles.margin.top - this._styles.margin.bot, 0);
    },
    innerWidth: function() {
      return Math.max(this.width - this._styles.margin.left - this._styles.margin.right, 0);
    },
    xLabelTransform: function() {
      return "translate(" + this.innerWidth / 2 + " , " + (this.height - 10) + ")";
    },
    yLabelTransform: function() {
      return (
        "translate(" +
        -Math.min(50, this._styles.margin.left - 10) +
        ", " +
        this.innerHeight / 2 +
        ") rotate(-90)"
      );
    }
  },
  methods: {
    transDuration(duration) {
      return this.animations ? duration : 0;
    }
  }
};
