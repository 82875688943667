<template>
  <icon-base v-bind="$props" viewport="0 0 28 28">
    <g>
      <path
        :style="style"
        class="st0"
        d="M26.9,16.6h-2.5l-1.7-4.9c-0.3-0.8-1-1.3-1.8-1.3H9.5c-0.8,0-1.5,0.5-1.8,1.3l-0.5,1.4H5.9
	c-2.7,0-4.8-2.2-4.8-4.8s2.2-4.8,4.8-4.8h5.7c0.2,1,1.1,1.7,2.1,1.7h1.5v0.8H17v-1h2.2c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5
	H17V2.1h2.2c0.3,0,0.5-0.2,0.5-0.5S19.4,1,19.1,1H17V0h-1.8v0.8h-1.5c-1,0-1.9,0.7-2.1,1.6H5.9C2.6,2.4,0,5.1,0,8.3s2.6,5.9,5.9,5.9
	h1L6,16.6H3.5c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h1.6V28h2.6v-3.2h15V28h2.6v-9.2h1.5c0.6,0,1.1-0.5,1.1-1.1
	C28,17.1,27.5,16.6,26.9,16.6z M8.8,12.1c0.1-0.3,0.4-0.6,0.8-0.6h11.3c0.4,0,0.7,0.2,0.8,0.6l1.6,4.5H7.2L8.8,12.1z M10.5,20.9H6.3
	v-2.1l4.2,0.5V20.9z M24.2,20.9H20v-1.6l4.2-0.5V20.9z"
      ></path>
    </g>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconSectorData",
  mixins: [iconMixin],
  computed: {
    style() {
      return `fill: ${this.color}`;
    }
  }
};
</script>
