import Vue from "vue";
import VueI18n from "vue-i18n";

import de from "../assets/locales/de.json";
import de_whatAmILookingAt from "../assets/locales/de_whatAmILookingAt.json";
import fr from "../assets/locales/fr.json";

Vue.use(VueI18n);

function loadLocaleMessages() {
  return {
    de: { ...de, ...de_whatAmILookingAt },
    fr: fr
  };
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "de",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
  silentTranslationWarn: true,
  messages: loadLocaleMessages()
});
