import clickOutside from "./directives/click-ouside.js";
import { longClickDirective } from "vue-long-click";

const longClickInstance = longClickDirective({ delay: 800, interval: 0 });

export default {
  install(Vue) {
    Vue.directive("click-outside", clickOutside);
    Vue.directive("longclick", longClickInstance);
  }
};
