<template>
  <div v-if="sector" class="content-area">
    <div class="row">
      <div class="col-12">
        <h2>{{ sector.name }}: Indikatoren verwalten</h2>
        <p>
          <router-link :to="'/admin/sectors'" title="Zurück zu den Sektoren">
            Zurück zu den Sektoren
          </router-link>
        </p>
        <p class="text-secondary">
          Im Moment werden Indikatoren über den CSV-Import hinzugefügt. Auf dieser Seite kann einem
          Indikator eine Beschreibung und Quellen hinugefügt werden. Künftig könnte der Workflow so
          angepasst werden, dass Sektoren nur hier erfass werden können und lediglich Score Score
          importiert werden.
        </p>
        <card-component class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
          <table class="table table-hover table-striped">
            <thead>
              <tr>
                <th class="id">ID</th>
                <th class="name">Indikator</th>
                <th class="norm">
                  <span id="tt-target">Richtung Normierung</span>
                  <b-tooltip target="tt-target">
                    <b>Wenn aktiv</b>: Hohe Werte sind positiv<br />
                    <b>Wenn inaktiv</b>: Tiefe Werte sind
                    positiv
                  </b-tooltip>
                </th>
                <th class="desc">Beschreibung</th>
                <th class="actions">Aktionen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(indicator, index) in indicatorSet" :key="index">
                <td>{{ indicator.id }}</td>
                <td>{{ indicator.name }}</td>
                <td>
                  <label for="checkbox"></label>
                  <input id="checkbox" v-model="indicator.isScoreTopDown" type="checkbox" />
                </td>
                <td>
                  <label class="w-100">
                    <textarea
                      v-model="indicator.description"
                      type="text"
                      name="description"
                      class="form-control"
                    ></textarea>
                  </label>
                </td>
                <td class="justify-content-end">
                  <router-link
                    class="btn-circle btn btn-secondary mr-1 d-none"
                    :to="`/`"
                    title="Indikatoren anzeigen"
                  >
                    <i class="nc-icon nc-stre-right font-weight-bold"></i>
                  </router-link>

                  <button
                    class="btn-circle btn btn-info mr-1"
                    title="Quellen bearbeiten"
                    @click="handleOpenSourceModal(indicator)"
                  >
                    Q
                  </button>

                  <button
                    class="btn-circle btn btn-danger mr-1"
                    title="Indikator löschen"
                    @click="handleDeleteIndicator(indicator)"
                  >
                    <i class="nc-icon nc-simple-remove font-weight-bold"></i>
                  </button>

                  <button
                    class="btn-circle btn btn-success"
                    title="Änderungen speichern"
                    @click="handleUpdateIndicator(indicator)"
                  >
                    <i class="nc-icon nc-check-2 font-weight-bold"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </card-component>
      </div>
    </div>

    <indicator-sources-modal
      :id="sourceModal.id"
      :indicator="sourceModal.indicator"
      @sourcesSubmitted="handleUpdateSources"
    />
  </div>
</template>

<script>
import IndicatorSrv from "../../services/IndicatorSrv";
import SectorSrv from "../../services/SectorSrv";
import IndicatorSourcesModal from "../../components/Admin/IndicatorSourcesModal";

export default {
  name: "SectorsPage",
  components: { IndicatorSourcesModal },
  data() {
    return {
      sector: null,
      indicatorSet: [],
      sourceModal: {
        indicator: null,
        id: "indicator-source-modal"
      }
    };
  },
  mounted() {
    let sectorId = this.$route.params.sectorId;
    SectorSrv.getSector(sectorId).then(sector => {
      this.sector = sector;
    });
    IndicatorSrv.getIndicatorsBySector(sectorId).then(indicators => {
      this.indicatorSet = indicators;
    });
  },
  methods: {
    handleOpenSourceModal(indicator) {
      this.sourceModal.indicator = indicator;
      this.$bvModal.show(this.sourceModal.id);
    },
    handleUpdateSources(payload) {
      IndicatorSrv.updateIndicatorSources(payload.indicatorId, {
        sourceSet: payload.sourceSet
      })
        .then(() => {
          this.$bvModal.hide(this.sourceModal.id);
          this.indicatorSet.find(i => i.id === payload.indicatorId).sources = payload.sourceSet;

          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `Quellen wurden erfolgreich aktualisiert.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Aktualisieren der Quellen. Bitte noch einmal versuchen."
          });
        });
    },
    handleUpdateIndicator(indicator) {
      IndicatorSrv.updateIndicator(indicator.id, indicator)
        .then(() => {
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `Indikator ${indicator.name} wurde erfolgreich aktualisiert.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Aktualisieren des Indikators. Bitte noch einmal versuchen."
          });
        });
    },
    handleDeleteIndicator(indicator) {
      IndicatorSrv.deleteIndicator(indicator.id)
        .then(() => {
          // remove sector from list
          this.indicatorSet = this.indicatorSet.filter(i => i.id !== indicator.id);
          // flash message
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `Indikator ${indicator.name} wurde erfolgreich gelöscht.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Löschen des Indikators. Bitte noch einmal versuchen."
          });
        });
    }
  }
};
</script>

<style scoped>
th.id {
  width: 5%;
}
th.name {
  width: 20%;
}
th.norm {
  width: 15%;
}
th.desc {
  width: 45%;
}
th.actions {
  width: 15%;
}
</style>
