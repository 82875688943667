<template>
  <icon-base v-bind="$props" viewport="0 0 32 24">
    <g>
      <path
        class="st0"
        d="M25.9,18.7c0-0.1,0-0.2-0.1-0.4c-0.7-0.8-1.3-1.6-1.7-2.2c0.3-0.5,0.5-1,0.6-1.4l2.9-0.4c0.1,0,0.2-0.1,0.3-0.2
	C28,14,28,13.9,28,13.8v-3.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2l-2.8-0.4c-0.1-0.4-0.4-0.9-0.6-1.5
	c0.2-0.3,0.5-0.7,0.8-1.1c0.4-0.5,0.6-0.8,0.8-1c0.1-0.1,0.1-0.2,0.1-0.4c0-0.3-0.9-1.3-2.7-3c-0.1-0.1-0.3-0.2-0.4-0.2
	c-0.1,0-0.3,0-0.4,0.1l-2.2,1.7c-0.5-0.3-1-0.5-1.4-0.6l-0.4-2.9c0-0.1-0.1-0.2-0.2-0.3C18,0,17.9,0,17.8,0h-3.5
	c-0.3,0-0.5,0.1-0.6,0.4C13.6,1,13.4,2,13.3,3.3c-0.5,0.2-1,0.4-1.4,0.6L9.7,2.2C9.6,2.2,9.4,2.1,9.3,2.1c-0.2,0-0.7,0.4-1.5,1.1
	C7.1,4,6.6,4.5,6.3,4.9C6.2,5,6.2,5.1,6.2,5.3c0,0.1,0,0.2,0.1,0.4C7,6.5,7.6,7.2,8,7.8c-0.3,0.5-0.5,1-0.6,1.4L4.5,9.7
	c-0.1,0-0.2,0.1-0.3,0.2C4.1,10,4,10.1,4,10.2v3.5c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.2l2.9,0.5c0.1,0.5,0.4,1,0.7,1.5
	c-0.2,0.3-0.5,0.7-0.9,1.1c-0.4,0.5-0.6,0.8-0.8,1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.3,0.9,1.3,2.7,3c0.1,0.1,0.3,0.1,0.4,0.1
	c0.2,0,0.3,0,0.4-0.1l2.2-1.7c0.5,0.3,1,0.5,1.4,0.6l0.4,2.9c0,0.1,0.1,0.2,0.2,0.3C14,24,14.2,24,14.3,24h3.5
	c0.3,0,0.5-0.2,0.6-0.5c0.2-0.6,0.3-1.6,0.4-2.9c0.5-0.1,1-0.3,1.4-0.6l2.2,1.7c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.7-0.4,1.5-1.1
	c0.7-0.7,1.3-1.3,1.5-1.7C25.9,19,25.9,18.9,25.9,18.7L25.9,18.7z M19.4,15.4c-1,1-2.3,1.5-3.8,1.4c-1-0.1-2-0.5-2.7-1.1
	c-1.2-1.1-1.8-2.4-1.7-4.1c0.1-1,0.5-1.9,1.1-2.7c1.1-1.2,2.4-1.8,4.1-1.7c1,0.1,2,0.5,2.7,1.1c1.1,1,1.7,2.2,1.7,3.6
	C20.8,13.3,20.4,14.5,19.4,15.4L19.4,15.4z M19.4,15.4"
      ></path>
    </g>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconBarChart",
  mixins: [iconMixin]
};
</script>
