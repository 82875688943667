<template>
  <div class="wrapper overflow-hidden">
    <div class="main-panel full-width no-footer clear">
      <top-navbar></top-navbar>
      <ceis-content></ceis-content>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./Partials/TopNavbar.vue";
import CeisContent from "./Partials/ContentMain.vue";

export default {
  components: {
    TopNavbar,
    CeisContent
  },
  mounted() {
    this.$store.dispatch("auth/tryToAuthenticate");
  }
};
</script>

<style lang="scss"></style>
