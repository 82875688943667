<template>
  <div class="content-area h-100">
    <div class="row">
      <div class="col-12">
        <h2>Indikatoren des Posts verwalten - {{ post.title }}</h2>
        <p>
          <router-link :to="'/admin/posts'" title="Zurück zu den Posts">
            Zurück zu den Posts
          </router-link>
        </p>
        <p class="text-secondary">
          In dieser Ansicht können einzelne Indikatoren eines Posts bearbeitet werden.
        </p>
        <card-component class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive">
          <table class="table table-hover table-striped">
            <thead>
              <tr>
                <th class="id">ID</th>
                <th class="name">Indikator</th>
                <th class="norm">
                  <span id="tt-target">Indiaktor anzeigen</span>
                  <b-tooltip target="tt-target">
                    <b>Wenn aktiv</b>: Indikatoren werden im entsprechenden Post angezeigt<br />
                    <b>Wenn inaktiv</b>: Indikatoren werden im entsprechenden Post nicht angezeigt
                  </b-tooltip>
                </th>
                <th class="desc">Beschreibung</th>
                <th class="actions">Aktionen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(indicator, i) in post.Indicators" :key="i">
                <td>{{ indicator.id }}</td>
                <td>{{ indicator.name }}</td>
                <td>
                  <label for="checkbox"></label>
                  <input
                    id="checkbox"
                    v-model="indicator.Posts_Indicators.showInPost"
                    type="checkbox"
                  />
                </td>
                <td>
                  <label class="w-100">
                    <textarea
                      v-model="indicator.Posts_Indicators.description"
                      type="text"
                      name="description"
                      class="form-control"
                    ></textarea>
                  </label>
                </td>
                <td class="justify-content-end">
                  <button
                    class="btn-circle btn btn-danger mr-1"
                    title="Indikator löschen"
                    @click="handleDeleteIndicator(indicator)"
                  >
                    <i class="nc-icon nc-simple-remove font-weight-bold"></i>
                  </button>

                  <button
                    class="btn-circle btn btn-success"
                    title="Änderungen speichern"
                    @click="handleUpdateIndicator(indicator)"
                  >
                    <i class="nc-icon nc-check-2 font-weight-bold"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </card-component>
        <div>
          <!-- Using modifiers -->
          <b-button v-b-toggle.indicators class="m-1" variant="primary" @click="visible = !visible"
            >Weitere Indikatoren hinzufügen</b-button
          >
          <b-collapse v-show="visible" id="indicators">
            <b-card>
              <b-button size="sm" class="mb-2 w-20" @click="sortIndicatorsByDate()">Neuste zuerst</b-button>
              <ul class="list-group">
                <li
                  v-for="(indicator, i) in indicatorSet"
                  :key="i"
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <h5 class="m-0">
                    {{ indicator.name }}
                    <span class="ml-2 badge badge-white badge-sector px-2">
                      <component
                        :is="getSectorIcon(indicator.sectorId)"
                        :color="'#' + getSectorColor(indicator.sectorId)"
                        :width="12"
                        :height="12"
                      ></component>
                    </span>
                    <span class="ml-2 badge badge-white badge-indicator-type px-2">
                      {{ $t(indicator.type) }}
                    </span>
                  </h5>
                  <label class="w-25">
                    <input
                      v-model="indicator.description"
                      type="text"
                      class="form-control"
                      placeholder="Beschreibung"
                    />
                  </label>
                  <label for="checkbox"></label>
                  <input v-model="selection" :value="indicator" type="checkbox" />
                </li>
              </ul>
              <button
                class="btn btn-primary mt-2 float-right"
                title="Indikator löschen"
                @click="handleAddIndicatorsToPost()"
              >
                Änderungen speichern
              </button></b-card
            >
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostSrv from "../../services/PostSrv";
import SectorSrv from "../../services/SectorSrv";
import {
  IconSectorMobility,
  IconSectorBuildings,
  IconSectorGases,
  IconSectorRenewableEnergy,
  IconSectorData
} from "../../components/Icons/sectors";
export default {
  name: "PostPage",
  components: {
    IconSectorMobility,
    IconSectorBuildings,
    IconSectorGases,
    IconSectorRenewableEnergy,
    IconSectorData
  },
  data() {
    return {
      post: {},
      sectorSet: [],
      indicatorSet: [],
      selection: [],
      indicators: [],
      visible: false
    };
  },
  watch: {},
  mounted() {
    PostSrv.getPost(this.$route.params.postId).then((post) => {
      this.post = post;
    });
    SectorSrv.getSectors().then((sectors) => {
      this.sectorSet = sectors;
      let indicators = [];
      let indicatorsToShow = [];
      this.sectorSet.map((sector) => {
        indicators = indicators.concat(sector.indicatorSet);
      });
      indicatorsToShow = indicators.filter(ind2 => !this.post.Indicators.some(ind1 => ind1.id === ind2.id));
      this.indicatorSet = indicatorsToShow.sort((a, b) => {
        var indA = a.name.toUpperCase();
        var indB = b.name.toUpperCase();
        if (indA < indB) {
          return -1;
        } else if (indA > indB) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  },
  methods: {
    getSectorColor(sectorId) {
      return SectorSrv.getSectorColor(sectorId);
    },
    getSectorIcon(sectorId) {
      return SectorSrv.getSectorIcon(sectorId);
    },
    sortIndicatorsByDate() {
      this.indicatorSet.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
    },
    handleUpdateIndicator(indicator) {
      PostSrv.updateIndicatorInPost(this.post.id, indicator.id, indicator.Posts_Indicators)
        .then(() => {
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `Indikator ${indicator.name} wurde erfolgreich aktualisiert.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Aktualisieren des Indikators. Bitte noch einmal versuchen."
          });
        });
    },
    handleDeleteIndicator(indicator) {
      PostSrv.deleteIndicatorInPost(this.post.id, indicator.id)
        .then(() => {
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `Indikator ${indicator.name} wurde erfolgreich gelöscht.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Löschen des Indikators. Bitte noch einmal versuchen."
          });
        });
    },
    handleAddIndicatorsToPost() {
      this.selection.forEach((e) => {
        this.indicators.push({
          id: e.id,
          description: e.description,
          showInPost: true
        });
      });
      PostSrv.addIndicatorToPost(this.$route.params.postId, { indicators: this.indicators })
        .then(() => {
          this.visible = false;
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `Indikatoren wurden erfolgreich zum Post hinzugefügt.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Hinzufügen der Indikatoren zum Post. Bitte noch einmal versuchen."
          });
        });
    }
  }
};
</script>

<style scoped>
th.id {
  width: 5%;
}
th.name {
  width: 20%;
}
th.norm {
  width: 15%;
}
th.desc {
  width: 45%;
}
th.actions {
  width: 15%;
}
</style>
