import Vue from "vue";
import Router from "vue-router";

import Configurator from "../components/Configurator/ConfiguratorMain.vue";
import Login from "../pages/admin/LoginPage";
import Canton from "../pages/admin/CantonPage";
import CsvImport from "../pages/admin/CsvImportPage";

import AuthSrv from "../services/AuthSrv";
import AdminLayout from "../layout/AdminLayout";
import Sectors from "../pages/admin/SectorsPage";
import IndicatorsBySector from "../pages/admin/IndicatorsBySectorPage";
import AdminPosts from "../pages/admin/PostsPage";
import AdminPost from "../pages/admin/PostPage";
import * as Contents from "../components/Configurator/contents";
import ConfiguratorLayout from "../layout/ConfiguratorLayout";
import PageLayout from "../layout/PageLayout";
import Posts from "../pages/posts/PostsPage";
import Post from "../pages/posts/PostPage";
import Home from "../pages/home/LandingPage";

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: "/",
      component: ConfiguratorLayout,
      redirect: "/pages",
      children: [
        {
          path: "configurator",
          name: "Configurator",
          redirect: "/configurator/bar",
          component: Configurator,
          children: [
            {
              path: "bar",
              name: "bar",
              component: Contents.ConfiguratorContentCharts,
            },
            {
              path: "map",
              name: "map",
              component: Contents.ConfiguratorContentCharts,
            },
            {
              path: "trend",
              name: "trend",
              component: Contents.ConfiguratorContentCharts,
            },
            {
              path: "spider",
              name: "spider",
              component: Contents.ConfiguratorContentCharts,
            },
            {
              path: "sources",
              name: "sources",
              component: Contents.ConfiguratorContentSources,
            },
            {
              path: "raw",
              name: "raw",
              component: Contents.ConfiguratorContentTable,
            }
          ]
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/pages",
      component: PageLayout,
      redirect: "/pages/home",
      children: [
        {
          path: "home",
          name: "home",
          component: Home
        },
        {
          path: "posts",
          name: "posts",
          component: Posts
        },
        {
          path: "posts/:postId",
          name: "specificPost",
          component: Post
        }
      ]
    },
    {
      path: "/admin",
      component: AdminLayout,
      meta: { isAuth: true, isAdmin: true },
      redirect: "/admin/sectors",
      children: [
        {
          path: "canton",
          name: "canton",
          component: Canton
        },
        {
          path: "csvImport",
          name: "csvInport",
          component: CsvImport
        },
        {
          path: "sectors",
          name: "sectors",
          component: Sectors
        },
        {
          path: "sectors/:sectorId",
          name: "indicatorsBySector",
          component: IndicatorsBySector
        },
        {
          path: "posts",
          name: "adminPosts",
          component: AdminPosts
        },
        {
          path: "posts/:postId",
          name: "adminSpecificPost",
          component: AdminPost
        }
      ]
    }
  ]
});

// todo maybe separate this from router
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isAuth)) {
    AuthSrv.isAuth()
      .then((user) => {
        if (user.username) {
          // check if a specific user role is required
          if (to.matched.some((record) => record.meta.isAdmin)) {
            if (user.role === "admin") {
              next();
            } else {
              router.push("/configurator/bar"); // push back to frontend
            }
          } else {
            // continue uf no specific user role is required
            next();
          }
        } else {
          router.push({ path: "/login", query: { originalUrl: to.path } });
        }
      })
      .catch(() => {
        router.push({ path: "/login", query: { fullPath: to.fullPath } });
      });
  } else {
    next();
  }
});

export default router;
