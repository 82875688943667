import CONST from "../consts";

export default {
  // name of the chart
  name: CONST.CHARTS.SOURCE,

  // the supported views
  supportedViews: [],

  // the supported views that require scaled data
  supportedViewsReqScaledData: [],

  // the maximum number of items in single view
  maxItemsSingle: null,

  // the maximum number of items in combined view
  maxItemsCombined: null,

  // the maximum number of cantons in single view
  maxCantonsSingle: [],

  // the maximum number of contains in combined view
  maxCantonsCombined: 26,

  // determines if the graphic type supports the selection of cantons
  isCantonSelectionSupported: true,

  // determines if the graphic type uses single year selection or year range
  isYearRange: false
};
