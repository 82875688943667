<template>
  <div ref="conf-raw-data" class="configurator-raw-data w-100 h-100">
    <div style="height: 150px">
      <div class="entry-header mb-3">
        <h2>{{ $t("pages.table.title") }}</h2>
        <p class="text-muted">{{ $t("pages.table.desc") }}</p>
      </div>

      <div class="actions">
        <download-csv
          class="btn btn-outline-primary"
          :data="tableData"
          :name="fileName"
          :fields="['canton', 'indicator.name', 'unit', 'year', 'score']"
        >
          <i class="bi bi-download"></i> {{ $t("pages.table.download_data") }}
        </download-csv>
      </div>
    </div>

    <perfect-scrollbar class="pr-3" style="height: calc(100% - 150px)">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t("indicator") }}</th>
            <th scope="col">{{ $t("canton") }}</th>
            <th scope="col">{{ $t("unit") }}</th>
            <th scope="col">{{ $t("year") }}</th>
            <th scope="col">{{ $t("value") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, i) in tableData" :key="'row-' + i">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ d["indicator.name"] }}</td>
            <td>{{ d.canton }}</td>
            <td>{{ d.unit }}</td>
            <td>{{ d.year }}</td>
            <td v-if="config.useScores">{{ d.scoreNew }}</td>
            <td v-else>{{ d.score }}</td>
          </tr>
        </tbody>
      </table>
    </perfect-scrollbar>
  </div>
</template>

<script>
import Vue from "vue";
import JsonCSV from "vue-json-csv";

Vue.component("downloadCsv", JsonCSV);

export default {
  name: "ConfiguratorContentTable",
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    scores: {
      type: Array,
      required: true,
      default: () => []
    },
    cantons: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    selection() {
      return typeof this.config.selection !== "undefined" ? this.config.selection : [];
    },
    cantonsArray() {
      return this.cantons.map(c => c.id);
    },
    tableData() {
      let ids = this.selection.map(sel => sel.indicator.id);
      return this.scores.filter(score => {
        return (
          ids.includes(score.indicatorId) &&
          this.isYear(score.year) &&
          this.cantonsArray.includes(score.canton)
        );
      });
    },
    fileName() {
      // todo make filename depending on selection [...sectors-year-{scaled}]
      return "ceisdata.csv";
    }
  },
  methods: {
    isYear(y) {
      let year = this.config.year;
      let isRange = Array.isArray(this.config.year);
      if (isRange) {
        return y >= year[0] && y <= year[1];
      } else {
        return y === year;
      }
    }
  }
};
</script>

<style scoped></style>
