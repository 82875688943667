<template>
  <icon-base v-bind="$props" viewport="0 0 32 24">
    <path></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconPosts",
  mixins: [iconMixin]
};
</script>
