export default {
  CHARTS: {
    BAR: "bar",
    SPIDER: "spider",
    MAP: "map",
    TREND: "trend",
    RAW: "raw",
    SOURCE: "sources"
  },

  VIEWS: {
    SINGLE: "single",
    MULTIPLE: "multiple",
    COMBINED: "combined"
  }
};
