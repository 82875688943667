<template>
  <icon-base v-bind="$props" viewport="0 0 32 24">
    <path
      class="st0"
      d="M3.18,0.02H1.04v24h29.92v-2.15H3.18V0.02z M6.85,15.37c0.89,0,1.62-0.72,1.62-1.62c0-0.1-0.01-0.19-0.02-0.28
	l2.86-1.58c0.29,0.27,0.68,0.44,1.11,0.44c0.66,0,1.23-0.4,1.48-0.96l2.47,0.54c0.09,0.8,0.78,1.43,1.6,1.43
	c0.89,0,1.62-0.72,1.62-1.62c0-0.14-0.02-0.27-0.05-0.4l3.1-2.8c0.25,0.17,0.56,0.27,0.89,0.27c0.45,0,0.86-0.19,1.15-0.49
	l2.83,2.01c-0.03,0.13-0.05,0.26-0.05,0.39c0,0.89,0.73,1.62,1.62,1.62c0.89,0,1.62-0.72,1.62-1.62c0-0.89-0.73-1.62-1.62-1.62
	c-0.43,0-0.81,0.17-1.1,0.44L25.1,7.51c0.02-0.1,0.03-0.21,0.03-0.32c0-0.89-0.73-1.62-1.62-1.62c-0.89,0-1.62,0.72-1.62,1.62
	c0,0.23,0.05,0.46,0.14,0.66l-2.99,2.7c-0.29-0.26-0.67-0.42-1.09-0.42c-0.64,0-1.19,0.37-1.45,0.91L14,10.47
	c-0.12-0.77-0.79-1.36-1.6-1.36c-0.89,0-1.62,0.72-1.62,1.62c0,0.13,0.02,0.26,0.05,0.39l-2.8,1.55c-0.3-0.32-0.72-0.52-1.19-0.52
	c-0.89,0-1.62,0.72-1.62,1.62C5.23,14.65,5.96,15.37,6.85,15.37z M28.42,10.44c0,0,0.01-0.01,0.01-0.01
	c0.02-0.03,0.04-0.07,0.06-0.1c0.13-0.19,0.34-0.31,0.58-0.31c0.39,0,0.71,0.32,0.71,0.71s-0.32,0.71-0.71,0.71
	s-0.71-0.32-0.71-0.71C28.36,10.63,28.38,10.53,28.42,10.44z M23.51,6.48c0.39,0,0.71,0.32,0.71,0.71c0,0.39-0.32,0.71-0.71,0.71
	c-0.39,0-0.71-0.32-0.71-0.71C22.81,6.8,23.12,6.48,23.51,6.48z M17.96,11.03c0.39,0,0.71,0.32,0.71,0.71s-0.32,0.71-0.71,0.71
	s-0.71-0.32-0.71-0.71S17.57,11.03,17.96,11.03z M12.4,10.02c0.39,0,0.71,0.32,0.71,0.71s-0.32,0.71-0.71,0.71s-0.71-0.32-0.71-0.71
	S12.01,10.02,12.4,10.02z M6.85,13.05c0.39,0,0.71,0.32,0.71,0.71s-0.32,0.71-0.71,0.71s-0.71-0.32-0.71-0.71S6.46,13.05,6.85,13.05
	z M29.79,2.07c-0.37-0.81-1.33-1.17-2.14-0.8c-0.43,0.2-0.73,0.55-0.86,0.97l-3.19-0.24c-0.02-0.13-0.06-0.25-0.12-0.37
	c-0.37-0.81-1.33-1.17-2.14-0.8c-0.73,0.33-1.1,1.15-0.88,1.9L18.4,4.27c-0.46-0.38-1.12-0.49-1.7-0.22
	c-0.38,0.17-0.66,0.48-0.81,0.84l-3.84-1.21c0-0.22-0.05-0.44-0.15-0.66c-0.37-0.81-1.33-1.17-2.14-0.8s-1.17,1.33-0.8,2.14
	c0.05,0.1,0.1,0.19,0.16,0.28L7.36,7.69c-0.38-0.13-0.8-0.12-1.19,0.06c-0.81,0.37-1.17,1.33-0.8,2.14s1.33,1.17,2.14,0.8
	c0.81-0.37,1.17-1.33,0.8-2.14C8.26,8.42,8.19,8.31,8.11,8.21L9.85,5.2c0.39,0.15,0.84,0.15,1.25-0.04c0.3-0.14,0.53-0.35,0.7-0.61
	l3.98,1.25c0.02,0.13,0.06,0.26,0.12,0.38c0.37,0.81,1.33,1.17,2.14,0.8c0.75-0.34,1.11-1.2,0.87-1.97l2.02-1.52
	c0.46,0.41,1.15,0.54,1.74,0.26c0.39-0.18,0.67-0.49,0.82-0.86l3.25,0.25c0.02,0.09,0.05,0.18,0.09,0.26
	c0.37,0.81,1.33,1.17,2.14,0.8C29.8,3.84,30.16,2.88,29.79,2.07z M7.49,8.92C7.65,9.28,7.5,9.7,7.14,9.86
	c-0.35,0.16-0.77,0.01-0.94-0.35S6.2,8.74,6.55,8.58c0.22-0.1,0.47-0.08,0.66,0.04c0.03,0.03,0.06,0.05,0.09,0.07
	c0,0,0.01,0,0.01,0.01C7.39,8.76,7.45,8.83,7.49,8.92z M11.07,3.4c0.16,0.35,0.01,0.77-0.35,0.94C10.37,4.5,9.95,4.34,9.79,3.99
	c-0.16-0.35-0.01-0.77,0.35-0.94S10.91,3.05,11.07,3.4z M18.02,5.22c0.16,0.35,0.01,0.77-0.35,0.94c-0.35,0.16-0.77,0.01-0.94-0.35
	s-0.01-0.77,0.35-0.94C17.43,4.71,17.85,4.87,18.02,5.22z M22.65,2c0.16,0.35,0.01,0.77-0.35,0.94c-0.35,0.16-0.77,0.01-0.94-0.35
	c-0.16-0.35-0.01-0.77,0.35-0.94C22.07,1.49,22.49,1.64,22.65,2z M28.96,2.44c0.16,0.35,0.01,0.77-0.35,0.94
	c-0.35,0.16-0.77,0.01-0.94-0.35s-0.01-0.77,0.35-0.94C28.38,1.93,28.8,2.09,28.96,2.44z M6.84,19.9c0.89,0,1.61-0.72,1.61-1.61
	c0-0.07-0.01-0.15-0.02-0.22l2.94-1.51c0.29,0.29,0.7,0.48,1.14,0.48c0.55,0,1.03-0.27,1.32-0.69l3.56,0.94
	c0.08,0.82,0.76,1.46,1.6,1.46c0.89,0,1.61-0.72,1.61-1.61c0-0.14-0.02-0.27-0.05-0.4l2.73-2.38c0.28,0.22,0.62,0.35,1,0.35
	c0.89,0,1.61-0.72,1.61-1.61s-0.72-1.61-1.61-1.61c-0.89,0-1.61,0.72-1.61,1.61c0,0.18,0.03,0.35,0.08,0.51l-2.67,2.34
	c-0.29-0.27-0.67-0.43-1.09-0.43c-0.62,0-1.16,0.35-1.43,0.87l-3.45-0.91c0-0.02,0-0.04,0-0.05c0-0.89-0.72-1.61-1.61-1.61
	c-0.89,0-1.61,0.72-1.61,1.61c0,0.12,0.01,0.23,0.04,0.34l-2.88,1.48c-0.29-0.34-0.73-0.56-1.22-0.56c-0.89,0-1.61,0.72-1.61,1.61
	C5.23,19.17,5.95,19.9,6.84,19.9z M24.28,12.39c0.39,0,0.7,0.32,0.7,0.7s-0.32,0.7-0.7,0.7s-0.7-0.32-0.7-0.7
	S23.89,12.39,24.28,12.39z M18.99,16.43c0.24,0,0.45,0.12,0.58,0.31c0.01,0.04,0.03,0.08,0.06,0.11c0,0,0,0,0,0
	c0.04,0.09,0.06,0.18,0.06,0.28c0,0.39-0.32,0.7-0.7,0.7c-0.39,0-0.7-0.32-0.7-0.7S18.61,16.43,18.99,16.43z M12.51,14.72
	c0.39,0,0.7,0.32,0.7,0.7c0,0.39-0.32,0.7-0.7,0.7c-0.24,0-0.45-0.12-0.58-0.31c-0.01-0.02-0.01-0.03-0.02-0.05
	c-0.01-0.02-0.03-0.05-0.04-0.07c-0.04-0.09-0.06-0.18-0.06-0.28C11.81,15.04,12.13,14.72,12.51,14.72z M6.84,17.59
	c0.27,0,0.5,0.15,0.62,0.37c0.01,0.03,0.02,0.06,0.03,0.09c0,0.01,0.01,0.01,0.01,0.02c0.02,0.07,0.04,0.14,0.04,0.22
	c0,0.39-0.32,0.7-0.7,0.7c-0.39,0-0.7-0.32-0.7-0.7S6.45,17.59,6.84,17.59z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconTrendChart",
  mixins: [iconMixin]
};
</script>
