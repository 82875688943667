<template>
  <transition name="fade" mode="out-in">
    <div class="content">
      <div class="container-fluid h-100">
        <flash-messages></flash-messages>
        <router-view></router-view>
      </div>
    </div>
  </transition>
</template>

<script>
import FlashMessages from "../../components/FlashMessages";
export default {
  components: {
    FlashMessages
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
    .fade-leave-to
      /* .fade-leave-active in <2.1.8 */
  
 {
  opacity: 0;
}
</style>
