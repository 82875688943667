import CONSTS from "../consts";
import bar from "./chartConfigBar";
import trend from "./chartConfigTrend";
import map from "./chartConfigMap";
import spider from "./chartConfigSpider";
import raw from "./chartConfigRaw";
import source from "./chartConfigSource";

let chartConfig = {};
chartConfig[CONSTS.CHARTS.BAR] = bar;
chartConfig[CONSTS.CHARTS.TREND] = trend;
chartConfig[CONSTS.CHARTS.MAP] = map;
chartConfig[CONSTS.CHARTS.SPIDER] = spider;
chartConfig[CONSTS.CHARTS.RAW] = raw;
chartConfig[CONSTS.CHARTS.SOURCE] = source;

export default function(typ) {
  if (typeof chartConfig[typ] !== "undefined") {
    return chartConfig[typ];
  }
  return null;
}
