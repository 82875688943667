<template>
  <router-link
    :to="to"
    class="configurator-link btn btn-outline-light px-0 text-center w-100"
    :class="{ 'mb-0': last, 'mb-3': !last }"
    active-class="btn-outline-primary"
    :disabled="disabled"
    :event="!disabled ? 'click' : ''"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "ConfiguratorLink",
  props: {
    to: {
      type: String,
      default: ""
    },
    last: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.configurator-link {
  max-width: 100px !important;
  text-align: center;
  pointer-events: all;
  float: left;
}
@media screen and (max-width: 960px) {
  .configurator-link {
    margin-right: 5px;
    margin-bottom: 0 !important;
    max-width: 60px !important;
  }
}
</style>
