<template>
  <icon-base v-bind="$props" viewport="0 0 18 18">
    <path
      :style="style"
      class="st0"
      d="M13.18,4.84V0H0v13.16h4.64V18H18V4.84H13.18z M1.65,11.65V1.51h9.88v3.33H4.64v6.81H1.65z M16.35,16.49H6.29
	v-3.33h6.89V6.35h3.17V16.49z"
    ></path>
  </icon-base>
</template>

<script>
import iconMixin from "../../../mixins/IconMixin";
export default {
  name: "IconViewCombined",
  mixins: [iconMixin],
  computed: {
    style() {
      return `fill: ${this.color}`;
    }
  }
};
</script>
