<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewport"
    :aria-labelledby="iconName"
    role="presentation"
    class="icon"
  >
    <title :id="iconName" lang="en"></title>
    <g :fill="color">
      <slot></slot>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box"
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: "#494949"
    },
    viewport: {
      type: String,
      default: "0 0 18 18"
    }
  }
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -4px; /* yes, I'm that particular about formatting */
}
</style>
