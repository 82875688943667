<template>
  <nav class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand" href="/#/pages/home">
        <img src="../../../public/logo_ceis.svg" alt="CEIS" height="30" />
      </a>
    </div>
  </nav>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      showConfig: true,
      navCollapsed: false
    };
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    openChangeFeed() {
      this.$router.push("/pages/posts");
    }
  }
};
</script>

<style scoped></style>
