<template>
  <div class="content-area">
    <div class="row">
      <div class="col-12">
        <h2>Posts verwalten</h2>
        <p class="text-secondary">
          In der nachfolgenden Tabelle werden alle aktuell erfassten Posts angezeigt. Um spezifische
          Posts zu bearbeiten, muss man den gewünschten Post mittels grauen Buttons öffnen.
        </p>
        <card-component class="stripped-tabled-with-hover" body-classes="table-full-width table-responsive">
          <table class="table table-hover table-striped">
            <thead>
              <tr>
                <th class="id">ID</th>
                <th class="date">Datum</th>
                <th class="title">Titel</th>
                <th class="desc">Beschreibung</th>
                <th class="summary">Zusammenfassung</th>
                <th class="img">Bild</th>
                <th class="actions">Aktionen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(post, i) in postSet" :key="i">
                <td>{{ post.id }}</td>
                <td>{{ post.date }}</td>
                <td>
                  <label class="w-100">
                    <input v-model="post.title" type="text" name="title" class="form-control" />
                  </label>
                </td>
                <td>
                  <label class="w-100">
                    <textarea
                      v-model="post.content"
                      class="form-control"
                      type="text"
                      name="desc"
                    ></textarea>
                  </label>
                </td>
                <td>
                  <label class="w-100">
                    <textarea
                      v-model="post.summary"
                      class="form-control"
                      type="text"
                      name="summary"
                    ></textarea>
                  </label>
                </td>
                <td>
                  <label class="w-100">
                    <input v-model="post.img" type="text" name="img" class="form-control" />
                  </label>
                </td>
                <td class="justify-content-end">
                  <router-link
                    class="btn-circle btn btn-secondary mr-1"
                    :to="`/admin/posts/${post.id}/`"
                    title="Post anzeigen"
                  >
                    <i class="nc-icon nc-stre-right font-weight-bold"></i>
                  </router-link>

                  <button
                    class="btn-circle btn btn-danger mr-1"
                    title="Post löschen"
                    @click="handleDeletePost(post)"
                  >
                    <i class="nc-icon nc-simple-remove font-weight-bold"></i>
                  </button>

                  <button
                    class="btn-circle btn btn-success"
                    title="Änderungen speichern"
                    @click="handleUpdatePost(post)"
                  >
                    <i class="nc-icon nc-check-2 font-weight-bold"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </card-component>
        <button href="#" class="btn btn-primary" @click="handleOpenPostModal()">
          Post hinzufügen
        </button>
      </div>
    </div>
    <post-modal :id="sourceModal.id" @postSubmitted="handleAddNewPost" />
  </div>
</template>

<script>
import PostSrv from "../../services/PostSrv";
import PostModal from "../../components/Admin/PostModal";
export default {
  name: "PostsPage",
  components: { PostModal },
  data() {
    return {
      postSet: [],
      sourceModal: {
        id: "post-modal"
      }
    };
  },
  mounted() {
    PostSrv.getPosts().then((posts) => {
      this.postSet = posts;
    });
  },
  methods: {
    handleOpenPostModal() {
      this.$bvModal.show(this.sourceModal.id);
    },
    handleAddNewPost(post) {
      PostSrv.addPost(post)
        .then(() => {
          this.$bvModal.hide(this.sourceModal.id);

          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: "Post " + post.title + " wurde erfolgreich erstellt"
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Erstellen des Posts. Bitte noch einmal versuchen."
          });
        });
    },
    handleUpdatePost(post) {
      PostSrv.updatePost(post.id, post)
        .then(() => {
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `${post.title} wurde erfolgreich aktualisiert.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Aktualisieren des Posts. Bitte noch einmal versuchen."
          });
        });
    },
    handleDeletePost(post) {
      PostSrv.deletePost(post.id)
        .then(() => {
          this.$eventHub.$emit("flash-message", {
            type: "success",
            message: `${post.title} wurde erfolgreich gelöscht.`
          });
        })
        .catch(() => {
          this.$eventHub.$emit("flash-message", {
            type: "danger",
            message: "Fehler beim Löschen des Posts. Bitte noch einmal versuchen."
          });
        });
    }
  }
};
</script>

<style scoped>
th.id {
  width: 5%;
}
th.title {
  width: 15%;
}
th.desc {
  width: 30%;
}
th.summary {
  width: 30%;
}
th.img {
  width: 15%;
}
</style>
